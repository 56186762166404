import { EDITIONS, VERIFY_TYPE } from '@/const/ccIndex';

export default {
  userInfo: (state) => state.userInfo,
  isDesktop: (state) => !!state.globalSetting.personalDesktopVersion,
  isAsia: (state) => state.globalSetting.deploySite === 'asia_pacific',
  upgradeSidecar: (state) => state.ccGlobalSetting.productConsolePackageMode === 'TGZ',
  blackUri: (state) => state.blackUri,
  globalConfig: (state) => state.globalConfig,
  isProductTrail: (state) => state.ccGlobalSetting.productAuthType === EDITIONS.COMMUNITY_VERSION,
  isExperienceVersion: (state) => state.ccGlobalSetting.productAuthType === EDITIONS.EXPERIENCE_VERSION,
  verifyType: (state) => (state.globalSetting.deploySite === 'asia_pacific' ? VERIFY_TYPE.SMS_VERIFY_CODE : VERIFY_TYPE.EMAIL_VERIFY_CODE),
  productClusterList: (state) => state.productClusterList,
  includesCC: (state) => state.globalSetting.features && state.globalSetting.features.PRODUCT_CLOUD_CANAL,
  includesDM: (state) => state.globalSetting.features && state.globalSetting.features.PRODUCT_CLOUD_DM,
  buildVersion: (state) => state.globalSetting.buildVersion,
  getNodeType: (state) => (type, deep) => state.globalDsSetting[type].categories.levels[deep],
  getLevels: (state) => (type) => {
    if (state.globalDsSetting[type]) {
      return state.globalDsSetting[type]?.categories.levels;
    }
    return [];
  },
  getLeafGroup: (state) => (type) => state.globalDsSetting[type]?.categories?.leafGroup,
  getQuickQuery: (state) => (type) => {
    if (state.globalDsSetting && state.globalDsSetting[type] && state.globalDsSetting[type].constant) {
      return {
        quickQuery: state.globalDsSetting[type].constant.quickQuery,
        quickQueryColumn: state.globalDsSetting[type].constant.quickQueryColumn,
        quickQueryMap: state.globalDsSetting[type].constant.quickQueryMap
      };
    }
  },
  getBrowserMenus: (state) => (dsType = null, tarType = null) => {
    console.log(dsType, tarType);
    if (dsType && tarType && state.globalDsSetting && state.globalDsSetting[dsType] && state.globalDsSetting[dsType].menus && state.globalDsSetting[dsType].menus[tarType]) {
      return state.globalDsSetting[dsType].menus[tarType];
    } else {
      return state.globalSetting.menus[tarType];
    }
  },
  getMenus: (state) => (menuType, dsType = null) => (dsType
    ? state.globalDsSetting && state.globalDsSetting[dsType] && state.globalDsSetting[dsType].menus && state.globalDsSetting[dsType].menus[menuType]
    : state.globalSetting.menus[menuType]),
  getQualifier: (state) => (dsType) => ({
    left: state.globalDsSetting[dsType].constant.leftQualifier || '"',
    right: state.globalDsSetting[dsType].constant.rightQualifier || '"'
  }),
  genQualifierText: (state) => (dsType, text) => {
    console.log(state.globalDsSetting[dsType], dsType);
    return `${state.globalDsSetting[dsType].constant.leftQualifier}${text}${state.globalDsSetting[dsType].constant.rightQualifier}`;
  },
  removeQualifierText: (state) => (dsType, text) => {
    const { leftQualifier, rightQualifier } = state.globalDsSetting[dsType].constant;
    text = text.replace(leftQualifier, '');
    text = text.replace(rightQualifier, '');
    return text;
  },
  targetDsList: (state) => (dsType) => state.globalDsSetting[dsType].targetDsList,
  ddlList: (state) => (dsType) => state.globalDsSetting[dsType].ddlList,
  supportRdbTx: (state) => (dsType) => state.globalDsSetting[dsType]?.features?.FUN_SUPPORT_RDB_TX,
  isolations: (state) => (dsType) => {
    if (state.globalDsSetting[dsType]) {
      console.log(state.globalDsSetting[dsType].isolations);
      return state.globalDsSetting[dsType].isolations;
    }
  },
  getEditor: (state) => (id) => state.editorSet[id]
};
