<template>
  <Modal
    :value="visible"
    :title="title"
    footer-hide
    :width="width"
    :closable="false"
    :mask-closable="false"
    @cancel="handleEmptyVerifyCodeModalData"
  >
    <div>
      <div class="msg" v-if="errMsg">
        <a-alert :message="errMsg" banner type="error"/>
      </div>
      <Form :label-width="80" ref="passwordConfirmForm">
        <FormItem :label="label?label:$t('lao-mi-ma')">
          <Input type="password" password v-model="originPassword" :placeholder="placeholder?placeholder:'请输入老密码'"></Input>
        </FormItem>
        <slot name="content"/>
      </Form>
      <div slot="footer" class="modal-footer" style="margin-top: 20px">
        <pd-button v-if="hasNextStep" type="primary" @click="handleConfirm">{{
            $t('xia-yi-bu')
          }}
        </pd-button>
        <pd-button v-if="!hasNextStep" type="primary" @click="handleConfirm">{{
            $t('que-ding')
          }}
        </pd-button>
        <pd-button @click="handleEmptyVerifyCodeModalData">{{ $t('guan-bi') }}</pd-button>
      </div>
    </div>
  </Modal>
</template>

<script>
import store from '@/store';
import { mapGetters } from 'vuex';
import {
  sendCodeInLoginState,
  sendCodeInLoginStateByAccount
} from '@/services/cc/api/rdpVerify';

export default {
  name: 'PasswordConfirmModal',
  props: {
    visible: Boolean,
    title: String,
    label: String,
    placeholder: String,
    handleCloseModal: Function,
    handleConfirmCallback: Function,
    footerHide: Boolean,
    verifyCodeType: String,
    width: { type: [Number, String], default: 400 },
    hasNextStep: {
      type: Boolean,
      default: false
    },
    newPhone: {
      type: Boolean,
      default: false
    },
    newEmail: {
      type: Boolean,
      default: false
    },
    phoneNumber: String,
    email: String,
    errMsg: String
  },
  computed: {
    ...mapGetters(['isAsia', 'isProductTrail', 'verifyType'])
  },
  data() {
    return {
      originPassword: '',
      store
    };
  },
  methods: {
    handleEmptyData() {
      this.originPassword = '';
    },
    handleEmptyVerifyCodeModalData() {
      this.handleEmptyData();
      this.handleCloseModal();
    },
    setErrorMsg(msg) {
      this.verifyCodeError = msg;
    },
    handleConfirm() {
      this.handleConfirmCallback(this.originPassword, true);
      this.handleEmptyData();
    }
  },
  destroyed() {
    clearInterval(this.sendCodeAgainTime);
  }
};
</script>

<style scoped>
  .msg {
    margin-bottom: 10px;
  }
</style>
