import OperationLog from '@/views/system/OperationLog';
import User from '@/views/system/User';
import Resource from '@/views/worker/Cluster';
import Server from '@/views/worker/Worker';
import StateMachine from '@/views/system/Fsm';
import StateTask from '@/views/system/AsyncManager';
import StateDetail from '@/views/system/FsmDetail';
// import SystemConfig from '@/views/ccsystem/SystemConfig';
// import UserCenter from '@/views/system/UserCenter';
import ConsoleJobDetail from '@/views/system/ConsoleJobDetail';
import AlertSetting from '@/views/system/AlertSetting';

export default [
  {
    path: '/ccsystem/user',
    name: 'user',
    component: User
  },
  {
    path: '/ccsystem/operationLog',
    name: 'operationLog',
    component: OperationLog
  },
  {
    path: '/ccsystem/fsm',
    name: '/system/fsm',
    component: StateMachine
  },
  {
    path: '/ccsystem/fsm/:id/:jobId',
    name: '/system/fsm/id/jobId',
    component: StateDetail
  },
  {
    path: '/ccsystem/state/task',
    name: '/system/state/task',
    component: StateTask
  },
  {
    path: '/ccsystem/state/task/:id',
    name: '/system/state/task/id',
    component: ConsoleJobDetail
  },
  // {
  //   path: '/ccsystem/config',
  //   name: '/ccsystem/config',
  //   component: SystemConfig
  // },
  // {
  //   path: '/ccsystem/userCenter',
  //   name: '/system/userCenter',
  //   component: UserCenter
  // },
  {
    path: '/ccsystem/alert/setting',
    name: '/system/alert/setting',
    component: AlertSetting
  }
];
