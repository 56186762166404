export default {
  editorSet: {},
  userInfo: {
    username: '',
    menuItems: [],
    auth: [],
    authArr: []
  },
  globalSetting: {
    personalDesktopVersion: false
  },
  ccGlobalSetting: {
  },
  dmGlobalSetting: {
  },
  globalDsSetting: {},
  dsTypeList: {},
  clusterListMap: {},
  allClusterWorkers: 0,
  allClusterRunningWorkers: 0,
  deployEnvListMap: {},
  productClusterList: [],
  selectCcProductCluster: null,
  selectDmProductCluster: null,
  aliyunRegionListMap: {},
  selfRegionListMap: {},
  regionList: [],
  metaCenterSearchParams: {},
  selectedWorker: {},
  logo: {
    loginLogo: '',
    headerLogo: ''
  },
  blackUri: [],
  setPkAndIdFlags: {},
  globalConfig: {},
  sourceConnection: false,
  sinkConnection: false,
  dataSourceDeployTypes: [],
  sourceDataSourceTypes: [],
  sinkDataSourceTypes: [],
  sourceInstance: [],
  sinkInstance: [],
  sourceDbList: [],
  sinkDbList: [],
  showTest1: false,
  showTest2: false,
  tableDatas: [],
  selectedTables: {},
  compareSelectedTables: [],
  compareSelectedDbs: [],
  firstToTableFilter: false,
  firstToCleanData: false,
  sinkTableList: {},
  selectedColumns: {},
  tableInfo: [],
  cleanDataData: [],
  sinkColumns: {},
  cleanDataSinkTables: [],
  urlLabels: [],
  userRole: '',
  specsMap: {},
  rdsData: [],
  addedRdsList: [],
  firstAddDataSource: true,
  selectedCluster: {},
  clusterList: [],
  jobData: null,
  licenseStatus: {
    success: false
  },
  resourceInfo: {
    authSubAccountCount: 10,
    authTaskTableCount: 10
  },
  deploySite: '',
  docUrlPrefix: 'https://www.cloudcanalx.com/us',
  contactUsUrl: 'https://www.cloudcanalx.com/about',
  myCatLog: [],
  mySystemMenuItems: [],
  ccSettingMenuItems: [],
  myAuth: [],
  defaultRedirectUrl: ''
};
