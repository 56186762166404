import { instance } from '../rdpInstance';

export const updateAliyunAkSk = (param) => instance.post('user/updateAliyunAkSk', param);
export const cleanAliyunAkSk = (param) => instance.post('user/cleanAliyunAkSk', param);
export const getCurrUserConfigs = (param) => instance.post('user/config/getcurruserconfigs', param);
export const updateUserConfigs = (param) => instance.post('user/config/upsertuserconfigs', param);
export const listMyAuthCategoryForMenu = (param) => instance.post('user/listMyAuthCategoryForMenu', param);
export const updateUserEmail = (param) => instance.post('user/updateUserEmail', param);
export const checkVerifyCode = (param) => instance.post('user/checkVerifyCode', param);
export const updateUserPhone = (param) => instance.post('user/updateUserPhone', param);
export const queryUserAkSk = (param) => instance.post('user/queryUserAkSk', param);
export const resetPwdWithOriginPwd = (param) => instance.post('user/resetPwdWithOriginPwd', param);
export const resetSubAccountPwd = (param) => instance.post('user/resetSubAccountPwd', param);
export const getPrimaryAccountPwdValidateExpr = (param) => instance.post('user/getPrimaryAccountPwdValidateExpr', param);
export const getSubAccountPwdValidateExpr = (param) => instance.post('user/getSubAccountPwdValidateExpr', param);
