<template>
  <div class="operators">
    <async-run-modal
      :visible="showAsyncRunModal"
      :onRun="handleRunAsync"
      v-if="showAsyncRunModal"
      :handle-close-modal="handleHideAsyncRunModal"
      :title="$t('yi-bu-zhi-hang')" type="console"
      :data-source-type="tab.dsType"/>
    <div class="operator-character">
      <a-tooltip placement="bottom">
        <a-dropdown-button type="primary" :disabled="tab.running"
                           :loading="tab.running" @click="onRun('run')">
          <cc-iconfont v-if="!tab.running" name="run" :color="tab.running?'#999':'#ffffff'"
                       :size="12"></cc-iconfont>
          <a-icon v-if="tab.running" type="loading"/>
          {{ $t('zhi-hang') }}
          <a-menu slot="overlay">
            <a-menu-item :key="run.key" v-for="run in Object.values(runTypes)"
                         @click="handleShowAsyncRunModal">{{ run.label }}
            </a-menu-item>
          </a-menu>
          <a-icon slot="icon" type="down" />
        </a-dropdown-button>
      </a-tooltip>
      <a-button @click="handleStop" :disabled="!tab.running" style="margin-left: 10px"
                :loading="stopping">
        <cc-iconfont v-if="!stopping" name="stop" :color="tab.running?'#555555':'#999'"
                     :size="12"></cc-iconfont>
        {{ $t('zhong-duan-zhi-hang') }}
      </a-button>
      <a-button @click="formatSql" style="margin-left: 10px">{{ $t('ge-shi-hua') }}</a-button>
      <a-button @click="onRun('asyncList')" style="margin-left: 10px" v-if="!isDesktop">
        {{ $t('yi-bu-ren-wu') }}
      </a-button>
      <div :class="`operator-character ${tab.running?'active':'not-active'}`">
      </div>
      <div class="float-right">
        <div class="transaction" v-if="supportRdbTx(tab.dsType)">
          <div class="auto-commit-select" style="display: inline-block">
            <a-tooltip placement="bottom">
              <div slot="title">
                <p>{{ $t('shou-dong-kai-qi-shi-wu-xu-shou-dong-ti-jiao-shi-wu') }}</p>
                <p>{{ $t('zi-dong-guan-bi-shi-wu-zhi-hang-de-sql-zi-dong-ti-jiao') }}</p>
                <p>{{ $t('shi-yong-mo-ren-de-shi-wu-ge-li-ji-bie') }}</p>
              </div>
              <span class="pointer" style="margin-right: 5px;">{{ $t('shi-wu') }}</span>
            </a-tooltip>
            <a-radio-group v-model="tab.autoCommit" @change="handleChangeTx">
              <a-radio :value="true">{{ $t('zi-dong') }}</a-radio>
              <a-radio :value="false">{{ $t('shou-dong') }}</a-radio>
            </a-radio-group>
          </div>
          <a-button v-if="!tab.autoCommit" @click="handleCommit" style="margin-right: 8px">
            {{ $t('ti-jiao') }}
          </a-button>
          <a-button v-if="!tab.autoCommit" @click="handleRollback" style="margin-right: 10px">
            {{ $t('hui-gun') }}
          </a-button>
        </div>
        <div class="isolations" v-if="isolations(tab.dsType) && isolations(tab.dsType).length">
          <a-select v-model="tab.isolation">
            <a-select-option v-for="isolation in isolations(tab.dsType)" :value="isolation.key" :key="isolation.key">{{isolation.i18n}}</a-select-option>
          </a-select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import AsyncRunModal from '@/components/modal/AsyncRunModal';
import { isRedis } from '@/const/dataSource';
import browseMixin from '@/mixins/browseMixin';
import { hasSchema, supportTransaction } from '../../../utils/index';

export default {
  name: 'Operators',
  components: { AsyncRunModal },
  mixins: [browseMixin],
  props: {
    onRun: Function,
    tab: Object,
    handleCommit: Function,
    handleRollback: Function,
    storeQueryTabs: Function,
    stopping: Boolean,
    handleStop: Function,
    formatSql: Function
  },
  data() {
    return {
      showAsyncRunModal: false,
      runTypes: {
        runAsync: { key: 'runAsync', label: this.$t('yi-bu-zhi-hang') }
        // ticket: { key: 'ticket', label: '提交工单' }
      },
      hasSchema,
      supportTransaction,
      sqlExecType: []
    };
  },
  computed: {
    ...mapGetters([
      'isDesktop', 'supportRdbTx', 'isolations'
    ]),
    ...mapState(['userInfo', 'globalSetting'])
  },
  watch: {
    'tab.sessionId': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.handleSetInitTx();
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleSetInitTx() {
      if (this.supportRdbTx(this.tab.dsType) && this.tab.sessionId) {
        this.setTx(this.tab.autoCommit);
      }
    },
    isRedis,
    handleRunAsync(asyncForm) {
      this.onRun('runAsync', asyncForm);
    },
    async handleJps() {
      window.jpc({
        request: 'saveFile({"file": "clouddm.csv"})',
        onSuccess: async (response) => {
          if (response) {
            await this.handleRunAsync({ exportToFile: response });
          }
        },
        onFailure(errorCode, errorMessage) { console.log(errorCode, errorMessage); }
      });
    },
    handleShowAsyncRunModal() {
      if (this.isDesktop) {
        this.handleJps();
      } else {
        this.showAsyncRunModal = true;
      }
    },
    handleHideAsyncRunModal() {
      this.showAsyncRunModal = false;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChangeTx(e) {
      if (this.tab.sessionId) {
        this.setTx(e.target.value);
      }
    },
    async setTx(autoCommit) {
      const { node } = this.tab;
      const res = await this.$services.setTx({
        data: {
          sessionId: this.tab.sessionId,
          levels: this.browseGenLevelsData(node),
          autoCommit,
          initIsolation: node.INSTANCE.attr.defaultIsolation
        }
      });

      if (res.success) {
        this.storeQueryTabs();
      }
    }
  }
};
</script>
