<template>
  <Modal
    :value="visible"
    :title="title"
    footer-hide
    :width="width"
    :closable="false"
    :mask-closable="false"
    @cancel="handleEmptyVerifyCodeModalData"
  >
    <div>
      <slot name="content"/>
      <Alert type="error" v-if="verifyCodeError" show-icon>{{
          verifyCodeError
        }}
      </Alert>
      <p>
        <Input style="width: 180px;" type="text" v-model="verifyCode"
               :placeholder="$t('dian-ji-huo-qu-shu-ru-yan-zheng-ma')"/>
        <pd-button type="primary" ghost style="margin-left: 10px" :disabled="sendCodeDisabled"
                   @click="handleSendVerifyCode">
          {{
            sendCodeDisabled ? $t('zai-ci-huo-qu') + sendCodeAgainTime + 's)' : isAsia ? $t('huo-qu-duan-xin-yan-zheng-ma') : $t('huo-qu-you-xiang-yan-zheng-ma')
          }}
        </pd-button>
      </p>
      <div slot="footer" class="modal-footer" style="margin-top: 20px">
        <pd-button v-if="hasNextStep" type="primary" @click="handleConfirm">{{
            $t('xia-yi-bu')
          }}
        </pd-button>
        <pd-button v-if="!hasNextStep" type="primary" @click="handleConfirm">{{
            $t('que-ding')
          }}
        </pd-button>
        <pd-button @click="handleEmptyVerifyCodeModalData">{{ $t('guan-bi') }}</pd-button>
      </div>
    </div>
  </Modal>
</template>

<script>
import store from '@/store';
import { mapGetters } from 'vuex';
import {
  sendCodeInLoginState,
  sendCodeInLoginStateByAccount
} from '@/services/cc/api/rdpVerify';

export default {
  name: 'VerifyCodeModal',
  props: {
    visible: Boolean,
    title: String,
    handleCloseModal: Function,
    handleConfirmCallback: Function,
    footerHide: Boolean,
    verifyCodeType: String,
    width: { type: [Number, String], default: 400 },
    hasNextStep: {
      type: Boolean,
      default: false
    },
    newPhone: {
      type: Boolean,
      default: false
    },
    newEmail: {
      type: Boolean,
      default: false
    },
    phoneNumber: String,
    email: String
  },
  computed: {
    ...mapGetters(['isAsia', 'isProductTrail', 'verifyType'])
  },
  data() {
    return {
      verifyCodeError: '',
      sendCodeDisabled: false,
      verifyCode: '',
      sendCodeAgainTime: 60,
      store
    };
  },
  methods: {
    handleEmptyData() {
      this.sendCodeDisabled = false;
      this.verifyCode = '';
      this.verifyCodeError = '';
      this.sendCodeAgainTime = 60;
    },
    handleEmptyVerifyCodeModalData() {
      this.handleEmptyData();
      this.handleCloseModal();
    },
    setErrorMsg(msg) {
      this.verifyCodeError = msg;
    },
    handleConfirm() {
      this.handleConfirmCallback(this.verifyCode, true);
      this.handleEmptyData();
    },
    handleSendVerifyCode() {
      this.sendCodeDisabled = true;
      this.sendCodeAgainTime = 60;
      const that = this;

      this.sendCodeAgain = setInterval(() => {
        if (that.sendCodeAgainTime > 0) {
          that.sendCodeAgainTime--;
        } else {
          clearInterval(that.sendCodeAgain);
          that.sendCodeDisabled = false;
        }
      }, 1000);
      let serviceName = '';
      if (this.newPhone || this.newEmail) {
        serviceName = sendCodeInLoginStateByAccount;
      } else {
        serviceName = sendCodeInLoginState;
      }
      serviceName({
        verifyType: this.verifyType,
        verifyCodeType: this.verifyCodeType,
        phoneNumber: this.phoneNumber,
        email: this.email,
        phoneAreaCode: 'CHINA'
      }).then((res) => {
        if (res.data.code === '1') {
          this.$Message.success(this.$t('fa-song-cheng-gong'));
        } else {
          this.sendCodeDisabled = false;
          this.sendCodeAgainTime = 60;
          clearInterval(this.sendCodeAgain);
          try {
            this.verifyCodeError = JSON.parse(res.data.msg).join(' ');
          } catch (err) {
            this.verifyCodeError = res.data.msg;
          }
        }
      }).catch((res) => {
        this.sendCodeDisabled = false;
        this.sendCodeAgainTime = 60;
        clearInterval(this.sendCodeAgain);
        this.$Modal.error({
          title: 'ERROR',
          content: `${res.data.msg}`
        });
      });
    }
  },
  destroyed() {
    clearInterval(this.sendCodeAgainTime);
  }
};
</script>

<style scoped>

</style>
