<template>
  <div class="content-wrapper">
    <Breadcrumb>
      <BreadcrumbItem to="/ccdatasource">{{ $t('shu-ju-yuan-guan-li') }}</BreadcrumbItem>
      <BreadcrumbItem>{{ $t('xin-zeng-shu-ju-yuan') }}</BreadcrumbItem>
    </Breadcrumb>
    <div class="add-datasource-wrapper">
      <div class="add-datasource-content">
        <DataSourceInfo :addDataSourceForm="addDataSourceForm"
                        v-if="currentStep===0" ref="dataSourceInfo" :versionMap="versionMap" :driver-map="driverMap"
                        :set-security-setting="setSecuritySetting"></DataSourceInfo>
<!--          <AddWhiteList :clusters="clusters" :rdsList="addDataSourceForm.rdsList" ref="addwhiteList"-->
<!--                        :addDataSourceForm="addDataSourceForm" :versionMap="versionMap"-->
<!--                        v-if="currentStep===1&&isAk"></AddWhiteList>-->
          <SetAccount :securitySetting="securitySetting" :addDataSourceForm="addDataSourceForm"
                      v-if="currentStep===1&&isAk"></SetAccount>
          <AddDataSourceInfo :addDataSourceForm="addDataSourceForm"
                             v-if="currentStep===2&&isAk"></AddDataSourceInfo>
        <SuccessAdd v-if="currentStep>2"></SuccessAdd>
      </div>
    </div>
    <div>
      <div class="add-dataSource-tools">
        <pd-button v-if="currentStep===0" @click="handleReturn">{{ $t('fan-hui-shu-ju-yuan-guan-li') }}</pd-button>
        <pd-button type="primary" v-if="currentStep!==0&&currentStep<3" @click="handleStep('pre')">
          {{ $t('shang-yi-bu') }}
        </pd-button>
        <pd-button type="primary"
                   :disabled="addDataSourceForm.instanceType==='ALIBABA_CLOUD_HOSTED'&&addDataSourceForm.rdsList.length===0"
                   @click="handleStep('next')" v-if="((currentStep!==1&&addDataSourceForm.instanceType==='ALIBABA_CLOUD_HOSTED')||
                           (currentStep!==0&&addDataSourceForm.instanceType!=='ALIBABA_CLOUD_HOSTED'))&&currentStep<3">
          {{ $t('xia-yi-bu') }}
        </pd-button>
        <pd-button type="primary" @click="handleAddDataSource"
                   v-if="(currentStep===1&&addDataSourceForm.instanceType==='ALIBABA_CLOUD_HOSTED')||
                           (currentStep===0&&addDataSourceForm.instanceType!=='ALIBABA_CLOUD_HOSTED')">
          {{ $t('xin-zeng-shu-ju-yuan') }}
        </pd-button>
      </div>
    </div>
    <Modal
      v-model="akskConfirm"
      :title="$t('ti-shi')"
      footer-hide
    >
      <div>
        <Alert type="error" show-icon>{{ $t('shu-ju-yuan-mei-you-tian-xie-aksk') }}</Alert>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button @click="akskConfirm=false">{{ $t('guan-bi') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showAddConfirm"
      :title="$t('xin-zeng-shu-ju-yuan-que-ren')"
      @on-ok="handleAdd"
      footer-hide
    >
      <div>
        <p v-if="DataSourceGroup.es.indexOf(addDataSourceForm.type)===-1&&dontAuto.length>0"
           style="font-weight: 500">
          {{ $t('nin-xuan-ze-de-mou-xie-shu-ju-yuan-mei-you-xuan-ze-zi-dong-chuang-jian-zhang-hao-mi-ma-bing-qie-mei-you-shou-dong-shu-ru-zhang-hao-mi-ma-ru-xuan-ze-zi-dong-chuang-jian-zhang-hao-mi-ma-hou-cloudcanal-hui-zi-dong-qu-dui-ying-rds-de-shu-ju-yuan-chuang-jian-zhang-hao-chuang-jian-shu-ju-ren-wu-shi-wu-xu-tian-xie-xiang-guan-xin-xi-ru-mei-you-xuan-ze-zi-dong-chuang-jian-zhang-hao-mi-ma-ze-hou-xu-xu-yao-nin-qin-zi-dao-rds-jin-hang-chuang-jian') }}</p>
        <p style="margin-top: 10px">{{ $t('que-ding-yao-xin-zeng-zhe-xie-shu-ju-yuan-ma') }}</p>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleAdd">{{ $t('que-ren') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { dsMainVersions, dsDrivers } from '@/services/cc/api/rdpConstant';
import { addDataSource } from '@/services/cc/api/rdpUpload';
import DataSourceInfo from '@/components/function/addDataSource/DataSourceInfo';
import AddDataSourceInfo from '@/components/function/addDataSource/AddDataSourceInfo';
// import AddWhiteList from '@/components/function/addDataSource/AddWhiteList';
import SetAccount from '@/components/function/addDataSource/SetAccount';
import SuccessAdd from '@/components/function/addDataSource/SuccessAdd';
import { isDb2, isHana, separatePort } from '@/utils';
import DataSourceGroup from '../dataSourceGroup';
import store from '../../store/index';

export default {
  name: 'AddDataSource',
  components: {
    DataSourceInfo,
    AddDataSourceInfo,
    SetAccount,
    SuccessAdd
  },
  computed: {
    isManual() {
      return this.addDataSourceForm.fetchType && this.addDataSourceForm.fetchType === 'MANUAL_ENTRY';
    },
    isAk() {
      return this.addDataSourceForm.fetchType && this.addDataSourceForm.fetchType === 'ACCESS_KEY';
    }
  },
  data() {
    return {
      akskConfirm: false,
      DataSourceGroup,
      store,
      currentStep: 0,
      showAddConfirm: false,
      dontAuto: [],
      clusters: [],
      versionMap: {},
      driverMap: {},
      addDataSourceForm: {
        fetchType: '',
        fetchTypeList: [],
        dbName: '',
        noValidateDbName: '',
        driver: '',
        dsKvConfigs: [],
        hostList: [{
          type: 'private', display: true, host: '', port: '3306'
        }, {
          type: 'public', display: false, host: '', port: '3306'
        }],
        connectType: null,
        connectTypeValue: '',
        host: '',
        type: 'MySQL',
        region: 'hangzhou',
        instanceType: 'SELF_MAINTENANCE',
        rdsList: [],
        aliyunAk: '',
        aliyunSk: '',
        instanceDesc: '',
        ifAkSK: 'true',
        port: '',
        publicHost: '',
        publicPort: '',
        hdfsSecurityType: 'NONE',
        account: '',
        password: '',
        hdfsPort: '8020',
        securityType: 'KERBEROS',
        hdfsDwDir: '/user/hive/warehouse',
        sid: '',
        service: '',
        accountRole: '',
        asSysDba: false,
        securityFile: '',
        caFile: ''
      },
      securitySetting: []
    };
  },
  mounted() {
    this.getMainVersion();
    this.getDrivers();
  },
  beforeDestroy() {
    store.state.rdsData = [];
    store.state.addedRdsList = [];
    store.state.firstAddDataSource = true;
    store.state.selectedCluster = {};
    store.state.clusterList = [];
  },
  methods: {
    separatePort,
    setSecuritySetting(setting) {
      this.securitySetting = setting;
    },
    getMainVersion() {
      dsMainVersions().then((res) => {
        this.versionMap = res.data.data;
      });
    },
    getDrivers() {
      dsDrivers().then((res) => {
        this.driverMap = res.data.data;
      });
    },
    handleStep(type) {
      if (type === 'pre') {
        this.currentStep--;
        // if (this.currentStep === 1) {
        //   this.addDataSourceForm.rdsList.map((item) => {
        //     if (item.clusters) {
        //       this.clusters[item.instanceId] = item.clusters;
        //     }
        //     return null;
        //   });
        // }
      } else if (this.currentStep === 0) {
        this.securitySetting = this.$refs.dataSourceInfo.securitySetting;
        this.currentStep++;
      } else if (this.currentStep === 1) {
        if (this.isManual || this.addDataSourceForm.ifAkSK === 'false') {
          if (!this.addDataSourceForm.host) {
            this.$Modal.warning({
              title: this.$t('shu-ju-yuan-tian-jia-shi-bai'),
              content: this.$t('qing-tian-xie-shu-ju-yuan-xin-xi')
            });
          } else if (this.addDataSourceForm.type === 'Hive'
            && (!this.addDataSourceForm.hdfsIp
              || !this.addDataSourceForm.hdfsPort
              || !this.addDataSourceForm.hdfsDwDir
              || !this.addDataSourceForm.hdfsSecurityType
              || this.addDataSourceForm.securityType === 'NONE'
              && !this.addDataSourceForm.account
              || this.addDataSourceForm.securityType === 'KERBEROS'
              && !this.addDataSourceForm.hdfsPrincipal)) {
            this.$Modal.warning({
              title: this.$t('shu-ju-yuan-tian-jia-shi-bai'),
              content: this.$t('qing-tian-xie-wan-zheng-de-shu-ju-yuan-xin-xi')
            });
          } else {
            this.currentStep++;
          }
        } else if (this.addDataSourceForm.rdsList.length > 0) {
          const noClusterDataSource = [];

          this.addDataSourceForm.rdsList.map((item) => {
            if (!item.clusters || item.clusters.length < 1) {
              noClusterDataSource.push(item);
            }
            return null;
          });
          if (noClusterDataSource.length > 0) {
            this.$Modal.confirm({
              title: this.$t('shu-ju-yuan-tian-jia-ti-shi'),
              content: this.$t('nin-dang-qian-yi-you-tian-jia-ji-qi-que-ren-dang-qian-suo-xuan-shu-ju-yuan-bu-dui-ci-tian-jia-bai-ming-dan-ru-bu-tian-jia-hou-xu-qing-zhi-shu-ju-yuan-guan-li-tian-jia'),
              onOk: () => {
                this.currentStep++;
              }
            });
          } else {
            this.currentStep++;
          }
        } else {
          this.$Modal.warning({
            title: this.$t('shu-ju-yuan-tian-jia-shi-bai'),
            content: this.$t('qing-xuan-ze-zhi-shao-yi-ge-shu-ju-yuan')
          });
        }
      } else {
        this.currentStep++;
      }
    },
    handleAddDataSource() {
      if (this.addDataSourceForm.instanceType === 'ALIBABA_CLOUD_HOSTED') {
        this.dontAuto = [];
        let aksk = true;
        this.addDataSourceForm.rdsList.map((rds) => {
          if (!rds.autoCreate && (!rds.account || !rds.password)) {
            this.dontAuto.push(rds.instanceId);
          }
          if (this.securitySetting[0] && this.securitySetting[0].needExtraAliyunAkSk && (!rds.accessKey || !rds.secretKey)) {
            aksk = false;
          }
          return null;
        });
        if (!aksk) {
          this.akskConfirm = true;
        } else if (this.dontAuto.length > 0) {
          this.showAddConfirm = true;
        } else {
          this.handleAdd();
        }
      } else {
        this.$refs.dataSourceInfo.$refs.addLocalDs.validate((val) => {
          console.log('local ds', val);
          if (val) {
            const { hostList } = this.addDataSourceForm;
            if (hostList[0].type === 'public') {
              this.addDataSourceForm.publicHost = hostList[0].host;
              this.addDataSourceForm.publicPort = hostList[0].port;
              this.addDataSourceForm.host = hostList[1].host;
              this.addDataSourceForm.port = hostList[1].port;
            } else {
              this.addDataSourceForm.publicHost = hostList[1].host;
              this.addDataSourceForm.publicPort = hostList[1].port;
              this.addDataSourceForm.host = hostList[0].host;
              this.addDataSourceForm.port = hostList[0].port;
            }
            this.dontAuto = [];
            this.addDataSourceForm.rdsList.map((rds) => {
              if (!rds.autoCreate && (!rds.account || !rds.password)) {
                this.dontAuto.push(rds.instanceId);
              }
              return null;
            });
            this.handleAdd();
          }
        });
      }
    },
    handleAdd() {
      this.showAddConfirm = false;
      if (this.isManual && !this.addDataSourceForm.host && !this.addDataSourceForm.publicHost) {
        this.$Modal.warning({
          title: this.$t('tian-jia-shu-ju-yuan-ti-shi'),
          content: this.$t('qing-tian-xie-wan-zheng-qie-zheng-que-de-shu-ju-yuan-di-zhi')
        });
      } else if (this.isManual) {
        const { connectTypeValue, dsKvConfigs } = this.addDataSourceForm;
        const formData = new FormData();
        const isSeparate = this.separatePort(this.addDataSourceForm.type);
        const host = isSeparate ? (this.addDataSourceForm.host && this.addDataSourceForm.port) ? `${this.addDataSourceForm.host}:${this.addDataSourceForm.port}` : ''
          : this.addDataSourceForm.host;
        const publicHost = isSeparate ? (this.addDataSourceForm.publicHost && this.addDataSourceForm.publicPort) ? `${this.addDataSourceForm.publicHost}:${this.addDataSourceForm.publicPort}` : ''
          : this.addDataSourceForm.publicHost;

        const kvConfigs = [];
        if (dsKvConfigs.length) {
          dsKvConfigs.forEach((config) => {
            const { configName, currentCount, defaultValue } = config;
            kvConfigs.push({
              configName,
              configValue: currentCount || defaultValue
            });
          });
        }
        const DataSourceAddData = {
          host: host && this.addDataSourceForm.type === 'Oracle' ? `${host}:${connectTypeValue}` : host,
          privateHost: host && this.addDataSourceForm.type === 'Oracle' ? `${host}:${connectTypeValue}` : host,
          publicHost: publicHost && this.addDataSourceForm.type === 'Oracle'
            ? `${publicHost}:${connectTypeValue}` : publicHost,
          region: this.addDataSourceForm.region,
          type: this.addDataSourceForm.type,
          connectType: this.addDataSourceForm.connectType,
          deployType: this.addDataSourceForm.instanceType,
          instanceDesc: this.addDataSourceForm.instanceDesc,
          hostType: 'PRIVATE',
          account: DataSourceGroup.oracle.indexOf(this.addDataSourceForm.type) > -1
            ? this.addDataSourceForm.asSysDba
              ? `${this.addDataSourceForm.account} as SYSDBA` : this.addDataSourceForm.account : this.addDataSourceForm.account,
          instanceId: this.addDataSourceForm.instanceId,
          password: this.addDataSourceForm.password,
          securityType: this.addDataSourceForm.securityType,
          dbName: (isDb2(this.addDataSourceForm.type) || isHana(this.addDataSourceForm.type)) ? this.addDataSourceForm.dbName : this.addDataSourceForm.noValidateDbName,
          clientTrustStorePassword: this.addDataSourceForm.clientTrustStorePassword,
          dsKvConfigs: kvConfigs,
          // extraData: {
          //   hdfsIp: this.addDataSourceForm.hdfsIp,
          //   hdfsPort: this.addDataSourceForm.hdfsPort,
          //   hdfsDwDir: this.addDataSourceForm.hdfsDwDir,
          //   hdfsPrincipal: this.addDataSourceForm.hdfsPrincipal
          // },
          version: this.addDataSourceForm.version,
          driver: this.addDataSourceForm.driver,
          envId: this.addDataSourceForm.envId
        };

        Object.keys(DataSourceAddData).forEach(((item) => {
          if (typeof DataSourceAddData[item] === 'string') {
            DataSourceAddData[item] = DataSourceAddData[item].trim();
          }
        }));

        formData.append('secretFile', this.addDataSourceForm.secretFile);
        formData.append('securityFile', this.addDataSourceForm.securityFile);
        formData.append('DataSourceAddData', JSON.stringify(DataSourceAddData));
        addDataSource(formData)
          .then((res) => {
            if (res.data.code === '1') {
              this.currentStep = 4;
            }
          });
      } else if (this.addDataSourceForm.ifAkSK === 'true') {
        if (this.getSecurity(this.addDataSourceForm.rdsList[0].securityType).needExtraAliyunAkSk) {
          let ifHasAKSK = true;

          this.addDataSourceForm.rdsList.map((rds) => {
            if (!rds.accessKey || !rds.secretKey) {
              ifHasAKSK = false;
            }
            return null;
          });
          if (!ifHasAKSK) {
            console.log('ifhasaksk', ifHasAKSK);
            this.$Modal.warning({
              title: this.$t('tian-jia-shu-ju-yuan-ti-shi'),
              content: this.$t('you-shu-ju-yuan-mei-you-tian-xie-wan-zheng-deali-yun-aksk-qing-tian-xie-wan-zheng-hou-zai-tian-jia')
            });
            return;
          }
        }
        this.addDataSourceForm.rdsList.map((rds) => {
          const list = [];

          if (rds.clusters) {
            rds.clusters[rds.instanceId].map((item) => {
              list.push(item.id);
              return null;
            });
          }
          // eslint-disable-next-line one-var
          const formData = new FormData(),
            DataSourceAddData = {
              instanceId: rds.instanceId,
              host: rds.host,
              instanceDesc: rds.instanceDesc,
              hostType: rds.hostType,
              password: rds.password,
              account: rds.account,
              clusterIds: list,
              whiteListAddType: rds.whiteListAddType,
              privateHost: rds.privateHost,
              publicHost: rds.publicHost,
              type: rds.dataSourceType,
              securityType: rds.securityType,
              region: this.addDataSourceForm.region,
              deployType: this.addDataSourceForm.instanceType,
              accessKey: rds.accessKey,
              secretKey: rds.secretKey,
              clientTrustStorePassword: rds.clientTrustStorePassword,
              version: rds.version || rds.selectedVersion,
              envId: this.addDataSourceForm.envId
            };

          Object.keys(DataSourceAddData).forEach(((item) => {
            if (typeof DataSourceAddData[item] === 'string') {
              DataSourceAddData[item] = DataSourceAddData[item].trim();
            }
          }));

          formData.append('secretFile', rds.secretFile);
          formData.append('securityFile', rds.securityFile);
          formData.append('DataSourceAddData', JSON.stringify(DataSourceAddData));
          addDataSource(formData)
            .then((res) => {
              if (res.data.code === '1') {
                this.currentStep = 4;
              }
            });
          return null;
        });
      } else {
        const formData = new FormData();
        const
          DataSourceAddData = {
            host: this.addDataSourceForm.host,
            privateHost: this.addDataSourceForm.host,
            publicHost: `${this.addDataSourceForm.publicHost}:${this.addDataSourceForm.publicPort}`,
            hostType: 'PUBLIC',
            region: this.addDataSourceForm.region,
            type: this.addDataSourceForm.type,
            deployType: this.addDataSourceForm.instanceType,
            instanceDesc: this.addDataSourceForm.instanceDesc,
            account: this.addDataSourceForm.account,
            password: this.addDataSourceForm.password,
            instanceId: this.addDataSourceForm.instanceId
          };

        Object.keys(DataSourceAddData).forEach(((item) => {
          if (typeof DataSourceAddData[item] === 'string') {
            DataSourceAddData[item] = DataSourceAddData[item].trim();
          }
        }));

        formData.append('secretFile', this.addDataSourceForm.secretFile);
        formData.append('securityFile', this.addDataSourceForm.securityFile);
        formData.append('DataSourceAddData', JSON.stringify(DataSourceAddData));
        addDataSource(formData)
          .then((res) => {
            if (res.data.code === '1') {
              this.currentStep = 4;
            }
          });
      }
    },
    handleReturn() {
      this.$router.push({ path: '/ccdatasource' });
    },
    handleReset() {
      this.addDataSourceForm = {
        host: '',
        publicHost: '',
        publicPort: '',
        type: 'MySQL',
        region: 'hangzhou',
        instanceType: 'ALIBABA_CLOUD_HOSTED',
        rdsList: [],
        aliyunAk: '',
        aliyunSk: '',
        instanceDesc: '',
        ifAkSK: 'true',
        port: '3306',
        hdfsSecurityType: 'NONE',
        account: '',
        hdfsPort: '8020',
        securityType: 'KERBEROS',
        securityFile: '',
        hdfsDwDir: '/user/hive/warehouse'
      };
    },
    handleCancel() {
      this.showAddConfirm = false;
    },
    getSecurity(type) {
      let security = {};

      this.securitySetting.map((item) => {
        if (item.securityType === type) {
          security = item;
        }
        return null;
      });
      return security;
    }
  }
};
</script>
<style lang="less">
.add-datasource-wrapper {
  background: #ffffff;
  margin-top: 16px;
  border: 1px solid #DADADA;

  .add-datasource-content {
    /*padding: 20px;*/
    margin-bottom: 60px;
  }
}

.add-dataSource-step {
  padding: 30px 380px;
  border-bottom: 1px solid #DADADA;
}

.add-dataSource-tools {
  /*margin-top: 20px;*/
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  background: #ffffff;
  width: 100%;
  line-height: 60px;
  height: 60px;
  z-index: 99;
  box-shadow: 0 2px 23px 0 rgba(197, 197, 197, 0.5);

  button {
    margin: 0 8px;
  }
}
</style>
