import axios from 'axios';
import { Message } from 'view-design';
import { Modal } from 'ant-design-vue';
import { api } from '@/services/http/api';
import { addPending, removePending } from '@/services/http/cencalRequest';
import store from '@/store';
import Cookies from 'js-cookie';
import { checkStatus } from './utils';
import Router from '../../router';
import i18n from '../../i18n';

let authPreMethod = '';

const instance = axios.create({
  timeout: 60000,
  headers: {
    'Accept-Language': i18n.locale
    // 'Access-Control-Allow-Origin': '*'
  },
  transformRequest: [(data) => {
    if (!data) {
      return;
    }
    if (Object.prototype.toString.call(data) !== '[object FormData]') {
      Object.keys(data)
        .map((key) => {
          if (!data[key] && data[key] !== false && data[key] !== 0) {
            data[key] = null;
          }
          return null;
        });
      return JSON.stringify(data);
    }
    return data;
  }]
});

const noTransformInstance = axios.create({
  timeout: 60000
});

instance.defaults.withCredentials = true;
noTransformInstance.defaults.withCredentials = true;

instance.interceptors.request.use((config) => {
  if (config.url.startsWith('/clouddm/console')) {
    config.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
      'X-Product-Code': store.state.selectDmProductCluster,
      ...config.headers
    };
  } else {
    config.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
      ...config.headers
    };
  }
  addPending(config);
  return Promise.resolve(config);
}, (error) => {
  Promise.reject(error);
});

noTransformInstance.interceptors.request.use((config) => {
  config.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
    ...config.headers
  };

  addPending(config);
  return Promise.resolve(config);
}, (error) => {
  Promise.reject(error);
});

instance.interceptors.response.use((res) => {
  removePending(res.config);
  // if (!res.data.permission) {
  //   res.data.success = false;
  //   res.data.code = 403;
  //   Modal.error({
  //     title: i18n.t('quan-xian-yi-chang'),
  //     content: i18n.t('que-shao-quan-xian') + res.data.needAuthKey
  //   });
  // }
  return Promise.resolve(checkStatus(res));
}, (error) => {
  if (error.response) {
    if (error.response.status === 307) {
      if (error.response.headers['x-redirect-addr']) {
        const cookies = Cookies.get();
        const newUrl = `${error.response.headers['x-redirect-addr']}${error.response.config.url}`;
        const headers = {
          'x-is-redirect': true
        };
        if (cookies.jwt_token) {
          headers.jwt_token = cookies.jwt_token;
        }
        return instance.post(newUrl,
          error.response.config.data ? JSON.parse(error.response.config.data) : null,
          {
            headers,
            withCredentials: true,
            credentials: 'include'
          });
      } else {
        // instance.post('').then((response) => response);
      }
    }
    return Promise.reject(checkStatus(error.response));
  }
  if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
    return Promise.reject(new Error(i18n.t('qing-qiu-chao-shi')));
  }
  return Promise.reject(new Error(error));
});

noTransformInstance.interceptors.response.use((res) => {
  removePending(res.config);
  return Promise.resolve(checkStatus(res));
}, (error) => {
  if (error.response) {
    return Promise.reject(checkStatus(error.response));
  }
  if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
    return Promise.reject(new Error(i18n.t('qing-qiu-chao-shi')));
  }
  return Promise.reject(new Error(error));
});

const request = async (opt) => {
  const options = {
    method: 'post',
    ...opt
  };

  const {
    url: requestUrl,
    msg,
    modal = true,
    page
  } = options;

  try {
    // if (!['/login', '/register', '/logout', '/global_settings', '/list_org', '/login_supplement', '/check_supplement', '/load_supplement_info'].includes(options.url)) {
    //   options.baseURL = process.env.VUE_APP_BASE_URL;
    // }
    const res = ['/datasource/addds'].includes(requestUrl) ? await noTransformInstance(options) : await instance(options);
    if (res.success && requestUrl === api.verifyOpPwd) {
      window.$bus.emit(authPreMethod);
      authPreMethod = '';
    }

    if (!opt.noStatus) {
      if (!res.success) {
        switch (res.code) {
          case '2011':
            window.$bus.emit('setCloudAKSKModal');
            break;
          case '6001':
            window.$bus.emit('dingDingSettingModal');
            break;
          default:
            if (['/login', '/datasource/connectds'].includes(requestUrl)) {
              return res;
            }
            if (['/datasource/schema/rightclickschema'].includes(requestUrl) && res.data && res.data.next) {
              return res;
            }
            if (modal) {
              Modal.error({
                title: i18n.t('cuo-wu'),
                class: 'limit-height',
                width: 500,
                okText: i18n.t('que-ding'),
                zIndex: 9999,
                content: res.msg ? (res.msg[0] === '[' && res.msg[res.msg.length - 1] === ']' ? JSON.parse(res.msg)[0] : res.msg) : '系统异常,请联系管理员'
              });
              return res;
            }
        }
      } else if (res.success && msg) {
        Message.success(options.msg);
      }
    }
    return res;
  } catch (err) {
    if (typeof err === 'object' && err.toString().includes('Cancel')) {
      return err;
    }
    switch (err.status) {
      case 401:
        await Router.push({ name: 'Login' });
        break;
      case 498:
        window.$bus.emit('setOpPasswordModal');
        break;
      case 499:
        if (requestUrl === api.listSchemaFirstLevel) {
          authPreMethod = `${page}_listfirstlevel_callback`;
        }
        if (requestUrl === api.createSession) {
          authPreMethod = `${page}_listfirstlevel_callback`;
        }
        window.$bus.emit('showEnterOpPwdModal');
        break;
      case 406:
        Modal.error({
          title: i18n.t('quan-xian-yi-chang'),
          content: err.msg || i18n.t('nin-mei-you-gai-quan-xian-de-cao-zuo-qing-lian-xi-zhu-zhang-hao-huo-guan-li-yuan')
        });
        break;
      case 307:
        break;
      default:
        const url = new URL(err.config.url);
        const pathList = url.pathname.split('/');
        if (pathList.length > 1 && pathList[1] === 'clouddm') {
          if (url.pathname !== '/clouddm/console/api/v1/dm_global_setting') {
            Modal.error({
              title: 'ERROR',
              content: '该产品集群无法连通，请检查该集群是否正常'
            });
          }
        } else {
          Modal.error({
            title: 'Error',
            content: err.msg || i18n.t('xi-tong-yi-chang')
          });
        }
    }
    return err;
  }
};

export default request;
