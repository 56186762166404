<template>
  <div class="table-list-container">
    <div class="table-list-resize"/>
    <a-select v-model="currentTab.leafType" @change="handleChangeTab" size="small"
              class="table-leaf-type-select">
      <a-select-option v-for="tab in getLeafGroup(currentTab.node.INSTANCE.attr.dsType)"
                       :key="tab.type">
        {{ tab.i18n }}
      </a-select-option>
    </a-select>
    <div class="table-list-content">
      <div class="search-header">
        <a-input v-model="currentTab[currentTab.leafType].searchKey" style="margin-right: 6px;"
                 @change="handleSearch" size="small" allowClear>
          <a-icon slot="prefix" type="search"/>
        </a-input>
        <cc-svg-icon name="focus" @click.native="handleFocus" style="cursor:pointer;"></cc-svg-icon>
        <cc-svg-icon style="margin-left: 2px" name="refresh" @click.native="handleRefreshTree" :size="16"></cc-svg-icon>
<!--        <cc-iconfont style="margin-right: 6px;margin-left: 6px;" @click.native="handleRefreshTree"-->
<!--                     :size="14"-->
<!--                     class="icon"-->
<!--                     color="#999999"-->
<!--                     name="refresh"></cc-iconfont>-->
      </div>
      <div class="table-query-item-container" @contextmenu.prevent.stop="onContextmenu"
           :class="!['TABLE', 'VIEW', 'PROC', 'FUNC'].includes(currentTab.leafType) ? 'no-indent' : ''">
        <loading :active.sync="tableListLoading" :is-full-page="false"></loading>
        <c-tree
          class="table-list-tree"
          ref="tableTree"
          key-field="key"
          :load="handleExpandLoadNode"
          :expanded-keys="currentTab.expandedKeys"
          :render="renderNode"
          :nodeIndent="20"
          :renderNodeAmount="200"
          @click="handleSelectTable"
          @node-dblclick="handleDblClick"
          @expand="handleSetExpandedKeys"
          @node-right-click="handleNodeRightClick"
        >
          <template #empty>
            <div style="margin-bottom: 10px;font-weight: bold" v-if="!tableListLoading">{{ $t('zan-wu-shu-ju') }}
            </div>
            <a-button @click="handleRefreshTree" type="primary" v-if="!tableListLoading">{{ $t('shua-xin') }}
            </a-button>
          </template>
        </c-tree>
      </div>
    </div>
    <a-modal :title="menuModal.title" v-model="menuModal.show" :mask-closable="false"
             :closable="false" :keyboard="false">
      <div style="margin-bottom: 5px;font-weight: bold;">
        {{ menuModal.content }}
      </div>
      <a-collapse :bordered="false" size="small" style="margin: 5px 0;"
                  v-model="menuModal.collapseKey" :destroyInactivePanel="true">
        <a-collapse-panel :header="$t('gao-ji-pei-zhi')" key="options">
          <div v-for="setting in advancedSetting" :key="setting.value">
            <a-checkbox :value="menuModal.options[setting.value]"
                        @change="handleMenuOptionChange(setting.value, $event)">
              {{ setting.label }}
            </a-checkbox>
          </div>
        </a-collapse-panel>
      </a-collapse>
      <div style="display: flex;" v-if="menuModal.showNameInput">
        <a-input :value="menuModal.name" :placeholder="$t('qing-shu-ru-xin-de-ming-zi')" @change="handleMenuNameChange"
                 allow-clear/>
      </div>
      <div style="margin-top: 5px;font-weight: bold;" v-if="menuModal.sql">{{ $t('sql-yu-ju') }}:</div>
      <div style="width: 100%;border: 1px solid #ccc;padding: 3px 10px;" v-if="menuModal.sql">
        <read-only-editor :text="menuModal.sql"/>
      </div>
      <div class="footer">
        <a-button type="primary" @click="handleRightClickMenu(actionType)" v-if="!menuModal.sql"
                  :disabled="!menuModal.name">{{ $t('sheng-cheng-sql-yu-ju') }}
        </a-button>
        <a-button v-if="menuModal.permission && menuModal.sql"
                  :type="menuModal.danger ? 'danger' : 'primary'"
                  @click="handleDoAction" :loading="doActionLoading">{{ $t('li-ji-zhi-hang') }}
        </a-button>
        <a-button v-if="!menuModal.permission && menuModal.sql">{{ $t('ti-jiao-gong-dan') }}</a-button>
        <a-button @click="handleCloseModal">{{ $t('guan-bi') }}</a-button>
      </div>
    </a-modal>
    <a-modal :title="sqlModal.title" v-model="sqlModal.show" :mask-closable="false"
             :closable="false" :keyboard="false" :width="800">
      <div class="content">
        <div class="header" style="margin-bottom: 10px;" v-if="currentDdlList.length">
          <a-radio-group v-model="sqlModal.type" @change="handleStruct">
            <a-radio value="Request" v-if="currentDdlList.includes('Request')">{{ $t('huo-qu-ddl-yu-ju') }}</a-radio>
            <a-radio value="Convert" v-if="currentDdlList.includes('Convert')">{{ $t('sheng-cheng-ddl-yu-ju') }}</a-radio>
          </a-radio-group>
          <a-select v-if="currentTargetDsList.length && sqlModal.type === 'Convert'"
                    v-model="sqlModal.dsType"
                    @change="handleStruct"
                    style="width: 200px;"
          >
            <a-select-option v-for="ds in currentTargetDsList" :value="ds" :key="ds">{{ ds }}
            </a-select-option>
          </a-select>
        </div>
        <a-collapse :bordered="false" size="small" style="margin: 5px 0;"
                    v-model="menuModal.collapseKey" :destroyInactivePanel="true"
                    v-if="sqlModal.type === 'Convert'">
          <a-collapse-panel :header="$t('gao-ji-pei-zhi')" key="options">
            <div v-for="setting in structAdvancedSetting" :key="setting.value">
              <a-checkbox :value="menuModal.options[setting.value]"
                          @change="handleMenuOptionChange(setting.value, $event)">
                {{ setting.label }}
              </a-checkbox>
            </div>
          </a-collapse-panel>
        </a-collapse>
        <div
          style="width: 100%;border: 1px solid #ccc;padding: 3px 10px;overflow: auto;max-height: 500px;">
          <loading :active.sync="sqlModal.loading" :is-full-page="false"></loading>
          <pre>{{ sqlModal.sql.join('\n') }}</pre>
        </div>
      </div>
      <div class="footer">
        <!--        <a-button @click="handleStruct" type="primary">重新生成</a-button>-->
        <a-button @click="copyText(sqlModal.sql)" type="primary">{{ $t('fu-zhi-sql') }}</a-button>
        <a-button @click="handleCloseModal">{{ $t('guan-bi') }}</a-button>
      </div>
    </a-modal>
    <a-modal v-model="triggerModal.show" :title="triggerModal.title" :mask-closable="false"
             :closable="false" :keyboard="false" :width="800">
      <div class="trigger-modal">
        <a-form :form="triggerModal.options" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }"
                v-show="!triggerModal.sql">
          <a-form-item :label="$t('chu-fa-ming')">
            <a-input v-model="triggerModal.options.triggerName" size="small"/>
          </a-form-item>
          <a-form-item :label="$t('chu-fa-shi-jian')">
            <a-radio-group v-model="triggerModal.options.triggerTime" size="small">
              <a-radio value="BEFORE">{{ $t('cao-zuo-zhi-qian') }}</a-radio>
              <a-radio value="AFTER">{{ $t('cao-zuo-zhi-hou') }}</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item :label="$t('cao-zuo-lei-xing')">
            <a-radio-group v-model="triggerModal.options.triggerEvent"
                           v-if="isMySQL(currentTab.dsType)">
              <a-radio value="DELETE">{{ $t('shan-chu') }}</a-radio>
              <a-radio value="UPDATE">{{ $t('geng-xin') }}</a-radio>
              <a-radio value="INSERT">{{ $t('cha-ru') }}</a-radio>
            </a-radio-group>
            <a-checkbox-group v-else v-model="triggerModal.options.triggerEvent"
                              @change="handleTriggerColumnsChange">
              <a-checkbox value="DELETE">{{ $t('shan-chu') }}</a-checkbox>
              <a-checkbox value="INSERT">{{ $t('cha-ru') }}</a-checkbox>
              <a-checkbox value="UPDATE">{{ $t('geng-xin') }}</a-checkbox>
            </a-checkbox-group>
          </a-form-item>
          <a-form-item :label="$t('geng-xin-de-lie')"
                       v-if="!isMySQL(currentTab.dsType) && triggerModal.options.triggerEvent.includes('UPDATE')">
            <a-select v-model="triggerModal.options.triggerColumns" mode="multiple">
              <a-select-option v-for="column in triggerModal.columnList" :key="column.name">
                {{ column.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="$t('chu-fa-biao-ming')">
            <a-input v-model="triggerModal.options.triggerTable" size="small" disabled/>
          </a-form-item>
          <a-form-item :label="$t('chu-fa-qi-ti')">
            <div class="trigger-monaco-editor" ref="triggerMonacoEditor">

            </div>
          </a-form-item>
        </a-form>
        <div v-show="triggerModal.sql"
             style="width: 100%;border: 1px solid #ccc;padding: 3px 10px;overflow: auto;max-height: 500px;">
          <loading :active.sync="sqlModal.loading" :is-full-page="false"></loading>
          <pre>{{ triggerModal.sql }}</pre>
        </div>
      </div>
      <div class="footer">
        <a-button type="primary" @click="handleRightClickMenu(actionType)" v-if="!triggerModal.sql"
                  :disabled="!triggerModal.options.triggerName">{{ $t('sheng-cheng-sql-yu-ju') }}
        </a-button>
        <a-button v-if="triggerModal.sql" @click="handlePreCreateTrigger">
          {{ $t('fan-hui-xiu-gai') }}
        </a-button>
        <a-button v-if="triggerModal.permission && triggerModal.sql"
                  :type="triggerModal.danger ? 'danger' : 'primary'"
                  @click="handleDoAction" :loading="doActionLoading" style="margin-right: 10px;">
          {{ $t('li-ji-zhi-hang') }}
        </a-button>
        <a-button v-if="!triggerModal.permission && triggerModal.sql">{{ $t('ti-jiao-gong-dan') }}</a-button>
        <a-button @click="handleCloseModal">{{ $t('guan-bi') }}</a-button>
      </div>
    </a-modal>
    <a-modal :title="genDataModal.title" v-model="genDataModal.show" :width="1000"
             v-if="genDataModal.show"
             :mask-closable="false" :keyboard="false" @cancel="handleCloseModal">
      <div class="generate-modal">
        <div class="advanced" v-show="genDataModal.step === 1">
          <div class="title">{{ $t('gao-ji-xuan-xiang') }}</div>
          <div class="options">
            <div class="option">
              <a-input v-model="genDataModal.globalConfigs.producer" size="small"
                       :addon-before="$t('sheng-chan-bing-fa-du')"/>
              <a-tooltip placement="bottom" style="margin-left: 3px;margin-right: 5px;">
                <a-icon type="question-circle"/>
                <template slot="title">
                  {{ $t('zao-shu-ju-zhong-sheng-cheng-sui-ji-shu-ju-de-shu-liang') }}
                </template>
              </a-tooltip>
            </div>
            <div class="option">
              <a-input v-model="genDataModal.globalConfigs.writer" size="small"
                       :addon-before="$t('xie-ru-bing-fa-du')"/>
              <a-tooltip placement="bottom" style="margin-left: 3px;margin-right: 5px;">
                <a-icon type="question-circle"/>
                <template slot="title">
                  {{ $t('zao-shu-ju-zhong-jiang-shu-ju-xie-ru-shu-ju-ku-xian-cheng-de-shu-liang') }}
                </template>
              </a-tooltip>
            </div>
            <div class="option">
              <a-checkbox v-model="genDataModal.globalConfigs.ignoreErrors">{{ $t('yu-dao-cuo-wu-shi-ji-xu') }}
              </a-checkbox>
              <a-tooltip placement="bottom" style="margin-left: 3px;margin-right: 5px;">
                <a-icon type="question-circle"/>
                <template slot="title">
                  {{ $t('sheng-cheng-shu-ju-shi-ru-yu-dao-duplicate-key-deng-cuo-wu-hu-lve-bing-ji-xu-sheng-cheng-shu-ju') }}
                </template>
              </a-tooltip>
            </div>
            <div class="option">
              <a-checkbox v-model="genDataModal.globalConfigs.transaction">{{ $t('kai-qi-shi-wu') }}</a-checkbox>
              <a-tooltip placement="bottom" style="margin-left: 3px;margin-right: 5px;">
                <a-icon type="question-circle"/>
                <template slot="title">
                  {{ $t('ru-guo-shu-ju-ku-zhi-chi-shi-wu-gou-xuan-ci-xuan-xiang-jiang-kai-qi-shi-wu') }}
                </template>
              </a-tooltip>
            </div>
          </div>
          <div class="options" style="margin-top: 5px;"
               v-if="actionType === TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER_INCREMENT">
            <div class="option">
              <a-input v-model="genDataModal.globalConfigs.time" :addon-before="$t('yun-hang-shi-jian')" addon-after="秒"
                       size="small"></a-input>
              <a-tooltip placement="bottom" style="margin-left: 3px;margin-right: 5px;">
                <a-icon type="question-circle"/>
                <template slot="title">
                  {{ $t('zeng-liang-cao-zuo-yun-hang-shi-jian-dan-wei-miao') }}
                </template>
              </a-tooltip>
            </div>
            <div class="option">
              <a-input v-model="genDataModal.globalConfigs.insertRatio" addon-before="INSERT"
                       size="small" addon-after="%"></a-input>
              <a-tooltip placement="bottom" style="margin-left: 3px;margin-right: 5px;">
                <a-icon type="question-circle"/>
                <template slot="title">
                  {{ $t('cha-ru-cao-zuo-de-bi-shuai') }}
                </template>
              </a-tooltip>
            </div>
            <div class="option">
              <a-input v-model="genDataModal.globalConfigs.updateRatio" addon-before="UPDATE"
                       size="small" addon-after="%"></a-input>
              <a-tooltip placement="bottom" style="margin-left: 3px;margin-right: 5px;">
                <a-icon type="question-circle"/>
                <template slot="title">
                  {{ $t('geng-xin-cao-zuo-de-bi-shuai') }}
                </template>
              </a-tooltip>
            </div>
            <div class="option">
              <a-input v-model="genDataModal.globalConfigs.deleteRatio" addon-before="DELETE"
                       size="small" addon-after="%"></a-input>
              <a-tooltip placement="bottom" style="margin-left: 3px;margin-right: 5px;">
                <a-icon type="question-circle"/>
                <template slot="title">
                  {{ $t('shan-chu-cao-zuo-de-bi-shuai') }}
                </template>
              </a-tooltip>
            </div>
          </div>
        </div>
        <div class="content" v-show="genDataModal.step === 1">
          <div class="title">{{ $t('biao-she-zhi') }}</div>
          <div class="table-setting">
            <div class="left">
              <div class="search">
                <a-input v-model="genDataModal.searchKey" size="small"
                         @change="handleGenDataFilter(genDataModal.searchKey)">
                  <a-icon slot="prefix" type="search"/>
                </a-input>
              </div>
              <c-tree
                checkable
                class="datasource-tree"
                ref="genDataTree"
                key-field="key"
                :load="handleGenDataExpandLoadNode"
                :expanded-keys="genDataModal.expandedKeys"
                :render="renderGenDataNode"
                :nodeIndent="20"
                :auto-load="false"
                @click="handleGenDataSelectTable"
                @expand="handleGenDataSetExpandedKeys"
                @check="handleGenDataCheck"
              >
                <template #empty>
                  <div style="margin-bottom: 10px;font-weight: bold">{{ $t('zan-wu-shu-ju') }}
                  </div>
                  <a-button @click="handleRefreshTree" type="primary">{{ $t('shua-xin') }}
                  </a-button>
                </template>
              </c-tree>
            </div>
            <div class="right">
              <div v-if="genDataModal.selectedNode">
                <div v-if="genDataModal.selectedNode.configType === 'tableConfig'">
                  <b>{{ $t('biao') }}: {{genDataModal.selectedNode.title}}</b>
                </div>
                <div v-if="genDataModal.selectedNode.configType === 'columnConfig'">
                  <b>{{ $t('biao') }}: {{genDataModal.selectedNode.tableName}} {{ $t('lie') }}: {{genDataModal.selectedNode.title}}</b>
                </div>
                <create-table-item v-for="component in genDataModal.selectedSchema"
                                   :current-schema="component"
                                   :key="component.field"
                                   :tab="currentTab"
                                   :form-data="genDataModal.tableConfigs"
                                   :node-type="genDataModal.selectedNode.configType"
                                   :selected-node="genDataModal.selectedNode"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="preview" v-if="genDataModal.step === 2">
          <div class="left">
            <div v-for="config in genDataModal.selectedTaleConfigs"
                 :style="`background: ${selectedTableConfig.name === config.name ? '#ccc' : '#fff'}`"
                 :key="config.name" @click="handleSelectTableConfig(config)">
              {{ config.name }}
            </div>
          </div>
          <div class="right">
            <c-c-read-only-table :cell-data="cellData" :column-list="columnList"
                                 :selected-config="selectedTableConfig" :width="652"/>
          </div>
        </div>
        <div class="result" v-if="genDataModal.step === 3">
          <div class="header">
            <div class="info">
              <div class="line">
                <div><b>&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('zong-cheng-gong-hang') }}: </b>{{ genDataModal.successTotal }}</div>
                <div><b>&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('zong-shi-bai-hang') }}: </b>{{ genDataModal.failedTotal }}</div>
                <div><b>&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('ping-jun-xie-ru') }}: </b>{{ genDataModal.writeAvgTime }}</div>
              </div>
              <div class="line">
                <div><b>{{ $t('cheng-gong-cha-ru-hang') }}: </b>{{ genDataModal.successInsertTotal }}</div>
                <div><b>{{ $t('cheng-gong-shan-chu-hang') }}: </b>{{ genDataModal.successDeleteTotal }}</div>
                <div><b>{{ $t('cheng-gong-xiu-gai-hang') }}: </b>{{ genDataModal.successUpdateTotal }}</div>
              </div>
              <div class="line">
                <div><b>{{ $t('shi-bai-cha-ru-hang') }}: </b>{{ genDataModal.failedInsertTotal }}</div>
                <div><b>{{ $t('shi-bai-shan-chu-hang') }}: </b>{{ genDataModal.failedDeleteTotal }}</div>
                <div><b>{{ $t('shi-bai-xiu-gai-hang') }}: </b>{{ genDataModal.failedUpdateTotal }}</div>
              </div>
              <div><b>{{ $t('ri-zhi-wen-jian') }}: </b>{{genDataModal.logFile}}</div>
              <div v-if="!isDesktop"><b>{{ $t('ri-zhi-wen-jian-suo-zai-fu-wu-qi') }}: </b>{{genDataModal.logHost}}</div>
            </div>
            <div>
              <a-checkbox v-model="genDataModal.refresh" @change="handleLogRefreshChange">
                {{ $t('mei-1-miao-shua-xin') }}
              </a-checkbox>
              <a-button @click="handleObtainLog" size="small">{{ $t('shua-xin') }}</a-button>
            </div>
          </div>
          <div class="logs">
            <div v-if="!genDataModal.logArr.length">{{ $t('huo-qu-shu-ju-ri-zhi-zhong') }}</div>
            <DynamicScroller class="scroller" :items="genDataModal.logArr" :min-item-size="20" :item-height="20">
              <template v-slot="{ item }">
                <div class="log" v-html="highlightLog(item)">{{item}}</div>
              </template>
            </DynamicScroller>
<!--            <div v-for="(log, index) in genDataModal.logArr" :key="index" class="log">{{-->
<!--                log-->
<!--              }}-->
<!--            </div>-->
          </div>
        </div>
      </div>
      <div class="footer">
        <a-button v-if="genDataModal.step !== 1"
                  @click="handlePreStep">{{ $t('shang-yi-bu') }}
        </a-button>
        <a-button type="primary" v-if="genDataModal.step === 1" @click="handlePreview"
                  :loading="genDataModal.loading">{{ $t('yu-lan') }}
        </a-button>
        <a-button type="primary" v-if="genDataModal.step === 2" style="margin-right: 10px;"
                  @click="handleGenData" :loading="genDataModal.loading">{{this.genDataModal.title}}
        </a-button>
        <a-button v-if="genDataModal.step === 3 && showTaskPauseBtn" style="margin-right: 10px;" :loading="genDataModal.loading"
                  @click="handleAsyncEventAction('pause')">
          {{ $t('zan-ting') }}
        </a-button>
        <a-button
          v-if="genDataModal.step === 3 && showTaskResumeBtn"
          @click="handleAsyncEventAction('resume')" style="margin-right: 10px;" :loading="genDataModal.loading">
          {{ $t('hui-fu') }}
        </a-button>
        <a-button @click="handleCloseFaker" v-if="genDataModal.step === 3 && showTaskCancelBtn" style="margin-right: 10px;" :loading="genDataModal.loading">
          {{ $t('ting-zhi') }}</a-button>
        <a-button @click="handleCloseModal" v-if="genDataModal.step === 3" style="margin-right: 10px;">
          {{ $t('zui-xiao-hua') }}</a-button>
        <a-button @click="handleCloseModal" v-if="genDataModal.step !== 3">
          {{ $t('guan-bi') }}</a-button>
      </div>
    </a-modal>
  </div>
</template>
<script>
import CTree from '@/components/ctree/ctree.umd';
import Loading from 'vue-loading-overlay';
import { mapGetters, mapState } from 'vuex';
import deepClone from 'lodash.clonedeep';
import copyMixin from '@/mixins/copyMixin';
import { ACTION_TYPE, TAB_TYPE } from '@/const';
import datasourceMixin from '@/mixins/datasourceMixin';
import { isMySQL, isRedis } from '@/const/dataSource';
import browseMixin from '@/mixins/browseMixin';
import { FAKER_TASK_STATUS, TABLE_RIGHT_CLICK_MENU_ITEM } from '@/utils';
import utilMixin from '@/mixins/utilMixin';
import { clearAllPending } from '@/services/http/cencalRequest';
import ReadOnlyEditor from '@/components/ReadOnlyEditor';
import CreateTableItem from '@/components/modal/CreateTableItem';
import CCReadOnlyTable from '@/components/widgets/CCReadOnlyTable';
import * as monaco from 'monaco-editor';
import { Modal } from 'ant-design-vue';
import { DynamicScroller } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
// import GenDataModal from '@/views/sql/components/modal/GenDataModal.vue';

const BG_COLOR = {
  Insert: 'rgb(236, 255, 220)',
  Delete: 'rgb(250, 128, 114)',
  Update: 'yellow'
};

export default {
  name: 'TableList',
  mixins: [copyMixin, datasourceMixin, browseMixin, utilMixin],
  components: {
    CCReadOnlyTable,
    CreateTableItem,
    ReadOnlyEditor,
    DynamicScroller,
    // GenDataModal,
    Loading,
    CTree
  },
  props: {
    rdbTableDetail: Function,
    getNodeData: Function,
    handleAddTab: Function,
    tableListLoading: Boolean,
    currentTab: {
      type: Object,
      default: () => {
      }
    },
    listLeaf: Function,
    handleQueryTable: Function,
    listDbTables: Function,
    handleShowStruct: Function,
    handleGoTicket: Function,
    handleNewDbQuery: Function,
    handleShowData: Function
  },
  data() {
    return {
      monacoEditor: null,
      defaultOpts: {
        value: 'begin\n \n end', // 编辑器的值
        language: 'mysql',
        fontSize: 14,
        fontWeight: 'bold',
        theme: 'vs', // 编辑器主题：vs, hc-black, or vs-dark，更多选择详见官网
        minimap: {
          enabled: false
        },
        automaticLayout: true,
        lineNumbers: 'off',
        autoIndent: true // 自动缩进
      },
      triggerModal: {
        columnList: [],
        show: false,
        permission: false,
        sql: '',
        title: '',
        actionData: {},
        columns: [],
        options: {
          triggerName: '',
          triggerTime: 'BEFORE',
          triggerEvent: '',
          triggerTable: '',
          triggerColumns: []
        }
      },
      currentDdlList: [],
      currentTargetDsList: [],
      TABLE_RIGHT_CLICK_MENU_ITEM,
      selectedTableConfig: {},
      schemaDef: {},
      scrollY: 0,
      top: 0,
      selectedNode: null,
      advancedSetting: [
        {
          value: 'delimited',
          label: this.$t('shi-yong-xian-ding-fu-bao-guo-shu-ju-ku-dui-xiang-ming')
        }, {
          value: 'usingExists',
          label: this.$t('shi-yong-if-exists-zi-ju')
        }, {
          value: 'cascade',
          label: this.$t('shi-yong-cascade-zi-ju-jin-hang-qiang-zhi-shan-chu')
        }, {
          value: 'restrict',
          label: this.$t('shi-yong-restrict-zi-ju-zai-xian-zhi-tiao-jian-xia-shan-chu')
        }, {
          value: 'purge',
          label: this.$t('shi-yong-purge-zi-ju-jin-hang-zi-yuan-hui-shou')
        }, {
          value: 'truncateUseDelete',
          label: this.$t('shi-yong-delete-yu-ju-ti-dai-truncate-yu-ju')
        }
      ],
      structAdvancedSetting: [
        {
          value: 'delimited',
          label: this.$t('shi-yong-xian-ding-fu-bao-guo-shu-ju-ku-dui-xiang-ming')
        }
      ],
      genDataModal: {
        loading: false,
        startLine: 0,
        resume: false,
        sessionId: '',
        dataSourceId: '',
        tableConfigColumns: {},
        refresh: true,
        timer: null,
        selectedSchema: {},
        selectedIndex: -1,
        searchKey: '',
        tableConfigs: [],
        selectedTaleConfigs: [],
        treeData: [],
        show: false,
        step: 1,
        previewLoading: false,
        failedDeleteTotal: 0,
        failedInsertTotal: 0,
        failedTotal: 0,
        failedUpdateTotal: 0,
        successDeleteTotal: 0,
        successInsertTotal: 0,
        successTotal: 0,
        successUpdateTotal: 0,
        writeAvgTime: '0毫秒',
        logArr: [],
        status: '',
        logFile: '',
        logHost: '',
        globalConfigs: {
          producer: 3,
          writer: 10,
          ignoreErrors: true,
          transaction: false,
          insertRatio: '30',
          updateRatio: '30',
          deleteRatio: '30',
          time: '300'
        },
        selectedNode: null,
        expandedKeys: [],
        checkedKeys: []
      },
      sqlModal: {
        loading: false,
        dsType: '',
        type: 'Request',
        title: this.$t('huo-qu-ddl-yu-ju'),
        show: false,
        sql: [],
        permission: false,
        danger: false,
        options: {
          delimited: false,
          usingExists: false,
          cascade: false,
          restrict: false,
          purge: false,
          truncateUseDelete: false
        }
      },
      menuModal: {
        actionData: {},
        show: false,
        title: '',
        content: '',
        name: '',
        preName: '',
        showNameInput: false,
        sql: '',
        permission: false,
        danger: false,
        collapseKey: '',
        options: {
          delimited: false,
          usingExists: false,
          cascade: false,
          restrict: false,
          purge: false,
          truncateUseDelete: false
        }
      },
      columnList: [],
      previewData: {},
      cellData: [],
      actionType: '',
      menuList: [],
      tableListItemLength: 0,
      doActionLoading: false
    };
  },
  mounted() {
    const currentLeaf = this.currentTab[this.currentTab.leafType];
    if (currentLeaf.treeData) {
      this.handleSetData(currentLeaf.treeData);
    }

    const tableListTreeList = $('.table-list-tree');
    if (tableListTreeList && tableListTreeList.length) {
      tableListTreeList[0].addEventListener('scroll', this.handleSetScrollTop, true);
    }

    window.$bus.on('showFakerModal', (task) => this.showFakerDetail(task));
  },
  beforeDestroy() {
    const tableListTreeList = $('.table-list-tree');
    if (tableListTreeList && tableListTreeList.length) {
      tableListTreeList[0].removeEventListener('scroll', this.handleSetScrollTop, true);
    }
    window.$bus.off('showFakerModal', this.showFakerDetail);
  },
  computed: {
    ...mapState(['globalDsSetting']),
    ...mapGetters([
      'isDesktop',
      'getLeafGroup',
      'getMenus',
      'getBrowserMenus',
      'getQuickQuery',
      'genQualifierText',
      'targetDsList',
      'ddlList'
    ]),
    showTaskCancelBtn() {
      const {
        INIT, RUNNING, PAUSE, WAITING_RESUME, WAITING_PAUSE
      } = FAKER_TASK_STATUS;
      return [INIT, RUNNING, PAUSE, WAITING_RESUME, WAITING_PAUSE].includes(this.genDataModal.status);
    },
    showTaskPauseBtn() {
      const { RUNNING, WAITING_PAUSE } = FAKER_TASK_STATUS;
      return [RUNNING, WAITING_PAUSE].includes(this.genDataModal.status);
    },
    showTaskRetryBtn() {
      const { COMPLETE } = FAKER_TASK_STATUS;
      return [COMPLETE].includes(this.genDataModal.status);
    },
    showTaskResumeBtn() {
      const { PAUSE, WAITING_RESUME } = FAKER_TASK_STATUS;
      return [PAUSE, WAITING_RESUME].includes(this.genDataModal.status);
    }
  },
  methods: {
    async handleAsyncEventAction(actionType) {
      try {
        let res;
        let msg;
        this.genDataModal.loading = true;
        switch (actionType) {
          case 'pause':
            res = await this.$services.fakerPause({
              data: {
                dataSourceId: this.currentTab.node.INSTANCE.id,
                toolSessionId: this.genDataModal.sessionId
              }
            });
            msg = this.$t('zan-ting-cheng-gong');
            break;
          case 'resume':
            res = await this.$services.fakerResume({
              data: {
                dataSourceId: this.currentTab.node.INSTANCE.id,
                toolSessionId: this.genDataModal.sessionId
              }
            });
            msg = this.$t('hui-fu-cheng-gong');
            break;
          default:
            break;
        }

        this.genDataModal.loading = false;
        if (res.success) {
          this.$message.success(msg);
          switch (actionType) {
            case 'pause':
              this.clearLogInterVal();
              this.handleObtainLog(false);
              break;
            case 'resume':
              this.handleObtainLog();
              break;
            default:
              break;
          }
        }
      } catch (e) {
        this.genDataModal.loading = false;
      }
    },
    async showFakerDetail(task) {
      this.genDataModal.step = 3;
      this.genDataModal.show = true;
      const res = await this.$services.fakerFetchUiConfig({
        data: {
          toolSessionId: task.bizId
        }
      });

      if (res.success) {
        this.genDataModal = { ...this.genDataModal, ...res.data };
        this.genDataModal.preTableConfigs = deepClone(res.data.tableConfigs);
        this.genDataModal.sessionId = task.bizId;
        this.genDataModal.dataSourceId = res.data.levels[1];
        this.genDataModal.resume = true;
        this.actionType = res.data.type === 'FULL' ? TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER : TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER_INCREMENT;
        this.genDataModal.title = this.actionType === TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER ? '生成数据' : '压力测试';
        this.genDataModal.startLine = -1;
        this.handleObtainLog();
      }
    },
    handleTriggerColumnsChange(value) {
      if (!value.includes('UPDATE')) {
        this.triggerModal.options.triggerColumns = [];
      }
    },
    isMySQL,
    async handleStruct() {
      this.sqlModal.loading = true;
      const {
        node,
        selectedTable: table,
        leafType
      } = this.currentTab;
      if (this.sqlModal.type === 'Request') {
        const res = await this.$services.requestScript({
          data: {
            levels: this.browseGenLevelsData(node),
            targetType: leafType,
            targetName: table.title
          }
        });

        if (res.success) {
          this.sqlModal.sql = [res.data.sql];
        }
      } else if (this.sqlModal.type === 'Convert') {
        const res = await this.$services.convertDDL({
          data: {
            levels: this.browseGenLevelsData(node),
            leafType,
            sourceTableName: table.title,
            targetDsType: this.sqlModal.dsType,
            options: this.sqlModal.options
          }
        });

        if (res.success) {
          this.sqlModal.sql = res.data.sql;
        }
      }

      this.sqlModal.loading = false;
    },
    handleSelectTableConfig(tableConfig) {
      this.selectedTableConfig = tableConfig;
      const cellData = [];
      const currentTableData = this.previewData[tableConfig.name];
      if (currentTableData && currentTableData.length) {
        this.columnList = this.genDataModal.tableConfigColumns[tableConfig.name];
        this.columnList.forEach((column, columnIndex) => {
          currentTableData.forEach((row, rowIndex) => {
            const cell = {
              r: rowIndex,
              c: columnIndex,
              v: {
                ct: {
                  fa: '@',
                  t: 's'
                },
                v: row.type === 'Delete' ? row.oldValue[column] : row.newValue[column],
                m: row.type === 'Delete' ? row.oldValue[column] : row.newValue[column],
                bg: row.type === 'Update' ? row.oldValue[column] !== row.newValue[column] ? BG_COLOR[row.type] : '#fff' : BG_COLOR[row.type]
              }
            };
            if (row.type === 'Update') {
              cell.v.ps = {
                value: row.oldValue[column]
              };
            }
            cellData.push(cell);
          });
        });
      }

      this.cellData = cellData;
    },
    handlePreStep() {
      this.genDataModal.refresh = true;
      if (this.genDataModal.step === 2) {
        this.genDataModal.step -= 1;
      }
      if (this.genDataModal.step === 3) {
        if (this.genDataModal.status !== 'COMPLETE') {
          Modal.confirm({
            title: this.$t('ti-shi'),
            content: this.$t('dian-ji-shang-yi-bu-hui-guan-bi-thisgendatamodaltitle-que-ding-yao-jin-hang-shang-yi-bu-cao-zuo-ma', [this.genDataModal.title]),
            onOk: () => {
              this.genDataModal.step -= 2;
              this.clearLogInterVal();
              this.handleCloseFaker();
              if (this.genDataModal.resume) {
                this.handleRightClickMenu(this.actionType);
              }
            }
          });
        } else {
          this.genDataModal.step -= 2;
        }
      }
    },
    highlightLog(log) {
      if (log.includes('[ERROR]')) {
        return this.highlightStrict(log, '[ERROR]', 'color: red', 'b');
      }
      if (log.includes('[TRACE]')) {
        return this.highlightStrict(log, '[TRACE]', 'color: gray', 'b');
      }
      if (log.includes('[DEBUG]')) {
        return this.highlightStrict(log, '[DEBUG]', 'color: gray', 'b');
      }
      if (log.includes('[INFO]')) {
        return this.highlightStrict(log, '[INFO]', 'color: green', 'b');
      }
      if (log.includes('[WARN]')) {
        return this.highlightStrict(log, '[WARN]', 'color: yellow', 'b');
      }

      return log;
    },
    async handlePreview() {
      const { node } = this.currentTab;
      const checkedKeys = this.$refs.genDataTree.getCheckedKeys();
      console.log(checkedKeys);
      const tableConfigs = [];
      const tableConfigColumns = {};
      this.genDataModal.tableConfigs.forEach((tableConfig) => {
        const selectedTableConfig = deepClone(tableConfig);
        tableConfigColumns[selectedTableConfig.name] = [];
        selectedTableConfig.columnConfigs = [];
        delete selectedTableConfig.key;
        if (tableConfig.columnConfigs && tableConfig.columnConfigs.length) {
          tableConfig.columnConfigs.forEach((columnConfig) => {
            if (checkedKeys.includes(columnConfig.key)) {
              const selectedColumnConfig = {
                seedConfig: {}
              };
              Object.keys(columnConfig).forEach((key) => {
                if (['name', 'seedType', 'ignoreColsInsert', 'ignoreColsUpdate', 'ignoreColsUpdateWhere', 'ignoreColsDeleteWhere'].includes(key)) {
                  selectedColumnConfig[key] = columnConfig[key];
                } else {
                  if (key !== 'key') {
                    selectedColumnConfig.seedConfig[key] = columnConfig[key];
                  }
                }
              });
              delete selectedColumnConfig.key;
              selectedTableConfig.columnConfigs.push(selectedColumnConfig);
              tableConfigColumns[selectedTableConfig.name].push(selectedColumnConfig.name);
            }
          });
        }
        if (this.genDataModal.resume && checkedKeys.includes(tableConfig.key)) {
          this.genDataModal.preTableConfigs.forEach((preTableConfig) => {
            if (preTableConfig.name === tableConfig.name) {
              selectedTableConfig.columnConfigs = preTableConfig.columnConfigs;
              tableConfigColumns[selectedTableConfig.name] = [];
              preTableConfig.columnConfigs.forEach((preColumn) => {
                tableConfigColumns[selectedTableConfig.name].push(preColumn.name);
              });
            }
          });
        }

        if (selectedTableConfig.columnConfigs.length) {
          tableConfigs.push(selectedTableConfig);
        }
      });
      this.genDataModal.tableConfigColumns = tableConfigColumns;

      try {
        this.genDataModal.loading = true;
        const res = await this.$services.fakerPreview({
          data: {
            levels: this.browseGenLevelsData(node),
            ...this.genDataModal.globalConfigs,
            tableConfigs,
            type: this.actionType === TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER_INCREMENT ? 'INCREMENT' : 'FULL'
          }
        });
        this.genDataModal.loading = false;

        this.genDataModal.selectedTaleConfigs = tableConfigs;

        if (res.success) {
          this.genDataModal.step++;
          if (res.data) {
            if (res.data.data) {
              this.previewData = res.data.data;
              const cellData = [];
              const currentTableConfig = tableConfigs[0];
              this.selectedTableConfig = currentTableConfig;
              const currentTableData = this.previewData[currentTableConfig.name];
              if (currentTableData && currentTableData.length) {
                this.columnList = tableConfigColumns[currentTableConfig.name];
                this.columnList.forEach((column, columnIndex) => {
                  currentTableData.forEach((row, rowIndex) => {
                    const cell = {
                      r: rowIndex,
                      c: columnIndex,
                      v: {
                        ct: {
                          fa: '@',
                          t: 's'
                        },
                        v: row.type === 'Delete' ? row.oldValue[column] : row.newValue[column],
                        m: row.type === 'Delete' ? row.oldValue[column] : row.newValue[column],
                        bg: row.type === 'Update' ? row.oldValue[column] !== row.newValue[column] ? BG_COLOR[row.type] : '#fff' : BG_COLOR[row.type]
                      }
                    };
                    if (row.type === 'Update') {
                      cell.v.ps = {
                        value: row.oldValue[column]
                      };
                    }
                    cellData.push(cell);
                  });
                });

                this.cellData = cellData;
              }
            }
          }
        }
      } catch (e) {
        this.genDataModal.loading = false;
      }
    },
    async handleGenData() {
      try {
        this.genDataModal.loading = true;
        const res = await this.$services.fakerExecute({
          data: {
            levels: this.browseGenLevelsData(this.currentTab.node),
            ...this.genDataModal.globalConfigs,
            tableConfigs: this.genDataModal.selectedTaleConfigs,
            type: this.actionType === TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER_INCREMENT ? 'INCREMENT' : 'FULL'
          }
        });
        this.genDataModal.loading = false;
        if (res.success) {
          this.genDataModal.sessionId = res.data;
          this.genDataModal.step++;
          await this.handleObtainLog();
        }
      } catch (e) {
        this.genDataModal.loading = false;
      }
    },
    handleLogRefreshChange(e) {
      if (e.target.checked) {
        this.handleObtainLog();
      } else {
        this.clearLogInterVal();
      }
    },
    async handleObtainLog(loop = true) {
      this.clearLogInterVal();
      const obtainLog = async () => {
        const res = await this.$services.fakerTailLog({
          data: {
            dataSourceId: this.genDataModal.dataSourceId,
            toolSessionId: this.genDataModal.sessionId,
            startLine: this.genDataModal.startLine
          }
        });

        if (res.success) {
          const {
            status,
            endLine,
            failedDeleteTotal,
            failedInsertTotal,
            failedTotal,
            failedUpdateTotal,
            successDeleteTotal,
            successInsertTotal,
            successTotal,
            successUpdateTotal,
            writeAvgTime,
            logArr,
            logFile,
            logHost
          } = res.data;
          this.genDataModal.startLine = endLine;
          this.genDataModal.failedTotal = failedTotal;
          this.genDataModal.failedInsertTotal = failedInsertTotal;
          this.genDataModal.failedDeleteTotal = failedDeleteTotal;
          this.genDataModal.failedUpdateTotal = failedUpdateTotal;
          this.genDataModal.successTotal = successTotal;
          this.genDataModal.successInsertTotal = successInsertTotal;
          this.genDataModal.successDeleteTotal = successDeleteTotal;
          this.genDataModal.successUpdateTotal = successUpdateTotal;
          this.genDataModal.writeAvgTime = writeAvgTime;
          this.genDataModal.logFile = logFile;
          this.genDataModal.logHost = logHost;
          this.genDataModal.status = status;
          if (logArr && logArr.length) {
            this.genDataModal.logArr = logArr.reverse().concat(this.genDataModal.logArr);
          }
          if (status === FAKER_TASK_STATUS.COMPLETE || status === FAKER_TASK_STATUS.PAUSE) {
            this.clearLogInterVal();
          }
        }
      };
      await obtainLog();
      if (loop || this.genDataModal.refresh) {
        this.genDataModal.timer = window.setInterval(obtainLog, 2000);
      }
    },
    async getFakerDef() {
      const { selectedTable, node } = this.currentTab;
      try {
        this.genDataModal.loading = true;
        const defRes = await this.$services.fakerDef({
          data: {
            levels: this.browseGenLevelsData(node),
            table: selectedTable.title,
            type: this.actionType === TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER_INCREMENT ? 'INCREMENT' : 'FULL'
          }
        });
        this.genDataModal.loading = false;
        if (defRes.success) {
          this.schemaDef = defRes.data;
        }
      } catch (e) {
        this.genDataModal.loading = false;
        console.log(e);
      }
    },
    async getInitTableFaker(node, params = {}, resolve, reject) {
      const {
        checked, selected, expand, preTableConfig
      } = params;
      try {
        this.genDataModal.loading = true;
        const initRes = await this.$services.fakerInit({
          data: {
            levels: this.browseGenLevelsData(this.currentTab.node),
            table: node.title,
            type: this.actionType === TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER_INCREMENT ? 'INCREMENT' : 'FULL'
          }
        });
        await this.$refs.genDataTree.setChecked(node.key, false);
        this.genDataModal.loading = false;
        if (initRes.success) {
          const children = [];
          initRes.data.columns.forEach((column) => {
            const { seedType, name, support } = column;
            let preChecked = true;
            if (preTableConfig) {
              const preColIndex = preTableConfig.columnConfigs.findIndex((col) => col.name === name);
              if (preColIndex === -1) {
                preChecked = false;
              }
            }
            children.push({
              title: name,
              seedType,
              name,
              disabled: !support,
              tableName: node.title,
              isLeaf: true,
              checked: preChecked && support,
              configType: 'columnConfig',
              key: `\`${node.key}\`.\`${name}\``
            });
          });

          node._loaded = true;
          if (resolve) {
            if (selected) {
              await this.$refs.genDataTree.setSelected(node.key, true);
            }
            if (expand) {
              await this.$refs.genDataTree.setExpand(node.key, true);
            }
            if (checked) {
              console.log('checked', node.key);
              await this.$refs.genDataTree.setChecked(node.key, true);
            }
            resolve(children);
          }
        } else {
          if (reject) {
            console.log('reject1');
            reject();
          }
        }
      } catch (e) {
        this.genDataModal.loading = false;
        if (reject) {
          console.log('reject2');
          reject(e);
        }
      }
    },
    async handleGenDataCheck(node) {
      if (!node.resume) {
        await this.$refs.genDataTree.setExpand(node.key, true);
        setTimeout(() => {
          this.$refs.genDataTree.setChecked(node.key, true);
        });
      }
    },
    async handleGenDataSetCheckedKeys(keys, checked = true) {
      console.log('handleGenDataSetCheckedKeys', keys);
      this.genDataModal.checkedKeys = keys;
      await this.$refs.genDataTree.setCheckedKeys(keys, checked);
    },
    async handleGenDataGetCheckedKeys() {
      console.log('handleGenDataGetCheckedKeys');
      const checkedKeys = await this.$refs.genDataTree.getCheckedKeys();
      return checkedKeys;
    },
    handleSetScrollTop(e) {
      this.scrollY = e.target.scrollTop;
    },
    handleEleScroll(top) {
      const eleList = $('.table-list-tree .ctree-tree__scroll-area');
      if (eleList && eleList.length) {
        eleList[0].scrollTo({ top });
      }
    },
    async handleSearch() {
      await this.handleFilter(this.currentTab[this.currentTab.leafType].searchKey);
      if (this.currentTab[this.currentTab.leafType].treeData && this.currentTab[this.currentTab.leafType].treeData.length) {
        this.$refs.tableTree.scrollTo(this.currentTab[this.currentTab.leafType].treeData[0].key);
      }
    },
    handleDblClick(node) {
      let sql = '';
      const {
        quickQuery,
        quickQueryColumn,
        quickQueryMap
      } = this.getQuickQuery(this.currentTab.dsType);
      const { selectedTable, leafGroup } = this.currentTab;
      const needQualifier = node.nodeType === 'TABLE' || node.nodeType === 'VIEW';
      if (quickQuery && leafGroup.findIndex((leaf) => leaf.type === node.nodeType) > -1) {
        sql = quickQuery;
        sql = sql.replace('%%TABLE%%', needQualifier ? this.genQualifierText(this.currentTab.dsType, selectedTable.title) : selectedTable.title);
        sql = sql.replace('%%SCHEMA%%', needQualifier ? this.genQualifierText(this.currentTab.dsType, this.currentTab.node.SCHEMA.name) : this.currentTab.node.SCHEMA.name);
      }

      if (quickQueryColumn && node.nodeType === 'COLUMN') {
        sql = quickQueryColumn;
        sql = sql.replace('%%TABLE%%', needQualifier ? this.genQualifierText(this.currentTab.dsType, selectedTable.title) : selectedTable.title);
        sql = sql.replace('%%COLUMN%%', needQualifier ? this.genQualifierText(this.currentTab.dsType, node.title) : node.title);
      }

      if (quickQueryMap) {
        if (quickQueryMap[node.nodeType]) {
          sql = quickQueryMap[node.nodeType];
          sql = sql.replace('%%TABLE%%', needQualifier ? this.genQualifierText(this.currentTab.dsType, selectedTable.title) : selectedTable.title);
          sql = sql.replace('%%SCHEMA%%', needQualifier ? this.genQualifierText(this.currentTab.dsType, this.currentTab.node.SCHEMA.name) : this.currentTab.node.SCHEMA.name);
          sql = sql.replace('%%COLUMN%%', needQualifier ? this.genQualifierText(this.currentTab.dsType, node.title) : node.title);
          sql = sql.replace('%%PARAM%%', (node.objAttr && node.objAttr.rdb_param) || '');
        }
      }

      this.handleQueryTable(sql);
    },
    handleRefreshTree() {
      this.scrollY = 0;
      this.currentTab.expandedKeys = [];
      this.listLeaf();
    },
    handleFocus() {
      if (this.selectedNode) {
        this.handleScrollTo(this.selectedNode.key);
      }
    },
    handleScrollTo(key) {
      this.$refs.tableTree.scrollTo(key);
    },
    handleGenDataScrollTo(key) {
      this.$refs.genDataTree.scrollTo(key);
    },
    isExpandedKey(node) {
      return this.currentTab.expandedKeys.includes(node.key);
    },
    isGenDataExpandedKey(key) {
      return this.genDataModal.expandedKeys.includes(key);
    },
    handleSetExpandedKeys(node) {
      const { key } = node;
      if (this.isExpandedKey(node)) {
        this.currentTab.expandedKeys = this.currentTab.expandedKeys.filter((k) => k !== key);
      } else {
        this.currentTab.expandedKeys.push(key);
      }
    },
    handleGenDataSetExpandedKeys(node) {
      const { key } = node;
      if (this.isGenDataExpandedKey(key)) {
        this.genDataModal.expandedKeys = this.genDataModal.expandedKeys.filter((k) => k !== key);
      } else {
        this.genDataModal.expandedKeys.push(key);
      }
    },
    handleNodeClick(node) {
      this.handleSetSelected(node);
    },
    async handleExpandLoadNode(node, resolve) {
      await this.getNodeData(node, {}, resolve);
    },
    async handleGenDataExpandLoadNode(node, resolve, reject) {
      if (node && node.title) {
        let preTableConfig = null;
        if (node.resume) {
          preTableConfig = this.genDataModal.preTableConfigs.find((tableConfig) => tableConfig.name === node.title);
        }
        await this.getInitTableFaker(node, {
          checked: false, selected: true, expand: false, preTableConfig
        },
        (children) => {
          this.genDataModal.tableConfigs.forEach((tableConfig) => {
            if (tableConfig.name === node.title) {
              children.forEach((child) => {
                const columnConfig = {
                  name: child.title,
                  seedType: child.seedType,
                  key: child.key
                };
                this.schemaDef.uiPanels.columnConfig.children.forEach((child2) => {
                  if (child2.type === 'Options') {
                    child2.options.forEach((option) => {
                      if (option.value === child.seedType) {
                        option.children.forEach((child3) => {
                          columnConfig[child3.field] = child3.defaultVal;
                        });
                      }
                    });
                  } else {
                    columnConfig[child2.field] = child2.defaultVal;
                    if (child2.type === 'Check') {
                      if (columnConfig[child2.field] === 'true') {
                        columnConfig[child2.field] = true;
                      }
                      if (columnConfig[child2.field] === 'false') {
                        columnConfig[child2.field] = false;
                      }
                    }
                  }
                });
                tableConfig.columnConfigs.push(columnConfig);
              });
            }
          });
          resolve(children);
        }, reject);
      } else {
        resolve();
      }
    },
    handleFilter(searchKey) {
      if (this.$refs.tableTree) {
        this.$refs.tableTree.filter(searchKey);
      }
    },
    handleGenDataFilter(searchKey) {
      if (this.$refs.genDataTree) {
        this.$refs.genDataTree.filter(searchKey);
      }
    },
    async handleSetData(data) {
      if (this.$refs.tableTree) {
        this.top = this.scrollY;
        await this.$refs.tableTree.setData(data);
        await this.handleSearch();
        this.$nextTick(() => {
          this.handleEleScroll(this.top);
        });
      }
    },
    handleGenDataSetData(data) {
      if (this.$refs.genDataTree) {
        this.$refs.genDataTree.setData(data);
      }
    },
    handleSetSelected(node, selected = true) {
      if (this.$refs.tableTree) {
        this.selectedNode = node;
        this.currentTab.selectedTable = {
          title: this.getRootNode(node).title
        };
        this.$refs.tableTree.setSelected(node.key, selected);
      }
    },
    async handleGenDataSetSelected(node, selected = true) {
      if (this.$refs.genDataTree) {
        this.genDataModal.selectedNode = node;
        this.genDataModal.selectedSchema = this.schemaDef.uiPanels[node.configType].children;
        let selectedData = {};
        if (node.configType === 'tableConfig') {
          this.genDataModal.tableConfigs.forEach((tableConfig) => {
            if (tableConfig.name === node.title) {
              selectedData = tableConfig;
            }
          });
        } else {
          this.genDataModal.tableConfigs.forEach((tableConfig) => {
            if (tableConfig.name === node.tableName) {
              tableConfig.columnConfigs.forEach((columnConfig) => {
                if (columnConfig.name === node.title) {
                  selectedData = columnConfig;
                }
              });
            }
          });
        }
        const generateOptionSchema = (item) => {
          if (item.type === 'Options' && selectedData) {
            const optionData = selectedData[item.field];
            item.options.forEach((option) => {
              if (option.value === optionData) {
                if (option.children && option.children.length) {
                  item.children = option.children;
                  option.children.forEach((child) => {
                    if (!(child.field in selectedData)) {
                      selectedData[child.field] = child.defaultVal;
                    }
                    generateOptionSchema(child);
                  });
                } else {
                  item.children = [];
                }
              }
            });
          }
          if (!(item.field in selectedData)) {
            selectedData[item.field] = item.defaultVal;
          }
        };
        this.genDataModal.selectedSchema.forEach((item) => {
          generateOptionSchema(item);
        });
        await this.$refs.genDataTree.setSelected(node.key, selected);
      }
    },
    getRootNode(node) {
      let rootNode = deepClone(node);
      if (rootNode.leafKey !== rootNode.key) {
        if (rootNode._parent) {
          if (rootNode._parent.key === rootNode.leafKey) {
            rootNode = rootNode._parent;
          } else {
            if (rootNode._parent._parent) {
              if (rootNode._parent._parent.key === rootNode.leafKey) {
                rootNode = rootNode._parent._parent;
              }
            }
          }
        }
      }
      return rootNode;
    },
    renderNode(h, node) {
      const {
        title, icon, tips, children, nodeType
      } = node;
      return (
        <div class={['node']} key={title}>
          {icon && <cc-svg-icon name={icon}/>}
          <div style={{ marginLeft: '3px' }}
               domPropsInnerHTML={this.highlight(title, this.currentTab[this.currentTab.leafType].searchKey)}>
          </div>
          {children && children.length > 0 && nodeType.includes('GROUP')
            && <div style="font-weight: bold;color: #bbb;">[{children.length}]</div>}
          {tips && <div style={{ marginLeft: '3px', color: '#ccc' }}>{tips}</div>}
        </div>
      );
    },
    renderGenDataNode(h, node) {
      const {
        title, icon, tips, children, nodeType
      } = node;
      return (
        <div class={['node']} key={title}>
          {icon && <cc-svg-icon name={icon}/>}
          <div style={{ marginLeft: '3px' }}
               domPropsInnerHTML={this.highlight(title, this.currentTab[this.currentTab.leafType].searchKey)}>
          </div>
          {children && children.length > 0 && nodeType.includes('GROUP')
            && <div style="font-weight: bold;color: #bbb;">[{children.length}]</div>}
          {tips && <div style={{ marginLeft: '3px', color: '#ccc' }}>{tips}</div>}
        </div>
      );
    },
    isRedis,
    handleSetExpandAll(expand = true) {
      this.$refs.tableTree.setExpandAll(expand);
    },
    handleSelectTable(table) {
      console.log('click');
      this.handleSetSelected(table);
    },
    handleGenDataSelectTable(table) {
      this.handleGenDataSetSelected(table);
    },
    handleNodeRightClick(table) {
      this.handleSetSelected(table);
    },
    handleChangeTab(leafType) {
      this.currentTab.leafType = leafType;
      this.listLeaf();
    },
    onContextmenu(event) {
      const node = this.$refs.tableTree.getSelectedNode();
      const isNotNode = event.target.classList && event.target.classList.length && event.target.classList[0] === 'ctree-tree__block-area';
      const items = [];
      const menuList = this.getBrowserMenus(this.currentTab.dsType, isNotNode || !node ? this.currentTab.leafType : node.nodeType);
      if (menuList && menuList.length) {
        if (isNotNode || !node) {
          menuList.forEach((menu) => {
            if (menu.menuId !== 'MENU_SEPARATOR' && !menu.needTarget) {
              items.push({
                label: menu.i18n,
                icon: `iconfont icon-svg-${menu.menuId}`,
                onClick: () => this.handleRightClickMenu(menu.menuId)
              });
            }
          });
        } else {
          menuList.forEach((menu, menuIndex) => {
            if (menu.menuId !== 'MENU_SEPARATOR') {
              items.push({
                label: menu.i18n,
                icon: `iconfont icon-svg-${menu.menuId}`,
                divided: menuList[menuIndex + 1] && menuList[menuIndex + 1].menuId === 'MENU_SEPARATOR',
                onClick: () => this.handleRightClickMenu(menu.menuId)
              });
            }
          });
        }
      }
      this.$contextmenu({
        items,
        event,
        customClass: 'custom-class',
        zIndex: 3,
        minWidth: 100
      });
    },
    clearLogInterVal() {
      if (this.genDataModal.timer) {
        window.clearInterval(this.genDataModal.timer);
        this.genDataModal.timer = null;
      }
    },
    async handleCloseFaker() {
      try {
        this.genDataModal.loading = true;
        this.clearLogInterVal();
        const res = await this.$services.fakerClose({
          data: {
            dataSourceId: this.currentTab.node.INSTANCE.id,
            toolSessionId: this.genDataModal.sessionId
          }
        });
        this.genDataModal.loading = false;
        if (res.success) {
          this.handleObtainLog(false);
        }
      } catch (e) {
        this.genDataModal.loading = false;
      }
    },
    async handleCloseModal() {
      this.clearLogInterVal();
      this.genDataModal = {
        loading: false,
        resume: false,
        dataSourceId: '',
        sessionId: '',
        status: '',
        logFile: '',
        logHost: '',
        failedDeleteTotal: 0,
        failedInsertTotal: 0,
        failedTotal: 0,
        failedUpdateTotal: 0,
        successDeleteTotal: 0,
        successInsertTotal: 0,
        successTotal: 0,
        successUpdateTotal: 0,
        writeAvgTime: '0毫秒',
        logArr: [],
        tableConfigColumns: {},
        selectedSchema: {},
        selectedIndex: -1,
        searchKey: '',
        refresh: true,
        timer: null,
        tableConfigs: [],
        selectedTaleConfigs: [],
        globalConfigs: {
          producer: 3,
          writer: 10,
          ignoreErrors: true,
          transaction: false,
          insertRatio: '30',
          updateRatio: '30',
          deleteRatio: '30',
          time: 300
        },
        treeData: [],
        show: false,
        step: 1,
        selectedNode: null,
        expandedKeys: [],
        checkedKeys: []
      };
      this.triggerModal = {
        loading: false,
        show: false,
        permission: false,
        sql: '',
        columnList: [],
        options: {
          triggerName: '',
          triggerTime: 'BEFORE',
          triggerEvent: isMySQL(this.currentTab.dsType) ? '' : [],
          triggerTable: '',
          triggerColumns: []
        }
      };
      if (this.monacoEditor) {
        this.monacoEditor.dispose();
      }
      this.sqlModal = {
        loading: false,
        dsType: '',
        type: 'Request',
        title: this.$t('huo-qu-ddl-yu-ju'),
        show: false,
        sql: [],
        permission: false,
        danger: false,
        options: {
          delimited: false,
          usingExists: false,
          cascade: false,
          restrict: false,
          purge: false,
          truncateUseDelete: false
        }
      };
      this.currentDdlList = [];
      this.menuModal = {
        actionData: {},
        show: false,
        title: '',
        content: '',
        name: '',
        preName: '',
        showNameInput: false,
        sql: '',
        permission: false,
        danger: false,
        collapseKey: '',
        options: {
          delimited: false,
          usingExists: false,
          cascade: false,
          restrict: false,
          purge: false,
          truncateUseDelete: false
        }
      };
      this.doActionLoading = false;
      clearAllPending();
    },
    handleMenuNameChange(e) {
      if (e.target.value !== this.menuModal.name) {
        this.menuModal.sql = '';
        this.menuModal.permission = false;
        this.menuModal.danger = false;
        this.menuModal.actionData = {};
      }
      this.menuModal.name = e.target.value;
    },
    handleMenuOptionChange(key, e) {
      if (this.menuModal.show) {
        if (e.target.checked !== this.menuModal.options[key]) {
          this.menuModal.sql = '';
          this.menuModal.permission = false;
          this.menuModal.danger = false;
          this.menuModal.actionData = {};
        }
        this.menuModal.options[key] = e.target.checked;
      }

      if (this.sqlModal.show) {
        if (e.target.checked !== this.sqlModal.options[key]) {
          this.sqlModal.sql = [];
          this.sqlModal.permission = false;
          this.sqlModal.danger = false;
        }
        this.sqlModal.options[key] = e.target.checked;
        this.handleStruct();
      }
    },
    async handleRightClickMenu(actionType) {
      console.log(actionType);
      this.actionType = actionType;
      const {
        node,
        selectedTable: table,
        leafType
      } = this.currentTab;
      const data = {
        node: this.currentTab.node,
        actionType,
        callback: null,
        other: {
          targetType: this.currentTab.leafType,
          targetName: table ? table.title : '',
          targetNewName: this.menuModal.name,
          options: this.menuModal.options
        }
      };

      let res = {};

      switch (actionType) {
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_COPY_NAME:
          this.copyText(table.title);
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER_INCREMENT:
          this.genDataModal.title = actionType === TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER ? this.$t('sheng-cheng-shu-ju') : this.$t('ya-li-ce-shi');
          // this.genDataModal.treeData = this.currentTab[this.currentTab.leafType].treeData;
          this.$nextTick(async () => {
            await this.getFakerDef();
            const treeData = [];
            const tableConfigs = [];
            let curNode = null;
            if (!this.genDataModal.show) {
              this.genDataModal.show = true;
            }
            if (!this.genDataModal.dataSourceId) {
              this.genDataModal.dataSourceId = this.currentTab.node.INSTANCE.id;
            }
            for await (const leaf of this.currentTab.TABLE.treeData) {
              const item = deepClone(leaf);
              item.configType = 'tableConfig';
              item.children = [];
              const tableConfig = {
                name: item.title,
                key: item.key,
                columnConfigs: []
              };
              this.schemaDef.uiPanels.tableConfig.children.forEach((child) => {
                tableConfig[child.field] = child.defaultVal;
              });

              if (this.selectedNode && leaf.key === this.selectedNode.key && !this.genDataModal.resume) {
                curNode = item;
                try {
                  await this.getInitTableFaker(item, {
                    checked: false,
                    selected: false,
                    expand: false
                  }, (children) => {
                    item.children = children;
                    children.forEach((child) => {
                      const columnConfig = {
                        name: child.title,
                        seedType: child.seedType,
                        key: child.key
                      };
                      this.schemaDef.uiPanels.columnConfig.children.forEach((child2) => {
                        if (child2.type === 'Options') {
                          child2.options.forEach((option) => {
                            if (option.value === child.seedType) {
                              option.children.forEach((child3) => {
                                columnConfig[child3.field] = child3.defaultVal;
                              });
                            }
                          });
                        } else {
                          columnConfig[child2.field] = child2.defaultVal;
                          if (child2.type === 'Check') {
                            if (columnConfig[child2.field] === 'true') {
                              columnConfig[child2.field] = true;
                            }
                            if (columnConfig[child2.field] === 'false') {
                              columnConfig[child2.field] = false;
                            }
                          }
                        }
                      });
                      tableConfig.columnConfigs.push(columnConfig);
                    });
                  });
                } catch (e) {
                  console.log(e);
                }
              }
              tableConfigs.push(tableConfig);
              if (this.genDataModal.resume && this.genDataModal.preTableConfigs.findIndex((preTableConfig) => preTableConfig.name === tableConfig.name) > -1) {
                item.resume = true;
                tableConfig.resume = true;
              }
              treeData.push(item);
            }

            this.genDataModal.treeData = treeData;
            await this.$refs.genDataTree.setData(treeData);

            if (this.genDataModal.resume) {
              this.genDataModal.preTableConfigs.forEach((preTableConfig) => {
                tableConfigs.forEach((tableConfig) => {
                  if (preTableConfig.name === tableConfig.name) {
                    this.$refs.genDataTree.setChecked(tableConfig.key, true);
                  }
                });
              });
            }

            this.genDataModal.tableConfigs = tableConfigs;

            if (curNode && curNode._loaded && !this.genDataModal.resume) {
              await this.$refs.genDataTree.scrollTo(curNode.key);
              await this.$refs.genDataTree.setExpand(curNode.key, true);
              await this.$refs.genDataTree.setChecked(curNode.key, true);
              await this.handleGenDataSetSelected(curNode, true);
            }
          });
          break;
        default:
          break;
      }

      switch (actionType) {
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_VIEW_REFRESH:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_KEY_REFRESH:
          this.listLeaf();
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_REFRESH:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_COLUMN_REFRESH:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_INDEX_REFRESH:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_PARTITION_REFRESH:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_PRIMARY_REFRESH:
          this.rdbTableDetail(this.currentTab.selectedTable.title, {
            expand: false, selected: true, type: actionType, selectedNode: this.selectedNode
          });
          break;
        default:
          break;
      }

      switch (actionType) {
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_DATA:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_VIEW_DATA:
          this.handleAddTab(TAB_TYPE.DATA, node, { table });
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_ALTER:
          this.handleAddTab(TAB_TYPE.STRUCT, node, { table, editorType: ACTION_TYPE.EDIT_TABLE });
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_CREATE:
          this.handleAddTab(TAB_TYPE.STRUCT, node, { editorType: ACTION_TYPE.CREATE_TABLE });
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_VIEW_DROP:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_DROP:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TRIGGER_DROP:
          data.callback = (permission, danger, sql, genActionData) => {
            this.menuModal.actionData = genActionData;
            this.menuModal.permission = permission;
            this.menuModal.danger = danger;
            this.menuModal.show = true;
            this.menuModal.title = this.$t('que-ren-shan-chu');
            this.menuModal.name = table.title;
            this.menuModal.preName = table.title;
            this.menuModal.content = `${this.$t('que-ding-yao-shan-chu')}${this.currentTab.popTip}.\`${table.title}\`？`;
            this.menuModal.sql = sql;
          };
          await this.browseGenAction(data.actionType, this.browseGenLevelsData(data.node), data.callback, data.other);
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_TRUNCATE:
          data.callback = (permission, danger, sql, genActionData) => {
            this.menuModal.actionData = genActionData;
            this.menuModal.permission = permission;
            this.menuModal.danger = danger;
            this.menuModal.show = true;
            this.menuModal.name = table.title;
            this.menuModal.preName = table.title;
            this.menuModal.title = this.$t('qing-kong');
            this.menuModal.content = `${this.$t('que-ding-yao-qing-kong')}${this.currentTab.popTip}.\`${table.title}\`？`;
            this.menuModal.sql = sql;
          };
          await this.browseGenAction(data.actionType, this.browseGenLevelsData(data.node), data.callback, data.other);
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_RENAME:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_VIEW_RENAME:
          if (!this.menuModal.name) {
            this.menuModal.show = true;
            this.menuModal.title = this.$t('zhong-ming-ming');
            this.menuModal.showNameInput = true;
            this.menuModal.name = table.title;
            this.menuModal.preName = table.title;
            this.menuModal.content = this.$t('zhong-ming-ming-thiscurrenttabpoptiptabletitle-wei', [this.currentTab.popTip, table.title]);
          } else {
            data.callback = (permission, danger, sql, genActionData) => {
              this.menuModal.permission = permission;
              this.menuModal.danger = danger;
              this.menuModal.sql = sql;
              this.menuModal.actionData = genActionData;
            };
            await this.browseGenAction(data.actionType, this.browseGenLevelsData(data.node), data.callback, data.other);
          }
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_VIEW_REQUEST:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_REQUEST:
          res = await this.$services.requestScript({
            data: {
              levels: this.browseGenLevelsData(node),
              targetType: leafType,
              targetName: table.title
            }
          });

          if (res.success) {
            this.sqlModal.show = true;
            this.sqlModal.title = this.$t('huo-qu-ddl-yu-ju-tabletitle', [table.title]);
            this.sqlModal.sql = [res.data.sql];
          }
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_GENERATE:
          res = await this.$services.generateScript({
            data: {
              levels: this.browseGenLevelsData(node),
              targetType: leafType,
              targetName: table.title
            }
          });

          if (res.success) {
            this.sqlModal.show = true;
            this.sqlModal.title = this.$t('sheng-cheng-ddl-yu-ju-tabletitle', [table.title]);
            this.sqlModal.sql = res.data.sql;
          }
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_GET_DDL:
          this.currentTargetDsList = this.targetDsList(this.currentTab.dsType) || [];
          if (this.currentTargetDsList && this.currentTargetDsList.length) {
            this.sqlModal.dsType = this.currentTargetDsList[0];
            this.currentDdlList = this.ddlList(this.currentTab.dsType) || [];
            if (this.currentDdlList.length) {
              this.sqlModal.type = this.currentDdlList[0];
              this.sqlModal.show = true;
              await this.handleStruct();
            }
          }
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TRIGGER_CREATE:
          this.triggerModal.title = this.$t('xin-jian-chu-fa-qi');
          if (!this.triggerModal.options.triggerName) {
            this.triggerModal.show = true;
            this.triggerModal.options.triggerTable = table.title;
            this.triggerModal.options.triggerEvent = isMySQL(this.currentTab.dsType) ? '' : [];
            const columnRes = await this.$services.rdbTableDetail({
              data: {
                levels: this.browseGenLevelsData(this.currentTab.node),
                targetName: table.title,
                targetType: 'TABLE'
              }
            });

            if (columnRes.success) {
              if (columnRes.data && columnRes.data.group) {
                columnRes.data.group.forEach((item) => {
                  if (item.type === 'RDB_COLUMN_GROUP') {
                    this.triggerModal.columnList = item.items;
                  }
                });
              }
            }
            this.$nextTick(() => {
              this.monacoEditor = monaco.editor.create(this.$refs.triggerMonacoEditor, this.defaultOpts);
            });
          } else {
            data.callback = (permission, danger, sql, genActionData) => {
              this.triggerModal.permission = permission;
              this.triggerModal.danger = danger;
              this.triggerModal.sql = sql;
              this.triggerModal.actionData = genActionData;
            };
            data.other.options = {
              ...this.triggerModal.options,
              triggerEvent: isMySQL(this.currentTab.dsType) ? this.triggerModal.options.triggerEvent ? this.triggerModal.options.triggerEvent.split(',') : [] : this.triggerModal.options.triggerEvent,
              triggerBody: this.monacoEditor.getValue()
            };
            data.other.targetType = 'TRIGGER';
            await this.browseGenAction(data.actionType, this.browseGenLevelsData(data.node), data.callback, data.other);
          }
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TRIGGER_ALTER:
          this.triggerModal.title = this.$t('xiu-gai-chu-fa-qi');
          if (!this.triggerModal.options.triggerName) {
            res = await this.$services.loadObject({
              data: {
                levels: this.browseGenLevelsData(node),
                targetType: leafType,
                targetName: table.title
              }
            });

            if (res.success) {
              this.triggerModal.show = true;
              this.triggerModal.options = {
                ...res.data
              };
              const columnRes = await this.$services.rdbTableDetail({
                data: {
                  levels: this.browseGenLevelsData(this.currentTab.node),
                  targetName: res.data.triggerTable,
                  targetType: 'TABLE'
                }
              });

              if (columnRes.success) {
                if (columnRes.data && columnRes.data.group) {
                  columnRes.data.group.forEach((item) => {
                    if (item.type === 'RDB_COLUMN_GROUP') {
                      this.triggerModal.columnList = item.items;
                    }
                  });
                }
              }
              this.$nextTick(() => {
                this.monacoEditor = monaco.editor.create(this.$refs.triggerMonacoEditor, this.defaultOpts);
                this.monacoEditor.setValue(res.data.triggerBody);
              });
            }
          } else {
            data.callback = (permission, danger, sql, genActionData) => {
              this.triggerModal.permission = permission;
              this.triggerModal.danger = danger;
              this.triggerModal.sql = sql;
              this.triggerModal.actionData = genActionData;
            };
            data.other.options = {
              ...this.triggerModal.options,
              triggerEvent: isMySQL(this.currentTab.dsType) ? this.triggerModal.options.triggerEvent ? this.triggerModal.options.triggerEvent.split(',') : [] : this.triggerModal.options.triggerEvent,
              triggerBody: this.monacoEditor.getValue()
            };
            data.other.targetType = 'TRIGGER';
            await this.browseGenAction(data.actionType, this.browseGenLevelsData(data.node), data.callback, data.other);
          }
          break;
        default:
          break;
      }
    },
    handlePreCreateTrigger() {
      this.triggerModal.sql = '';
    },
    async handleDoAction() {
      const callback = () => {
        switch (this.actionType) {
          case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_DROP:
          case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_RENAME:
          case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TRIGGER_DROP:
            this.listLeaf();
            break;
          case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TRIGGER_CREATE:
          case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TRIGGER_ALTER:
            this.handleChangeTab('TRIGGER');
            break;
          default:
            break;
        }
        this.handleCloseModal();
        this.doActionLoading = false;
        this.$message.success(this.$t('cao-zuo-cheng-gong'));
      };

      let actionData = this.menuModal.actionData;
      if ([TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TRIGGER_CREATE, TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TRIGGER_ALTER].includes(this.actionType)) {
        actionData = this.triggerModal.actionData;
      }
      const callbackFail = () => {
        this.doActionLoading = false;
      };
      this.doActionLoading = true;
      await this.browseDoAction(actionData, callback, callbackFail);
    }
  }
};
</script>

<style scoped lang="less">
.table-list-resize {
  height: 100%;
  width: 6px;
  background: rgba(0, 0, 0, 0);
  //background: red;
  position: absolute;
  right: -3px;
  cursor: col-resize;
  z-index: 9;
}

/deep/ .table-leaf-type-select .ant-select-selection {
  border-top: none;
  border-left: none;
  border-right: none;
}

/deep/ .ctree-tree-node__title {
  padding-left: 0;
  margin-left: 0;
}

/deep/ .node {
  display: flex;
  align-items: center;
}

/deep/ .no-indent {
  .ctree-tree-node__square {
    display: none;
  }

  .node {
    padding-left: 5px;
  }
}

/deep/ .ant-collapse-header {
  padding: 5px 10px 5px 34px !important;
}

/deep/ .ant-collapse-item {
  border: none;
}

/deep/ .ant-collapse-content-box {
  padding: 8px 10px;
}

/deep/ .highlight {
  background: orange !important;
  border-radius: 2px;
}

.generate-modal {
  height: 500px;
  display: flex;
  flex-direction: column;

  .advanced {
    margin-bottom: 5px;

    .title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .options {
      display: flex;

      .option {
        display: flex;
        align-items: center;
        margin-right: 10px;
      }
    }
  }

  .content {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .table-setting {
      display: flex;
      width: 100%;
      flex: 1;
      min-height: 0;
      border: 1px solid #ccc;

      .left {
        width: 300px;
        //min-height: 400px;
        //max-height: 400px;
        border-right: 1px solid #ccc;
        display: flex;
        flex-direction: column;

        .search {
          /deep/ .ant-input {
            border: none;
            border-bottom: 1px solid #ccc;
          }
        }

        .datasource-tree {
          flex: 1;
          min-height: 0;
        }
      }

      .right {
        flex: 1;
        overflow: auto;
        padding: 10px;
        min-height: 200px;
        max-height: 400px;
      }
    }
  }

  .preview {
    display: flex;
    height: 100%;

    .left {
      width: 300px;
      border: 1px solid #ccc;
      border-right: none;
      overflow: scroll;
    }

    .right {
      flex: 1;
      min-width: 0;
      border: 1px solid #ccc;
    }
  }

  .result {
    display: flex;
    flex-direction: column;
    height: 100%;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;

      .info {
        flex: 1;
        .line {
          display: flex;
          div {
            flex: 1;
          }
        }
      }
    }

    .logs {
      flex: 1;
      min-height: 0;
      border: 1px solid #ccc;
      padding: 3px;
      overflow: scroll;

      .scroller {
        height: 100%;

        .log {
          display: flex;
          width: 100%;
          height: 20px;
        }
      }
    }
  }
}

.trigger-modal {
  /deep/ .ant-form-item {
    margin-bottom: 0 !important;
  }

  .trigger-monaco-editor {
    height: 250px;
    width: 100%;
    border: 1px solid #ccc;
  }
}
</style>
