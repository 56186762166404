import { render, staticRenderFns } from "./AlertSetting.vue?vue&type=template&id=64f3475c"
import script from "./AlertSetting.vue?vue&type=script&lang=js"
export * from "./AlertSetting.vue?vue&type=script&lang=js"
import style0 from "./AlertSetting.vue?vue&type=style&index=0&id=64f3475c&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports