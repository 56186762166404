<template>
  <div class="clean-data-body">
    <FilterHeader :handleTableFilter="handleTableFilter" :filterTable="filterTable"
                  :handleBatchSetRedisKey="handleBatchSetRedisKey"
                  :handleShowVirtualColumn="handleShowVirtualColumn"
                  :handleSetTargetKey="handleSetTargetKey" :taskInfo="taskInfo"
                  :handleShowFilterAll="handleShowFilterAll" :handleSetBucketKey="handleSetBucketKey"
                  :handle-show-target-filter-all="handleShowTargetFilterAll"
                  :sourceType="taskInfo.sourceType"
                  :sinkType="taskInfo.sinkType" :filterList="filterList"
                  :handleShowOperateColumns="handleShowOperateColumns"
                  :handleDeleteFilter="handleDeleteFilter" :handleClearFilter="handleClearFilter"
                  :handleGetColumnByTable="handleGetColumnByTable" :selectedIndex="selectedIndex"
                  :getSelectedInfo="getSelectedInfo" :getWhereCondition="getWhereCondition"
                  :handleFilter="handleFilter" :handleSetPartition="handleSetPartition"
                  theType="create" :handle-show-partition-expr-all="handleShowPartitionExprAll"
                  :handlePageChange="handlePageChange"  :handleShowBatchVirtualColumn="handleShowBatchVirtualColumn"
                  :handleShowPartitionAll="handleShowPartitionAll" :supportVirtualColumn="supportVirtualColumn"
                  :showTableList="showTableList" :selectedColumns="selectedColumns"
                  :theMappingRule="taskInfo.columnMappingRule" :supportSpecifiedKeys="supportSpecifiedKeys"
                  :handleShowMappingRule="handleShowMappingRule"
    ></FilterHeader>
    <div class="table-filter-nav-container" style="position: relative;width:400px;float: left;">
      <div class="table-filter-nav table-filter-nav-column">
        <TableMapping
          v-if="DataSourceGroup.mq.indexOf(taskInfo.sinkType)===-1&&DataSourceGroup.mq.indexOf(taskInfo.sourceType)===-1&&!DataSourceGroup.redis.includes(taskInfo.sinkType) && !loading"
          :handleGetColumnByTable="handleGetColumnByTable" :selectedIndex="selectedIndex"
          theType="create"
          :showTableList="showTableList" :getSelectedInfo="getSelectedInfo"
          :sourceType="taskInfo.sourceType" :supportVirtualColumn="supportVirtualColumn"
          :sinkType="taskInfo.sinkType" :handleShowVirtualColumn="handleShowVirtualColumn"
          :getWhereCondition="getWhereCondition" :handleFilter="handleFilter" :handleTargetFilter="handleTargetFilter"
          :handleChangePk="handleChangePk"
          :handle-set-id="handleSetId" :handleBucketKeys="handleBucketKeys"
          :handleSetDrdsPartition="handleSetDrdsPartition"
          :handleConfirmChangePk="handleConfirmChangePk"
          :selectedColumns="selectedColumns" :uniqList="uniqList"
          :set-pk-and-id-flags="setPkAndIdFlags" :supportSpecifiedKeys="supportSpecifiedKeys"
          :task-info="taskInfo" :handle-set-sr-partition="handleSetSrPartition"
        ></TableMapping>
        <TableCacheMapping
          v-if="DataSourceGroup.redis.includes(taskInfo.sinkType)"
          :handleGetColumnByTable="handleGetColumnByTable" :selectedIndex="selectedIndex"
          theType="create"
          :showTableList="showTableList" :getSelectedInfo="getSelectedInfo"
          :sourceType="taskInfo.sourceType" :supportVirtualColumn="supportVirtualColumn"
          :sinkType="taskInfo.sinkType" :handleShowVirtualColumn="handleShowVirtualColumn"
          :handleSetRedisKey="handleSetRedisKey"
          :getWhereCondition="getWhereCondition" :handleFilter="handleFilter"
          :handleChangePk="handleChangePk" :supportSpecifiedKeys="supportSpecifiedKeys"
          :handleSetDrdsPartition="handleSetDrdsPartition"
          :handleConfirmChangePk="handleConfirmChangePk"
          :selectedColumns="selectedColumns" :uniqList="uniqList"></TableCacheMapping>
        <MqTableMapping
          v-if="DataSourceGroup.mq.indexOf(taskInfo.sourceType)>-1||DataSourceGroup.mq.indexOf(taskInfo.sinkType)>-1"
          :handleSetPartition="handleSetPartition" :handleChangePk="handleChangePk" theType="create"
          :type="DataSourceGroup.mq.includes(taskInfo.sourceType)?'source':'sink'"
          :handleShowVirtualColumn="handleShowVirtualColumn" :sourceType="taskInfo.sourceType"
          :sinkType="taskInfo.sinkType" :supportVirtualColumn="supportVirtualColumn"
          :handleGetColumnByTable="handleGetColumnByTable" :selectedIndex="selectedIndex"
          :showTableList="showTableList" :getSelectedInfo="getSelectedInfo"
          :getWhereCondition="getWhereCondition" :handleFilter="handleFilter" :supportSpecifiedKeys="supportSpecifiedKeys"
          :selectedColumns="selectedColumns" :task-info="taskInfo" :handle-set-id="handleSetId"></MqTableMapping>
      </div>
      <Page size="small" :page-size="pageSize" class="clean-data-page" :total="total" show-total
            @on-change="handlePageChange"/>
    </div>
    <div class="column-filter-container">
      <div class="mq-schema-left-container" v-if="DataSourceGroup.mq.includes(taskInfo.sourceType)">
        <div class="mq-schema-content">
          <p class="mq-header">{{ $t('yuan-xiao-xi-jie-gou') }} <span class="tip">{{ $t('gai-topic-xu-fu-he-yi-xia-jie-gou') }}</span>
            <Icon class="copy-icon" type="ios-photos-outline"
                  @click="handleCopyMqSchema(mqSchema)"/>
          </p>
          <div class="mq-schema-format" :style="`height:${tableHeight-40}px`">
            <pre>{{ mqSchema }}</pre>
          </div>
        </div>
      </div>
      <TableContainer
        :style="`marginRight:${(DataSourceGroup.mq.includes(taskInfo.sinkType)||DataSourceGroup.redis.includes(taskInfo.sinkType))?'480px':''}`"
        :tableHeight="tableHeight" :rowClassName="rowClassName" :handleSelected="handleSelected"
        :taskInfo="taskInfo" :mqSchemaKey="mqSchemaKey"
        :handleSelectCancel="handleSelectCancel" :handleAllSelected="handleAllSelected"
        :handleCancelSelectAll="handleCancelSelectAll"
        :handleSinkColumnChange="handleSinkColumnChange" :hasTheSameColumn="hasTheSameColumn"
        :handleChangeIndexType="handleChangeIndexType" :handleDeleteColumn="handleDeleteColumn"
        :handleChangeAnalyzer="handleChangeAnalyzer" :sourceType="taskInfo.sourceType"
        :sinkType="taskInfo.sinkType" :table="selectedTable" :esTypeList="esTypeList"
        :selectedColumns="selectedColumns" :mqSchema="mqSchema" :index="index"
        :targetCaseSensitive="taskInfo.targetCaseSensitive" :loading="loading" :get-target-value="getTargetValue"></TableContainer>
      <div class="mq-schema-container"
           v-if="DataSourceGroup.mq.includes(taskInfo.sinkType)||DataSourceGroup.redis.includes(taskInfo.sinkType)">
        <div class="mq-schema-content">
          <p class="mq-header">
            {{ DataSourceGroup.mq.includes(taskInfo.sinkType) ? $t('mu-biao-xiao-xi-jie-gou') : $t('mu-biao-cache-jie-gou') }}
            <span><Icon class="copy-icon" type="ios-photos-outline"
                        @click="handleCopyMqSchema(mqSchema)"/></span>
          </p>
          <div class="mq-schema-format" :style="`height:${tableHeight-40}px`">
            <pre>{{ mqSchema }}</pre>
          </div>
        </div>
      </div>
    </div>
    <CleanDataModal :showFilter="showFilter" :whereConditionFilter="whereConditionFilter" :showTargetFilter="showTargetFilter"
                    :selectedTable="selectedTable" :clearWhereCondition="clearWhereCondition" :clear-target-where-condition="clearTargetWhereCondition"
                    :handleAddWhereCondition="handleAddWhereCondition" :handleAddTargetWhereCondition="handleAddTargetWhereCondition"
                    :handleCancel="handleCancel" :showBatchVirtualColumn="showBatchVirtualColumn" :handleBatchSetVirtualColumn="handleBatchSetVirtualColumn"
                    :showFilterAll="showFilterAll" :handleColumnChange="handleColumnChange"
                    :handleFindTable="handleFindTable" :handleVirtualColumn="handleVirtualColumn"
                    :checkAll="checkAll" :handleCheckAll="handleCheckAll"
                    :checkAllGroup="checkAllGroup" :updateWhereCondition="updateWhereCondition" :update-target-where-condition="updateTargetWhereCondition"
                    :checkAllGroupChange="checkAllGroupChange" :virtual-col-gen-rule="virtualColGenRule"
                    :showHasColumnTableList="showHasColumnTableList"
                    :hasColumnTableList="hasColumnTableList" :hasTheSameColumn="hasTheSameColumn"
                    :showHasColumnTableListSize="showHasColumnTableListSize"
                    :showVirtualColumn="showVirtualColumn" :taskInfo="taskInfo"
                    :handleShowHasColumnPageChange="handleShowHasColumnPageChange"
                    :typeMeta="typeMeta" :whereCondition="whereCondition" :target-where-condition="targetWhereCondition"
                    :handleAddAllWhereCondition="handleAddAllWhereCondition"
                    :showPartition="showPartition" :partitionData="partitionData"
                    :HivePartitionFuncs="HivePartitionFuncs"
                    :HdfsFileFormats="HdfsFileFormats" :showOperateColumns="showOperateColumns"
                    :virtualColumnData="getShowVirtualColumnData"
                    :showPartitionAll="showPartitionAll" :showPartitionExprAll="showPartitionExprAll"
                    :partitionCheckAll="partitionCheckAll"
                    :partitionCheckAllGroup="partitionCheckAllGroup"
                    :partitionCheckAllGroupChange="partitionCheckAllGroupChange"
                    :handleOperateColumns="handleOperateColumns"
                    :handleAddPartitionAll="handleAddPartitionAll"
                    :showMqPartition="showMqPartition"
                    :mqPartition="mqPartition" :handleAddMqPartition="handleAddMqPartition"
                    :showSetAllPartitions="showSetAllPartitions" :selectedColumns="selectedColumns"
                    :tableList="tableList" :showDrdsPartition="showDrdsPartition"
                    :drdsPartition="drdsPartition" :showSrPartition="showSrPartition"
                    :handleAddDrdsPartitions="handleAddDrdsPartitions"
                    :handle-add-sr-partitions="handleAddSrPartitions"
                    :handle-change-partition-expr="handleChangePartitionExpr"
                    :drdsPartitionSetting="drdsPartitionSetting"
                    :handleConfirmSetRedisKey="handleConfirmSetRedisKey"
                    :showRedisKey="showRedisKey" :sr-data="srData"
                    :showBatchSetRedisKey="showBatchSetRedisKey"
                    :handleConfirmBatchSetRedisKey="handleConfirmBatchSetRedisKey"
                    :handleTargetFilter="handleTargetFilter"
                    :handleFindCropTable="handleFindCropTable"
                    :handleConfirmBatchSetPartitionExpr="handleConfirmBatchSetPartitionExpr"
    ></CleanDataModal>
    <VirtualColumn ref="virtual-column-container" :selectedTable="selectedTable" :handleDeleteVirtual="handleDeleteVirtual" :virtualColGenRule="virtualColGenRule"
                   :virtualColumnData="getShowVirtualColumnData" :taskInfo="taskInfo" :showMappingRule="showMappingRule"></VirtualColumn>
    <Modal
      v-model="showChangePk"
      width="500px"
      footer-hide
      :closable="false"
      :title="$t('xiu-gai-mu-biao-zhu-jian')">
      <div>
        <Select filterable :placeholder="$t('qing-xuan-ze-suo-yin-lie-fou-ze-hui-ying-xiang-xing-neng')" v-model="selectedTargetKeys" multiple
                :key="uqIndex">
          <Option v-for="uniq in getUniqData" :key="uniq.constraintName"
                  :value="uniq.cols.join(',')">
            <span>{{ $t('suo-yin-ming-cheng-uniqconstraintname', [uniq.constraintName]) }}</span>
            <span>{{ $t('lie-ming-uniqcolsjoin', [uniq.cols.join(',')]) }}</span>
          </Option>
          <Option v-for="column in currentColumnList" :key="column.columnName||column.sourceColumn || column.sinkColumn"
                  :value="column.columnName||column.sourceColumn || column.sinkColumn">
            {{ column.columnName || column.sourceColumn || column.sinkColumn}}
          </Option>
          <Option v-for="virtualColumn in getVirtualColumnData" :key="virtualColumn.customName || virtualColumn.targetVirtualName"
                  :value="virtualColumn.customName || virtualColumn.targetVirtualName">
            {{ virtualColumn.customName || virtualColumn.targetVirtualName }}
          </Option>
        </Select>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmPk">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showSetBucketKey"
      footer-hide
      :title="$t('xiu-gai-pai-xu-jian-he-fen-tong-jian')"
      :closable="false">
      <div>
        <Form label-position="right" :label-width="80">
          <FormItem :label="$t('pai-xu-jian')">
            <Select filterable v-model="selectedTargetKeys" multiple
                    :key="uqIndex" @on-change="handleChangeTargetKey">
              <Option v-for="uniq in getUniqData" :key="uniq.constraintName"
                      :value="uniq.cols.join(',')">
                <span>{{ $t('suo-yin-ming-cheng-uniqconstraintname', [uniq.constraintName]) }}</span>
                <span>{{ $t('lie-ming-uniqcolsjoin', [uniq.cols.join(',')]) }}</span>
              </Option>
              <Option v-for="column in currentColumnList" :key="column.columnName||column.sourceColumn"
                      :value="column.columnName||column.sourceColumn">
                {{ column.columnName || column.sourceColumn }}
              </Option>
            </Select>
          </FormItem>
          <FormItem :label="$t('fen-tong-jian')">
            <Select filterable v-model="specifiedDisKeys" multiple
                    :key="uqIndex">
              <Option v-for="key in selectedTargetKeysList" :key="key"
                      :value="key">
                {{key}}
              </Option>
            </Select>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmBuckets">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showSetId"
      width="500px"
      footer-hide
      :closable="false"
      :title="$t('she-zhi-id')">
      <template #header>
        {{ $t('she-zhi-id') }}
        <Tooltip transfer>
          <Icon type="ios-help-circle-outline" size="14"/>
          <div slot="content">
            <div>
              {{ $t('elasticsearch-zhong-id-yong-yu-wei-yi-shi-bie-yi-ge-wen-dang-cloudcanal-yun-xu-yong-hu-zhi-ding-yuan-duan-de-lie-zuo-wei-id-qu-zhi-de-lai-yuan-ru-guo-yong-hu-bu-zhi-ding-ze-mo-ren-shi-yong-yuan-duan-zhu-jian-lie-de-qu-zhi-zuo-wei-xie-ru-dui-duan-id-de-qu-zhi-dang-xuan-ze-duo-ge-lie-zuo-wei-id-shi-lian-jie-fu-mo-ren-wei-ke-zai-ge-ren-zhong-xin-xiu-gai-can-shu-pkseparator-zai-shi-yong-cloudcanal-zi-ding-yi-dai-ma-gou-jian-kuan-biao-jin-hang-duo-biao-join-de-chang-jing-xia-jian-yi-jiang-id-she-zhi-wei-yuan-duan-duo-biao-de-guan-lian-lie') }}
            </div>
            <a
              href="https://www.elastic.co/guide/en/elasticsearch/reference/current/mapping-id-field.html"
              target="_blank">{{ $t('can-kao-guan-fang-zi-liao-id-field-shuo-ming') }} </a>
          </div>
        </Tooltip>
      </template>
      <div>
        <Select filterable :placeholder="$t('she-zhi-id')" v-model="selectedIds" multiple
                :key="uqIndex">
          <Option v-for="column in currentColumnList" :key="column.columnName||column.sourceColumn"
                  :value="column.columnName||column.sourceColumn">
            {{ column.columnName || column.sourceColumn }}
          </Option>
        </Select>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmIds">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal v-model="showFilterAll"
           width="740px"
           footer-hide
           :closable="false"
           :title="$t('pi-liang-tian-jia-shu-ju-guo-lv-tiao-jian')">
      <div style="word-break: break-all" v-if="showFilterAll">
        <div style="margin-bottom: 16px">
          <span>{{ $t('guo-lv-mo-shi') }}</span>
          <Select v-model="dataFilterType" style="width: 180px">
            <Option value="SQL_WHERE_ADV">{{ $t('gao-ji-mo-shi-tui-jian') }}</Option>
            <Option value="SQL_WHERE">{{ $t('pu-tong-mo-shi') }}</Option>
          </Select>
        </div>
        select columns where
        <div style="margin-top: 10px">
          <Input :placeholder="dataFilterType === 'SQL_WHERE'?$t('tiao-jian-zhong-zi-duan-qing-yan-ge-an-ming-cheng-pi-pei-ru-da-xiao-xie-pu-tong'):$t('tiao-jian-zhong-zi-duan-qing-yan-ge-an-ming-cheng-pi-pei-ru-da-xiao-xie')" v-model="currentData" type="textarea" :rows="4"/>
        </div>
        <div style="margin-top: 10px">
          <pd-button type="primary"
                     @click="handleFindTable(currentData)">{{ $t('cha-zhao-you-yi-shang-lie-de-biao') }}
          </pd-button>
        </div>
        <BatchSet :checkAll="checkAll" :handleCheckAll="handleCheckAll" :checkAllGroup="checkAllGroup"
                  :checkAllGroupChange="checkAllGroupChange"
                  :hasColumnTableList="hasColumnTableList" :showHasColumnTableListSize="showHasColumnTableListSize"></BatchSet>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleAddAllWhereCondition(currentData)">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal v-model="showTargetFilterAll"
           width="740px"
           footer-hide
           :closable="false"
           :title="$t('pi-liang-tian-jia-dui-duan-geng-xin-tiao-jian')">
      <div style="word-break: break-all" v-if="showTargetFilterAll">
        update tables set ... where
        <div style="margin-top: 10px">
          <Input :placeholder="$t('tiao-jian-zhong-zi-duan-qing-yan-ge-an-ming-cheng-pi-pei-ru-da-xiao-xie-pu-tong')" v-model="currentData" type="textarea" :rows="4"/>
        </div>
        <div style="margin-top: 10px">
          <pd-button type="primary"
                     @click="handleFindTargetTable(currentData)">{{ $t('cha-zhao-you-yi-shang-lie-de-biao') }}
          </pd-button>
        </div>
        <!--        <p style="margin-top: 20px;margin-bottom: 10px;font-size: 14px;">{{ $t('ying-yong-dao-yi-xia-biao') }}</p>-->
        <BatchSet :checkAll="checkAll" :handleCheckAll="handleCheckAll" :checkAllGroup="checkAllGroup"
                  :checkAllGroupChange="checkAllGroupChange"
                  :hasColumnTableList="hasColumnTableList" :showHasColumnTableListSize="showHasColumnTableListSize"></BatchSet>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleAddAllTargetWhereCondition(currentData)">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal v-model="showOperateColumns"
           width="740px"
           footer-hide
           :closable="false"
           :title="$t('pi-liang-cai-jian-lie')">
      <div style="word-break: break-all" v-if="showOperateColumns">
        <div style="margin-top: 10px">
          <Select v-model="operateType" style="width: 120px;margin-right: 8px">
            <Option value="cancel">{{ $t('qu-xiao-gou-xuan') }}</Option>
            <Option value="select">{{ $t('gou-xuan') }}</Option>
            <!--            <Option value="selectAll">全部勾选</Option>-->
            <!--            <Option value="cancelAll">全部取消勾选</Option>-->
          </Select>
          <Input :placeholder="$t('zi-duan-ming-cheng')" @on-change="handleColumnChange" v-model="searchColumnKey"
                 style="width: 180px"/>
          <pd-button style="margin-left: 6px" type="primary"
                     @click="handleFindCropTable(searchColumnKey)">{{ $t('cha-zhao-you-gai-lie-de-biao') }}
          </pd-button>
        </div>
<!--        <p style="margin-top: 20px;margin-bottom: 10px;font-size: 14px;">{{ $t('ying-yong-dao-yi-xia-biao') }}</p>-->
        <BatchSet :checkAll="checkAll" :handleCheckAll="handleCheckAll" :checkAllGroup="checkAllGroup"
                  :checkAllGroupChange="checkAllGroupChange"
                  :hasColumnTableList="hasColumnTableList" :showHasColumnTableListSize="showHasColumnTableListSize"></BatchSet>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleOperateColumns(operateType, searchColumnKey)">{{ $t('que-ding') }}
          </pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showPartitionExprAll"
      width="740px"
      footer-hide
      :closable="false"
      :title="$t('pi-liang-she-zhi-shu-ju-fen-qu')">
      <div style="word-break: break-all">
        <p style="margin-bottom: 10px">{{ $t('qing-tian-ru-fen-qu-biao-da-shi') }}</p>
        <Input v-model="srData.partitionExpr" type="textarea" :rows="10"/>
        <div style="margin-top: 30px">
          <pd-button style="margin-left: 6px" type="primary"
                     @click="handleFindExrCropTable(searchColumnKey)">{{ $t('cha-zhao-you-gai-lie-de-biao') }}
          </pd-button>
        </div>
        <BatchSet :checkAll="checkAll" :handleCheckAll="handleCheckAll" :checkAllGroup="checkAllGroup"
                  :checkAllGroupChange="checkAllGroupChange"
                  :hasColumnTableList="hasColumnTableList" :showHasColumnTableListSize="showHasColumnTableListSize"></BatchSet>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmBatchSetPartitionExpr(searchColumnKey)">{{ $t('que-ding') }}
          </pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showBatchSetRedisKey"
      width="740px"
      footer-hide
      :closable="false"
      :title="$t('pi-liang-pei-zhi-redis-key')">
      <div style="word-break: break-all">
        <div style="margin-top: 10px">
          <Input :placeholder="$t('zi-duan-ming-cheng')" @on-change="handleColumnChange" v-model="searchColumnKey"
                 style="width: 180px"/>
          <pd-button style="margin-left: 6px" type="primary"
                     @click="handleFindCropTable(searchColumnKey)">{{ $t('cha-zhao-you-gai-lie-de-biao') }}
          </pd-button>
        </div>
        <BatchSet :checkAll="checkAll" :handleCheckAll="handleCheckAll" :checkAllGroup="checkAllGroup"
                  :checkAllGroupChange="checkAllGroupChange"
                  :hasColumnTableList="hasColumnTableList" :showHasColumnTableListSize="showHasColumnTableListSize"></BatchSet>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmBatchSetRedisKey(searchColumnKey)">{{ $t('que-ding') }}
          </pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal v-model="showChangePkBatch"
           width="500px"
           footer-hide
           :closable="false"
           :title="$t('pi-liang-xiu-gai-mu-biao-zhu-jian')">
      <div v-if="showChangePkBatch">
        <Select style="width: 340px" filterable :placeholder="$t('qing-xuan-ze-suo-yin-lie-fou-ze-hui-ying-xiang-xing-neng')"
                @on-change="handleSelectUniq" multiple :key="uqIndex" v-model="currentCols">
          <Option v-for="uniq in getAllUniqData" :key="uniq.column" :value="uniq.column">
            <span>{{ uniq.column }}</span>
            <span v-if="uniq.isUk" style="margin-left: 8px"><Icon type="ios-key"/></span>
          </Option>
        </Select>
        <pd-button style="margin-left: 6px" type="primary" @click="handleFindTableByUq">{{ $t('cha-zhao-you-gai-lie-de-biao') }}
        </pd-button>
        <BatchSet :checkAll="checkAll" :handleCheckAll="handleCheckAll" :checkAllGroup="checkAllGroup"
                  :checkAllGroupChange="checkAllGroupChange"
                  :hasColumnTableList="hasColumnTableList" :showHasColumnTableListSize="showHasColumnTableListSize"></BatchSet>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmPk('batch')">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal v-model="showSetBucketKeysBatch"
           width="600px"
           footer-hide
           :closable="false"
           :title="$t('pi-liang-xiu-gai-pai-xu-jian-he-fen-tong-jian')">
      <div v-if="showSetBucketKeysBatch">
        <Form label-position="right" :label-width="80">
          <FormItem :label="$t('pai-xu-jian')">
            <Select style="width: 340px" filterable :placeholder="$t('qing-xuan-ze-suo-yin-lie-fou-ze-hui-ying-xiang-xing-neng')"
                    @on-change="handleSelectUniq" multiple :key="uqIndex" v-model="currentCols">
              <Option v-for="uniq in getAllUniqData" :key="uniq.column" :value="uniq.column">
                <span>{{ uniq.column }}</span>
                <span v-if="uniq.isUk" style="margin-left: 8px"><Icon type="ios-key"/></span>
              </Option>
            </Select>
            <pd-button style="margin-left: 6px" type="primary" @click="handleFindTableByUq">{{ $t('cha-zhao-you-gai-lie-de-biao') }}
            </pd-button>
          </FormItem>
          <FormItem :label="$t('fen-tong-jian')">
            <Select filterable v-model="specifiedDisKeys" multiple
                    :key="uqIndex">
              <Option v-for="key in selectedTargetKeysList" :key="key"
                      :value="key">
                {{key}}
              </Option>
            </Select>
          </FormItem>
        </Form>
        <p style="margin-top: 20px;margin-bottom: 10px;font-size: 14px;">{{ $t('ying-yong-dao-yi-xia-biao') }}</p>
        <div style="background-color: #FAFAFA;border: 1px solid #EDEDED;padding: 22px 12px">
          <Checkbox style="margin-bottom: 10px"
                    :value="checkAll"
                    @click.prevent.native="handleCheckAllBatch">{{ $t('quan-xuan') }}
          </Checkbox>
          <CheckboxGroup style="max-height: 200px;overflow: auto;" v-model="checkAllGroup"
                         @on-change="checkAllGroupChange">
            <Checkbox style="margin-bottom: 10px" v-for="table of showHasColumnTableList"
                      :label="`${table.dbName}.${table.tableName}`" :key="`${table.dbName}.${table.tableName}`"></Checkbox>
          </CheckboxGroup>
          <Page size="small" style="margin-top: 20px" :total="hasColumnTableList.length" show-total
                :page-size="showHasColumnTableListSize" @on-change="handleShowHasColumnPageChange"/>
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmBuckets('batch')">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showNoPkList"
      width="600px"
      footer-hide
      :closable="false"
      :title="$t('chuang-jian-ren-wu-ti-shi')">
      <div>
        <p style="margin-bottom: 20px;font-weight: 500">
          {{ $t('yi-xia-biao-xuan-ze-le-tong-bu-update-huo-delete-dan-mei-you-xuan-ze-zhu-jian-lie-ke-neng-dao-zhi-tong-bu-yi-chang-huo-xing-neng-di-xia-shi-fou-xu-yao-zi-dong-wei-zhe-xie-biao-she-zhi-di-yi-ge-wei-yi-jian-wei-zhu-jian') }}</p>
        <Card dis-hover>
          <div style="word-break: break-all;max-height: 300px;overflow: auto;">
            {{ getaNoPkTableList }}
          </div>
        </Card>
        <div slot="footer" class="modal-footer no-pk-modal" style="margin-top: 20px">
          <!--          <pd-button type="primary" @click="handleSetPk">{{ $t('zi-dong-she-zhi') }}</pd-button>-->
          <pd-button type="default" @click="handleNext">{{ $t('hu-lve-bing-ji-xu') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showNoUkList"
      width="600px"
      footer-hide
      :closable="false"
      :title="$t('chuang-jian-ren-wu-ti-shi')">
      <div>
        <p style="margin-bottom: 20px;font-weight: 500">
          {{ $t('yi-xia-biao-xuan-ze-le-tong-bu-update-huo-delete-dan-mei-you-xuan-ze-zhu-jian-lie-ye-mei-you-wei-yi-jian-ke-neng-dao-zhi-tong-bu-yi-chang-huo-dao-zhi-xing-neng-di-xia-qing-fan-hui-shang-yi-bu-qu-xiao-yi-xia-biao-de-update-he-delete-huo-wei-zhe-xie-biao-she-zhi-zhu-jian-huo-wei-yi-jian') }}</p>
        <Card dis-hover>
          <div style="word-break: break-all;max-height: 300px;overflow: auto;">
            {{ getNoUkTableList }}
          </div>
        </Card>
        <div slot="footer" class="modal-footer no-pk-modal" style="margin-top: 20px">
          <pd-button type="primary" @click="handleCancel">{{ $t('zhi-dao-le') }}</pd-button>
          <pd-button type="default" @click="handleNext">{{ $t('hu-lve-bing-ji-xu') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showNoRedisKey"
      width="500px"
      footer-hide
      :closable="false"
      :title="$t('chuang-jian-ren-wu-ti-shi')">
      <div>
        <p style="margin-bottom: 20px;font-weight: 500">{{ $t('yi-xia-biao-mei-you-she-zhi-redis-key-qing-she-zhi-hou-zai-dian-ji-xia-yi-bu') }}</p>
        <Card dis-hover>
          <div style="word-break: break-all;max-height: 300px;overflow: auto;">
            {{ getNoRedisKeyList }}
          </div>
        </Card>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleCancel">{{ $t('zhi-dao-le') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showNoSinkColumnList"
      footer-hide
      :closable="false"
      :title="$t('chuang-jian-ren-wu-ti-shi')"
    >
      <div>
        {{ $t('yi-xia-lie-mei-you-she-zhi-mu-biao-biao-zi-duan') }}
        <div style="height: 300px;overflow: scroll;margin-top: 10px;width: 500px;">
          <div v-for="column in noSinkColumnList" :key="column.sourceColumn" style="display: flex;">
            <span style="font-weight: bold;">{{ column.sourceColumn }}</span>
            <span>({{ column.sourceDb }}{{ DataSourceGroup.hasSchema.includes(taskInfo.sourceType) ? `.${column.sourceSchema}` : '' }}.{{ column.sourceTable }})</span>
          </div>
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleCancel">{{ $t('zhi-dao-le') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showMappingRule"
      :title="$t('mu-biao-ying-she-gui-ze')"
      :closable="false"
      footer-hide
    >
      <div v-if="showMappingRule">
        <Form :label-width="80">
          <FormItem :label="$t('ying-she-gui-ze')">
            <Select v-model="theColumnMappingIndex" :placeholder="$t('qing-xuan-ze-ying-she-gui-ze')" filterable>
              <Option v-for="(rule,index) of taskInfo.columnMappingRule"
                      :value="index" :key="index">{{ rule.i18nRuleName }}
              </Option>
            </Select>
          </FormItem>
          <!--          <FormItem :label="DataSourceGroup.mq.includes(taskInfo.sinkType)?'Topic名称':'目标表'">-->
          <!--            <Select v-model="currentTopicName" :placeholder="DataSourceGroup.mq.includes(taskInfo.sinkType)?'请选择Topic名称':'请选择目标表'" filterable>-->
          <!--              <Option v-for="(topic,index) of store.state.sinkTableList[taskInfo.dbMap[selectedIndex].sourceDb]"-->
          <!--                      :value="topic.tableName" :key="index">{{topic.tableName}}</Option>-->
          <!--            </Select>-->
          <!--          </FormItem>-->
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmSetMappingRule">{{ $t('que-ren') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import {
  columnMappingRule,
  drdsPartitionAlgs,
  getEsAnalyzerTypes,
  listHdfsFileFormat,
  listHivePartitionFunc,
  schemaLessValueFormatTemplate,
  listVirtualColGenRule,
  virtualColGenSupport,
  specifiedKeysSupport
} from '@/services/cc/api/constant';
import { getDsTypeMapping, listDbTablesUniqs, validatePartitionExpr } from '@/services/cc/api/datasource';
import { validExprFilter, validTargetWhereCondition } from '@/services/cc/api/datarule';
import _ from 'lodash';
import { getExampleData, handleMqSchemaTool } from '@/views/viewUtil';
import $ from 'jquery';
import {
  isCk, isDb2, isES, isHana,
  isHasSchema, isKafka, isMongoDB, isMySQL, isOracle
} from '@/utils';
import Mapping from '@/views/util';
import BatchSet from '@/components/function/dataJob/createJob/cleanData/BatchSet';
import store from '@/store';
import DataSourceGroup from '@/views/dataSourceGroup';
import TableMapping from './cleanData/TableMapping';
import MqTableMapping from './cleanData/MqTableMapping';
import TableCacheMapping from './cleanData/TableCacheMapping';
import FilterHeader from './cleanData/FilterHeader';
import CleanDataModal from './cleanData/CleanDataModal';
import TableContainer from './cleanData/TableContainer';
import CleanDataMixin from '../../mixin/cleanDataMixin';
import { handleCopy } from './util';
import VirtualColumn from './cleanData/VirtualColumn';

export default {
  mixins: [CleanDataMixin],
  props: {
    type: {
      default: 'create',
      type: String
    },
    taskInfo: Object,
    updateLoading: Function,
    updateNextDisabled: Function,
    handleStep: Function
  },
  components: {
    FilterHeader,
    TableMapping,
    MqTableMapping,
    CleanDataModal,
    TableContainer,
    VirtualColumn,
    TableCacheMapping,
    BatchSet
  },
  created() {
    if (DataSourceGroup.mysql.includes(this.taskInfo.sourceType) || DataSourceGroup.polar.includes(this.taskInfo.sourceType)) {
      this.getMappingRule(this.getUniqList(this.handleInit));
    } else {
      this.getMappingRule(this.handleInit());
    }

    if (DataSourceGroup.es.includes(this.taskInfo.sinkType)) {
      this.getEsAnalyzerTypes();
    }

    if (this.taskInfo.processType === 'edit') {
      const dbMapObj = {};
      const selectedTablesObj = {};
      const partitionsObj = {};
      const redisKeyObj = {};
      const idFieldNamesObj = {};
      this.taskInfo.dbMap.forEach((db) => {
        db.selectedTables.forEach((table) => {
          if (isHasSchema(this.taskInfo.sourceType)) {
            if (dbMapObj[table.sourceDb] && dbMapObj[table.sourceDb][table.sourceSchema]) {
              dbMapObj[table.sourceDb][table.sourceSchema][table.sourceTable] = table;
            } else {
              dbMapObj[table.sourceDb] = {
                [table.sourceSchema]: {
                  [table.sourceTable]: table
                }
              };
            }
          } else {
            if (dbMapObj[db.sourceDb]) {
              dbMapObj[db.sourceDb][table.sourceTable] = table;
            } else {
              dbMapObj[db.sourceDb] = {
                [table.sourceTable]: table
              };
            }
          }
        });
      });
      this.taskInfo.schemaData.sourceSchema.forEach((db) => {
        if (isHasSchema(this.taskInfo.sourceType)) {
          if (isOracle(this.taskInfo.sourceType)) {
            db.tableSpaces.forEach((tableSpace) => {
              tableSpace.tables.forEach((table) => {
                const columnsObj = {};
                table.columns.forEach((column) => {
                  columnsObj[column.column] = {
                    ...column,
                    db,
                    table
                  };
                });
                table.columnsObj = columnsObj;
                if (selectedTablesObj[db.db] && selectedTablesObj[db.db][tableSpace.tableSpace]) {
                  selectedTablesObj[db.db][tableSpace.tableSpace][table.table] = table;
                } else {
                  selectedTablesObj[db.db] = {
                    [tableSpace.tableSpace]: {
                      [table.table]: table
                    }
                  };
                }
              });
            });
          } else {
            db.schemas.forEach((schema) => {
              schema.tables.forEach((table) => {
                const columnsObj = {};
                table.columns.forEach((column) => {
                  columnsObj[column.column] = {
                    ...column,
                    db,
                    table
                  };
                });
                table.columnsObj = columnsObj;
                if (selectedTablesObj[db.db] && selectedTablesObj[db.db][schema.schema]) {
                  selectedTablesObj[db.db][schema.schema][table.table] = table;
                } else {
                  selectedTablesObj[db.db] = {
                    [schema.schema]: {
                      [table.table]: table
                    }
                  };
                }
              });
            });
          }
        } else {
          if (db.collections) {
            db.tables = db.collections;
          }
          db.tables.forEach((table) => {
            if (table.collection) {
              table.table = table.collection;
            }
            const columnsObj = {};
            if (table.columns) {
              table.columns.forEach((column) => {
                columnsObj[column.column] = {
                  ...column,
                  db,
                  table
                };
              });
            }
            table.columnsObj = columnsObj;
            if (selectedTablesObj[db.db]) {
              selectedTablesObj[db.db][table.table] = table;
            } else {
              selectedTablesObj[db.db] = {
                [table.table]: table
              };
            }
          });
        }
      });
      if (this.taskInfo.schemaData.targetSchema) {
        this.taskInfo.schemaData.targetSchema.forEach((db) => {
          if (db.topic) {
            partitionsObj[db.topic] = db.partitions;
          } else if (db.prefix) {
            redisKeyObj[db.prefix] = db.suffixFields;
          } else if (db.indexName) {
            idFieldNamesObj[db.indexName] = db.idFieldNames;
          }
        });
      }
      this.taskInfo.preSelectedTablesObj = selectedTablesObj;
      this.taskInfo.dbMapObj = dbMapObj;

      Object.values(this.taskInfo.selectedTables)
        .forEach((db) => {
          if (Array.isArray(db)) {
            db.forEach((table) => {
              if (idFieldNamesObj[table.sinkTable]) {
                table.originalIdFieldNames = idFieldNamesObj[table.sinkTable];
                table.idFieldNames = idFieldNamesObj[table.sinkTable];
              }
              if (partitionsObj[table.sinkTable]) {
                table.originalPartitions = partitionsObj[table.sinkTable];
                table.partitions = partitionsObj[table.sinkTable];
              }
              if (redisKeyObj[table.sinkTable]) {
                table.originalSuffixFields = redisKeyObj[table.sinkTable];
                table.suffixFields = redisKeyObj[table.sinkTable];
              }
              if (selectedTablesObj[table.sourceDb] && selectedTablesObj[table.sourceDb][table.sourceTable] && selectedTablesObj[table.sourceDb][table.sourceTable].dataFilter) {
                table.whereCondition = selectedTablesObj[table.sourceDb][table.sourceTable].dataFilter.expression;
                table.originalWhereCondition = selectedTablesObj[table.sourceDb][table.sourceTable].dataFilter.expression;
                table.dataFilterType = selectedTablesObj[table.sourceDb][table.sourceTable].dataFilter.type;
              }
              if (selectedTablesObj[table.sourceDb] && selectedTablesObj[table.sourceDb][table.sourceTable] && selectedTablesObj[table.sourceDb][table.sourceTable].dstUpdateCondition) {
                table.targetWhereCondition = selectedTablesObj[table.sourceDb][table.sourceTable].dstUpdateCondition;
                table.originalTargetWhereCondition = selectedTablesObj[table.sourceDb][table.sourceTable].dstUpdateCondition;
              }
            });
          }
        });
    }
  },
  mounted() {
    this.listVirtualColGenRule();
    let totalHeight = window.innerHeight;
    this.setPkAndIdFlags = this.taskInfo.setPkAndIdFlags;

    if (totalHeight < 720) {
      totalHeight = 720;
    }
    $('.clean-data-body')
      .css('height', `${totalHeight - 300}px`);
    $('.table-filter-nav-container')
      .css('height', `${totalHeight - 360}px`);
    $('.table-filter-nav')
      .css('height', `${totalHeight - 425}px`);
    this.tableHeight = totalHeight - 370;
    if (this.taskInfo.virtualColumnData && Object.keys(this.taskInfo.virtualColumnData).length > 0) {
      this.tableHeight = totalHeight - 570;
    }
    this.virtualColGenSupport();
    this.specifiedKeysSupport();
  },
  data() {
    return {
      batchListResult: [],
      showMappingRule: false,
      searchColumnKey: '',
      operateType: 'cancel',
      exprFilterColumnList: [],
      currentData: '',
      theMappingRule: [],
      theMappingIndex: 0,
      theColumnMappingIndex: 0,
      showNoSinkColumnList: false,
      noSinkColumnList: [],
      setPkAndIdFlags: {},
      showNoPkList: false,
      showNoUkList: false,
      showSetBucketKey: false,
      ignoreNoPk: false,
      noPkList: [],
      noUkList: [],
      noRedisKey: [],
      mqSchemaKey: 0,
      showChangePk: false,
      showSetId: false,
      currentColumnList: [],
      showChangePkBatch: false,
      showSetBucketKeysBatch: false,
      showOperateColumns: false,
      showRedisKey: false,
      showNoRedisKey: false,
      drdsPartitionSetting: {},
      sourceTableMetaList: {},
      sinkTableMetaList: {},
      DataSourceGroup,
      esDisableIndexType: ['binary', 'geo_point', 'object'],
      tableHeight: 500,
      Mapping,
      table: {},
      selectedIndex: 0,
      filterList: [],
      loading: false,
      showSetAllPartitions: false,
      index: 0,
      HivePartitionFuncs: [],
      HdfsFileFormats: {},
      virtualColGenRule: [],
      store: {},
      total: 0,
      page: 1,
      pageSize: 20,
      showFilter: false,
      showTargetFilter: false,
      showVirtualColumn: false,
      showFilterAll: false,
      showTargetFilterAll: false,
      showPartitionAll: false,
      showPartitionExprAll: false,
      checkAll: false,
      showDrdsPartition: false,
      showSrPartition: false,
      srData: {
        partitionExpr: ''
      },
      showBatchSetRedisKey: false,
      showBatchVirtualColumn: false,
      uniqList: {},
      drdsPartition: {
        dbpartition: '',
        tbpartition: '',
        tbpartitions: 1,
        dbFuncs: 'HASH',
        tbFuncs: 'HASH'
      },
      finishStatus: {},
      checkAllGroup: [],
      partitionCheckAll: true,
      partitionCheckAllGroup: [],
      showPartition: false,
      showMqPartition: false,
      sinkTables: [],
      mqSchema: '',
      mqSchemaTemplate: '',
      whereCondition: '',
      targetWhereCondition: '',
      whereConditionFilter: {},
      filterTable: {
        filterTableName: '',
        tableFilterType: ''
      },
      mqPartition: {
        key: [],
        count: 4
      },
      cleanDataData: {},
      tableInfo: [],
      selectedColumns: {},
      sinkColumns: {},
      sinkColumnNames: [],
      tableList: [],
      showTableList: [],
      filteredTableList: [],
      selectedTable: {},
      hasColumnTableList: [],
      showHasColumnTableList: [],
      showHasColumnTableListPage: 1,
      showHasColumnTableListSize: 10,
      virtualColumnData: {},
      typeMapping: {},
      currentUqs: [],
      currentCols: [],
      uqInfo: {
        constraintName: '',
        cols: []
      },
      uqIndex: 0,
      esTypeList: [],
      selectedTargetKeys: [],
      selectedTargetKeysList: [],
      specifiedDisKeys: [],
      selectedIds: [],
      promiseList: [],
      partitionData: {
        partition: [
          {
            key: '',
            func: ''
          }
        ]
      },
      supportPkgDs: [],
      supportVirtualColumn: false,
      supportSpecifiedKeys: false,
      dataFilterType: 'SQL_WHERE_ADV',
      filterMode: 'fuzzy'
    };
  },
  computed: {
    getFiledPath() {
      if (isHasSchema(this.taskInfo.sourceType)) {
        return `/${this.selectedTable.sourceDb}/${this.selectedTable.sourceSchema}/${this.selectedTable.sourceTable}`;
      }
      return `/${this.selectedTable.sourceDb}/${this.selectedTable.sourceTable}`;
    },
    getShowVirtualColumnData() {
      if (this.taskInfo.virtualColumnData && this.selectedTable) {
        console.log('this.taskInfo.virtualColumnData[this.getFiledPath]', this.taskInfo.virtualColumnData[this.getFiledPath]);
      }
      return this.taskInfo.virtualColumnData && this.selectedTable && this.taskInfo.virtualColumnData[this.getFiledPath] || [];
    },
    createReviseFromCheck() {
      return this.store.state.jobData && this.store.state.jobData.createReviseTask;
    },
    getTargetValue() {
      return (target) => {
        switch (this.taskInfo.columnMappingRule[this.taskInfo.columnMappingIndex].rule) {
          case 'TO_LOWER_CASE':
            return target.toLowerCase();
          case 'TO_UPPER_CASE':
            return target.toUpperCase();
          case 'MIRROR':
            return target;
          default:
            return target;
        }
      };
    },
    getMappingMethod() {
      return (type) => {
        if (type === 'table') {
          if (DataSourceGroup.es.indexOf(this.taskInfo.jobData.targetDsVO.dataSourceType) > -1) {
            return 'TABLE_INDEX';
          }
          if (DataSourceGroup.mq.indexOf(this.taskInfo.jobData.targetDsVO.dataSourceType) > -1) {
            return 'TABLE_TOPIC';
          }
          if (DataSourceGroup.redis.indexOf(this.taskInfo.jobData.targetDsVO.dataSourceType) > -1) {
            return 'TABLE_KEYPREFIX';
          }
          if (DataSourceGroup.hasSchema.includes(this.taskInfo.jobData.targetDsVO.dataSourceType)) {
            if (DataSourceGroup.hasSchema.includes(this.taskInfo.jobData.sourceDsVO.dataSourceType)) {
              return 'TABLE_TABLE_WITH_SCHEMA';
            }
          }
          return 'TABLE_TABLE';
        }
        if (type === 'column') {
          if (DataSourceGroup.hasSchema.includes(this.taskInfo.jobData.targetDsVO.dataSourceType)) {
            if (DataSourceGroup.hasSchema.includes(this.taskInfo.jobData.sourceDsVO.dataSourceType)) {
              return 'COLUMN_COLUMN_WITH_SCHEMA';
            }
          }
          return 'COLUMN_COLUMN';
        }
      };
    },
    getTargetData() {
      return (type, sourceDb, sourceTable, sourceColumn) => {
        // console.log(type, sourceDb, sourceTable, sourceColumn);
        let data = '';

        this.taskInfo.schemaData.mappingConfig.forEach((item) => {
          if (item.method === type) {
            if (type === 'DB_DB') {
              const mapping = {
                value: sourceDb.db
              };

              if (item.serializeMapping[JSON.stringify(mapping)]) {
                data = JSON.parse(item.serializeMapping[JSON.stringify(mapping)]).value;
              } else {
                data = sourceDb.db;
              }
            } else if (type === 'SCHEMA_SCHEMA') {
              const mapping = {
                parent: {
                  value: sourceDb.db
                },
                value: sourceDb.tableSpaces ? sourceDb.tableSpaces[0].tableSpace : sourceDb.schemas[0].schema
              };

              if (item.serializeMapping[JSON.stringify(mapping)]) {
                const mappingData = JSON.parse(item.serializeMapping[JSON.stringify(mapping)]);

                data = `${mappingData.parent.value}.${mappingData.value}`;
              } else {
                data = sourceDb.db;
              }
            } else if (type === 'DB_SCHEMA') {
              const mapping = {
                value: sourceDb.db
              };

              if (item.serializeMapping[JSON.stringify(mapping)]) {
                const mappingData = JSON.parse(item.serializeMapping[JSON.stringify(mapping)]);

                data = `${mappingData.parent.value}.${mappingData.value}`;
              } else {
                data = sourceDb.db;
              }
            } else if (type === 'SCHEMA_DB') {
              if (sourceDb.tableSpaces) {
                const mapping = {
                  parent: {
                    value: sourceDb.db
                  },
                  value: sourceDb.tableSpaces[0].tableSpace
                };

                if (item.serializeMapping[JSON.stringify(mapping)]) {
                  const mappingData = JSON.parse(item.serializeMapping[JSON.stringify(mapping)]);
                  data = mappingData.value;
                } else {
                  data = sourceDb.db;
                }
              } else {
                const mapping = {
                  parent: {
                    value: sourceDb.db
                  },
                  value: sourceDb.schemas[0].schema
                };

                if (item.serializeMapping[JSON.stringify(mapping)]) {
                  const mappingData = JSON.parse(item.serializeMapping[JSON.stringify(mapping)]);

                  data = mappingData.value;
                } else {
                  data = sourceDb.db;
                }
              }
            } else if (type === 'TABLE_TABLE') {
              if (DataSourceGroup.hasSchema.includes(this.taskInfo.jobData.sourceDsVO.dataSourceType)) {
                if (DataSourceGroup.pg.includes(this.taskInfo.jobData.sourceDsVO.dataSourceType)) {
                  const mapping = {
                    parent: {
                      value: sourceDb.schemas[0].schema,
                      parent: {
                        value: sourceDb.db
                      }
                    },
                    value: sourceTable.table
                  };

                  if (item.serializeMapping[JSON.stringify(mapping)]) {
                    data = JSON.parse(item.serializeMapping[JSON.stringify(mapping)]).value;
                  } else {
                    data = sourceTable.table;
                  }
                } else if (DataSourceGroup.oracle.includes(this.taskInfo.jobData.sourceDsVO.dataSourceType)) {
                  const mapping = {
                    parent: {
                      value: sourceDb.tableSpaces[0].tableSpace,
                      parent: {
                        value: sourceDb.db
                      }
                    },
                    value: sourceTable.table
                  };

                  if (item.serializeMapping[JSON.stringify(mapping)]) {
                    data = JSON.parse(item.serializeMapping[JSON.stringify(mapping)]).value;
                  } else {
                    data = sourceTable.table;
                  }
                }
              } else {
                const mapping = {
                  parent: {
                    value: sourceDb.db
                  },
                  value: sourceTable.table
                };

                if (item.serializeMapping[JSON.stringify(mapping)]) {
                  data = JSON.parse(item.serializeMapping[JSON.stringify(mapping)]).value;
                } else {
                  data = sourceTable.table;
                }
              }
            } else if (type === 'TABLE_INDEX') {
              const mapping = {
                parent: {
                  value: sourceDb.db
                },
                value: sourceTable.table
              };

              if (item.serializeMapping[JSON.stringify(mapping)]) {
                data = JSON.parse(item.serializeMapping[JSON.stringify(mapping)]).value;
              } else {
                data = sourceTable.table;
              }
            } else if (type === 'TABLE_TOPIC') {
              const mapping = {
                parent: {
                  value: sourceDb.db
                },
                value: sourceTable.table
              };

              if (item.serializeMapping[JSON.stringify(mapping)]) {
                data = JSON.parse(item.serializeMapping[JSON.stringify(mapping)]).value;
              } else {
                data = sourceTable.table;
              }
            } else if (type === 'TABLE_KEYPREFIX') {
              const mapping = {
                parent: {
                  value: sourceDb.db
                },
                value: sourceTable.table
              };

              if (item.serializeMapping[JSON.stringify(mapping)]) {
                const prefix = JSON.parse(item.serializeMapping[JSON.stringify(mapping)]).value;

                data = prefix;
                this.targetSchema.forEach((target) => {
                  if (target.prefix === prefix) {
                    target.suffixFields.forEach((field) => {
                      data += `:{${field}}`;
                    });
                  }
                });
              } else {
                data = sourceTable.table;
              }
            } else if (type === 'TOPIC_TABLE') {
              const mapping = {
                parent: {
                  value: sourceDb.db
                },
                value: sourceTable.table
              };

              Object.keys(item.serializeMapping)
                .forEach((key) => {
                  if (item.serializeMapping[key] === JSON.stringify(mapping)) {
                    data = JSON.parse(key).value;
                  }
                });
            } else if (type === 'COLUMN_COLUMN') {
              if (DataSourceGroup.hasSchema.includes(this.taskInfo.jobData.sourceDsVO.dataSourceType)) {
                if (DataSourceGroup.pg.includes(this.taskInfo.jobData.sourceDsVO.dataSourceType)) {
                  const mapping = {
                    parent: {
                      parent: {
                        parent: {
                          value: sourceDb.db
                        },
                        value: sourceDb.schemas[0].schema
                      },
                      value: sourceTable.table
                    },
                    value: sourceColumn.column
                  };

                  if (item.serializeMapping[JSON.stringify(mapping)]) {
                    data = JSON.parse(item.serializeMapping[JSON.stringify(mapping)]).value;
                  } else {
                    data = sourceColumn.column;
                  }
                } else if (DataSourceGroup.oracle.includes(this.taskInfo.jobData.sourceDsVO.dataSourceType)) {
                  const mapping = {
                    parent: {
                      value: sourceTable.table,
                      parent: {
                        value: sourceDb.tableSpaces[0].tableSpace,
                        parent: {
                          value: sourceDb.db
                        }
                      }
                    },
                    value: sourceColumn.column
                  };

                  if (item.serializeMapping[JSON.stringify(mapping)]) {
                    data = JSON.parse(item.serializeMapping[JSON.stringify(mapping)]).value;
                  } else {
                    data = sourceColumn.column;
                  }
                }
              } else {
                const mapping = {
                  parent: {
                    value: sourceTable.table,
                    parent: {
                      value: sourceDb.db
                    }
                  },
                  value: sourceColumn.column
                };

                if (item.serializeMapping[JSON.stringify(mapping)]) {
                  data = JSON.parse(item.serializeMapping[JSON.stringify(mapping)]).value;
                } else {
                  data = sourceColumn.column;
                }
              }
            }
          } else if (type === 'TABLE_TABLE_WITH_SCHEMA' && item.method === 'TABLE_TABLE') {
            const mapping = {
              parent: {
                value: sourceDb.tableSpaces ? sourceDb.tableSpaces[0].tableSpace : sourceDb.schemas[0].schema,
                parent: {
                  value: sourceDb.db
                }
              },
              value: sourceTable.table
            };

            if (item.serializeMapping[JSON.stringify(mapping)]) {
              data = JSON.parse(item.serializeMapping[JSON.stringify(mapping)]).value;
            } else {
              data = sourceTable.table;
            }
          } else if (type === 'COLUMN_COLUMN_WITH_SCHEMA' && item.method === 'COLUMN_COLUMN') {
            const mapping = {
              parent: {
                value: sourceTable.table,
                parent: {
                  value: sourceDb.tableSpaces ? sourceDb.tableSpaces[0].tableSpace : sourceDb.schemas[0].schema,
                  parent: {
                    value: sourceDb.db
                  }
                }
              },
              value: sourceColumn.column
            };

            if (item.serializeMapping[JSON.stringify(mapping)]) {
              data = JSON.parse(item.serializeMapping[JSON.stringify(mapping)]).value;
            } else {
              data = sourceColumn.column;
            }
          }
        });
        return data;
      };
    },
    getUniqData() {
      const key = {
        dbName: this.selectedTable.sourceDb,
        tableSchema: null,
        tableName: this.selectedTable.sourceTable
      };

      if (this.uniqList[JSON.stringify(key)]) {
        this.updateCurrentUqs(this.uniqList[JSON.stringify(key)]);

        return this.uniqList[JSON.stringify(key)];
      }

      this.updateCurrentUqs([]);
      return [];
    },
    getAllUniqData() {
      // for computed,dont remove
      console.log(this.showChangePkBatch);
      const list = [];

      Object.values(this.uniqList)
        .forEach((item) => {
          item.forEach((uq) => {
            let hasSame = false;

            list.forEach((column) => {
              if (column.column === uq.cols.join(',')) {
                hasSame = true;
              }
            });
            if (!hasSame) {
              list.push({
                column: uq.cols.join(','),
                isUk: true
              });
            }
          });
        });

      Object.values(this.selectedColumns)
        .forEach((db) => {
          Object.values(db)
            .forEach((table) => {
              table.forEach((column) => {
                let hasSame = false;

                list.forEach((column1) => {
                  if (isKafka(this.sourceType)) {
                    if (column1.column === column.sinkColumn) {
                      hasSame = true;
                    }
                  } else {
                    if (column1.column === column.sourceColumn) {
                      hasSame = true;
                    }
                  }
                });
                if (!hasSame) {
                  if (isKafka(this.sourceType)) {
                    list.push({
                      column: column.sinkColumn,
                      isUk: false
                    });
                  } else {
                    list.push({
                      column: column.sourceColumn,
                      isUk: false
                    });
                  }
                }
              });
            });
        });
      Object.values(this.taskInfo.virtualColumnData).forEach((data) => {
        data.forEach((virtualColumn) => {
          let hasSame = false;
          list.forEach((column1) => {
            if (column1.column === (virtualColumn.customName || virtualColumn.targetVirtualName)) {
              hasSame = true;
            }
          });
          if (!hasSame) {
            list.push({
              column: virtualColumn.customName || virtualColumn.targetVirtualName,
              isUk: false,
              isVirtual: true
            });
          }
        });
      });

      return list;
    },
    getAllColumns() {
      const mySet = new Set();

      Object.values(this.selectedColumns)
        .map((db) => {
          Object.values(db)
            .map((table) => {
              table.forEach((column) => {
                mySet.add(column.sourceColumn);
              });
              return null;
            });
          return null;
        });

      return Array.from(mySet);
    },
    getaNoPkTableList() {
      const tableList = [];

      this.noPkList.forEach((noPk) => {
        tableList.push(noPk.sourceTable);
        return null;
      });

      return tableList.join(',');
    },
    getNoUkTableList() {
      const tableList = [];

      this.noUkList.forEach((noPk) => {
        tableList.push(noPk.sourceTable);
        return null;
      });

      return tableList.join(',');
    },
    getNoRedisKeyList() {
      const tableList = [];

      this.noRedisKey.forEach((noPk) => {
        tableList.push(noPk.sourceTable);
      });

      return tableList.join(',');
    },
    getVirtualColumnData() {
      // for computed,dont remove
      console.log(this.showChangePk);
      let path = '';
      if (isHasSchema(this.taskInfo.sourceType)) {
        path = `/${this.selectedTable.sourceDb}/${this.selectedTable.sourceSchema}/${this.selectedTable.sourceTable}`;
      } else {
        path = `/${this.selectedTable.sourceDb}/${this.selectedTable.sourceTable}`;
      }
      return this.taskInfo.virtualColumnData[path];
    }
  },
  methods: {
    specifiedKeysSupport() {
      specifiedKeysSupport({
        srcDsType: this.taskInfo.sourceType,
        dstDsType: this.taskInfo.sinkType
      }).then((res) => {
        if (res.data.success) {
          this.supportSpecifiedKeys = res.data.data;
        }
      });
    },
    virtualColGenSupport() {
      virtualColGenSupport({
        srcDsType: this.taskInfo.sourceType,
        dstDsType: this.taskInfo.sinkType
      }).then((res) => {
        if (res.data.success) {
          this.supportVirtualColumn = res.data.data;
        }
      });
    },
    listVirtualColGenRule() {
      listVirtualColGenRule({
        dsType: this.taskInfo.sourceType
      }).then((res) => {
        if (res.data.success) {
          this.virtualColGenRule = res.data.data;
        }
      });
    },
    handleConfirmSetMappingRule() {
      this.taskInfo.columnMappingIndex = this.theColumnMappingIndex;
      this.taskInfo.selectedColumnMappingRule = this.taskInfo.columnMappingRule[this.taskInfo.columnMappingIndex].rule;
      let theTable;
      Object.values(this.selectedColumns).forEach((db) => {
        Object.values(db).forEach((table) => {
          this.tableList.forEach((table1) => {
            if (table.length > 0) {
              if (table[0].sourceDb === table1.sourceDb && table[0].sourceTable === table1.sourceTable) {
                theTable = table1;
              }
            }
          });
          table.forEach((column) => {
            if (!(this.taskInfo.processType === 'edit' && theTable.hasInJob)) {
              if (theTable.needAutoCreated || DataSourceGroup.noStruct.includes(this.taskInfo.sinkType)
                || (!theTable.needAutoCreated && (this.hasTheSameItem(this.getTargetValue(column.sourceColumn), this.taskInfo.sinkColumns[theTable.sinkDb
                  ? theTable.sinkDb : theTable.targetDb][theTable.sinkTable
                  ? theTable.sinkTable : theTable.targetTable]) || this.hasIgnoreCaseColumn(this.getTargetValue(column.sourceColumn), this.taskInfo.sinkColumns[theTable.sinkDb
                  ? theTable.sinkDb : theTable.targetDb][theTable.sinkTable
                  ? theTable.sinkTable : theTable.targetTable]).hasIgnoreCase))) {
                column.sinkColumn = this.getTargetValue(column.sourceColumn);
              } else {
                column.sinkColumn = '';
              }
            }
          });
        });
      });
      this.showMappingRule = false;
    },
    compareTables() {
      if (Object.keys(this.taskInfo.selectedTables).length !== Object.keys(this.taskInfo.compareSelectedTables).length) {
        return false;
      }

      let same = true;

      Object.keys(this.taskInfo.selectedTables).forEach((key) => {
        const db = this.taskInfo.selectedTables[key];
        const preDb = this.taskInfo.compareSelectedTables[key];
        if (Array.isArray(db) && Array.isArray(preDb)) {
          if (db.length !== preDb.length) {
            same = false;
          } else {
            for (let i = 0; i < db.length; i++) {
              const table = db[i];
              const preTable = preDb[i];
              if (table._checked !== preTable._checked || table.sinkDb !== preTable.sinkDb || table.sinkTable !== preTable.sinkTable || table.targetSchema !== preTable.targetSchema
                || table.sourceDb !== preTable.sourceDb || table.sourceSchema !== preTable.sourceSchema || table.sourceTable !== preTable.sourceTable) {
                same = false;
              }
            }
          }
        }
      });

      return same;
    },
    getSelectedInfo(db, index) {
      let n = 0;
      const columnMap = [];

      if (this.selectedColumns[db] && this.selectedColumns[db][index] instanceof Array) {
        this.selectedColumns[db][index].forEach((item) => {
          if (item.selected) {
            n++;
            columnMap.push({
              sourceColumn: item.sourceColumn,
              sinkColumn: item.sinkColumn
            });
          }
          return null;
        });
      }
      return {
        selectedCount: n,
        columnMap
      };
    },
    updateCurrentUqs(data) {
      this.currentUqs = data;
    },
    getMappingRule(func) {
      this.updateLoading(true);
      this.updateNextDisabled(true);
      columnMappingRule({
        dstDsId: this.taskInfo.targetDataSourceId,
        clusterId: this.taskInfo.clusterId,
        dstHostType: this.taskInfo.targetHostType,
        srcDsType: this.taskInfo.sourceType,
        dstDsType: this.taskInfo.sinkType
      })
        .then((res) => {
          if (res.data.code === '1') {
            if (!this.taskInfo.columnMappingRule.length) {
              this.taskInfo.columnMappingRule = res.data.data;
              res.data.data.forEach((rule, index) => {
                if (rule.defaultCheck) {
                  this.taskInfo.columnMappingIndex = index;
                  this.taskInfo.selectedColumnMappingRule = this.taskInfo.columnMappingRule[this.taskInfo.columnMappingIndex].rule;
                }
              });
            }

            if (func) {
              func();
            }
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },
    handleInit() {
      if (DataSourceGroup.mq.includes(this.taskInfo.sinkType) || DataSourceGroup.redis.includes(this.taskInfo.sinkType)) {
        schemaLessValueFormatTemplate({ schemaLessValueFormat: this.taskInfo.advancedSetting.dstSchemaLessFormat })
          .then((res) => {
            this.mqSchemaTemplate = res.data.data;
          });
      } else if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        schemaLessValueFormatTemplate({ schemaLessValueFormat: this.taskInfo.advancedSetting.srcSchemaLessFormat })
          .then((res) => {
            this.mqSchemaTemplate = res.data.data;
          });
      }
      getDsTypeMapping({
        srcDsType: this.taskInfo.sourceType,
        dstDsType: this.taskInfo.sinkType,
        dstDsId: this.taskInfo.targetDataSourceId
      })
        .then((res1) => {
          if (res1.data.code === '1') {
            this.typeMapping = res1.data.data;

            this.store = store;
            if (this.taskInfo.firstToCleanData) {
              if (this.compareTables()) {
                this.selectedColumns = _.cloneDeep(this.taskInfo.selectedColumns);
                this.tableList = this.taskInfo.tableInfo;
                this.filteredTableList = _.cloneDeep(this.tableList);
                this.total = this.filteredTableList.length;
                this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
                this.selectedTable = this.showTableList[0];
                let
                  totalHeight = window.innerHeight;

                if (totalHeight < 720) {
                  totalHeight = 720;
                }
                this.tableHeight = totalHeight - 370;
                let firstVirtualColumnData = '';
                if (isHasSchema(this.taskInfo.sourceType)) {
                  firstVirtualColumnData = this.taskInfo.virtualColumnData[`/${this.selectedTable.sourceDb}/${this.selectedTable.sourceSchema}/${this.selectedTable.sourceTable}`];
                } else {
                  firstVirtualColumnData = this.taskInfo.virtualColumnData[`/${this.selectedTable.sourceDb}/${this.selectedTable.sourceTable}`];
                }
                if (firstVirtualColumnData && firstVirtualColumnData.length) {
                  this.tableHeight = totalHeight - 570;
                }
                this.selectedIndex = 0;
                this.forceUpdateView();
                const that = this;

                setTimeout(() => {
                  that.taskInfo.dbMap.forEach((db) => {
                    that.handleGetSelectedColumns(db.sourceDb);
                    return null;
                  });
                  this.updateLoading(false);
                  this.updateNextDisabled(false);
                });
              } else {
                this.init();
                this.taskInfo.compareSelectedTables = _.cloneDeep(this.taskInfo.selectedTables);
              }
            } else {
              this.init();
              this.taskInfo.firstToCleanData = true;
              this.taskInfo.compareSelectedTables = _.cloneDeep(this.taskInfo.selectedTables);
            }

            if (DataSourceGroup.hive.indexOf(this.taskInfo.sinkType) > -1) {
              listHivePartitionFunc({
                loopRunType: this.taskInfo.fullPeriodDate.dayType
              })
                .then((res) => {
                  if (res.data.code === '1') {
                    this.HivePartitionFuncs = res.data.data;
                    this.partitionData.partition[0].key = this.HivePartitionFuncs[0].partitionKey;
                    this.partitionData.partition[0].func = this.HivePartitionFuncs[0].function;
                  }
                });

              listHdfsFileFormat()
                .then((res) => {
                  if (res.data.code === '1') {
                    this.HdfsFileFormats = res.data.data;
                  }
                });
            }
            if (DataSourceGroup.drds.indexOf(this.taskInfo.sinkType) > -1) {
              drdsPartitionAlgs()
                .then((res) => {
                  if (res.data.code === '1') {
                    this.drdsPartitionSetting = res.data.data;
                  }
                });
            }

            if (!DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
              this.getDsTypeMeta();
            }
          } else {
            this.updateLoading(false);
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
      this.updateLoading(false);
    },
    getUniqList(func) {
      this.updateLoading(true);
      this.updateNextDisabled(true);

      const dbNames = [];

      this.taskInfo.dbMap.forEach((db) => {
        dbNames.push(db.sourceDb);
        return null;
      });
      listDbTablesUniqs({
        host: this.taskInfo.sourceHostType === 'PUBLIC' ? this.taskInfo.sourcePublicHost : this.taskInfo.sourcePrivateHost,
        privateHost: this.taskInfo.sourcePrivateHost,
        publicHost: this.taskInfo.sourcePublicHost,
        hostType: this.taskInfo.sourceHostType,
        type: this.taskInfo.sourceType,
        userName: DataSourceGroup.oracle.indexOf(this.taskInfo.sourceType) > -1 && this.taskInfo.sourceAccountRole ? `${this.taskInfo.sourceAccount} as SYSDBA` : this.taskInfo.sourceAccount,
        dbNames,
        dataSourceId: this.taskInfo.sourceDataSourceId,
        clusterId: this.taskInfo.clusterId
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.uniqList = res.data.data;
          }
          if (func) {
            func();
          } else {
            this.updateLoading(false);
          }
        });
    },
    init() {
      if (DataSourceGroup.hive.includes(this.taskInfo.sinkType) && this.HivePartitionFuncs && this.HivePartitionFuncs.length > 0) {
        this.taskInfo.partitionData = {
          partition: [
            {
              key: this.HivePartitionFuncs[0].partitionKey,
              func: this.HivePartitionFuncs[0].function
            }
          ]
        };
      }
      this.finishStatus = {};
      this.taskInfo.dbMap.forEach((item) => {
        this.finishStatus[item.sourceDb] = false;
      });
      this.updateLoading(true);
      this.updateNextDisabled(true);
      this.taskInfo.tableInfo = [];
      this.filteredTableList = [];
      this.tableList = [];
      this.promiseList = [];
      this.taskInfo.dbMap.forEach((item, index) => {
        // console.log((this.type === 'edit' && this.taskInfo.newData[item.sourceDb]), this.type, this.taskInfo.newData[item.sourceDb]);
        // if (this.type === 'create' || (this.type === 'edit' && this.taskInfo.newData[item.sourceDb])) {
        // console.log(this.taskInfo.newData[item.sourceDb]);
        this.updateLoading(true);
        this.updateNextDisabled(true);

        if (!item.sourceDb && !item.sinkDb) {
          item.sourceDb = 'empty';
          item.sinkDb = 'empty';
        }

        this.selectedItem = item;
        if (DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) > -1 || isMongoDB(this.taskInfo.sourceType)) {
          this.selectedColumns[item.sinkDb] = {};
        } else {
          this.selectedColumns[item.sourceDb] = {};
        }
        this.dbTablesMap = {};
        this.dbTablesMap[item.sourceDb] = [];
        this.sinkDbTableMap[item.sinkDb] = [];
        this.sourceTableMetaList[item.sourceDb] = [];
        this.sinkTableMetaList[item.sinkDb] = [];
        if (item.selectedTables) {
          item.selectedTables.forEach((table) => {
            if (table.selected) {
              this.dbTablesMap[item.sourceDb].push(table.sourceTable);
              if (!table.needAutoCreated) {
                // console.log('sinkDbTableMap push');
                this.sinkDbTableMap[item.sinkDb].push(table.sinkTable);
              }
              this.tableList.push({
                sourceTable: table.sourceTable,
                sinkTable: table.sinkTable,
                customSinkTable: table.customSinkTable,
                sourceDb: item.sourceDb,
                sinkDb: item.sinkDb,
                action: table.action,
                index,
                sourceSchema: table.sourceSchema,
                targetSchema: table.targetSchema,
                needAutoCreated: table.needAutoCreated,
                partitions: table.partitions,
                shards: table.shards,
                replication: table.replication,
                fieldMetaList: table.fieldMetaList,
                partitionKeys: [],
                hasInJob: table.hasInJob,
                dataFilterType: table.dataFilterType,
                whereCondition: table.whereCondition
              });
              this.taskInfo.tableInfo = this.tableList;

              this.sourceTableMetaList[item.sourceDb].push({
                dbName: item.sourceDb,
                tableName: table.sourceTable,
                schemaName: item.sourceSchema
              });
              this.sinkTableMetaList[item.sinkDb].push({
                dbName: item.sinkDb,
                tableName: table.sinkTable,
                schemaName: item.targetSchema
              });
            }
          });
        }
        if (this.sinkDbTableMap[item.sinkDb].length > 0 && (DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) > -1 || DataSourceGroup.mongo.includes(this.taskInfo.sourceType))) {
          this.getMqColumnData(item, index === this.taskInfo.dbMap.length - 1);
        } else if (this.sinkDbTableMap[item.sinkDb].length > 0) {
          this.getSourceAndTargetColumnData(item, index === this.taskInfo.dbMap.length - 1);
        } else {
          this.getSourceColumnData(item, index === this.taskInfo.dbMap.length - 1);
        }
        // }
      });
      Promise.allSettled(this.promiseList)
        .then((results) => {
          // const len = results.length;
          let ifUpdateNextDisabled = true;
          results.forEach((res) => {
            res = res.value;
            if (res) {
              const {
                item,
                selectedItem,
                sinkTables = {}
              } = res;
              try {
                if (this.sinkDbTableMap[item.sinkDb].length > 0 && (DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) > -1 || DataSourceGroup.mongo.includes(this.taskInfo.sourceType))) {
                  if (res.data.code === '1') {
                    let sinkTables2 = {};

                    sinkTables2 = res.data.data.tableMetaDataMap[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb] || res.data.data.tableMetaDataMap.empty;
                    console.log(1);
                    Object.keys(sinkTables2)
                      .forEach((key, i) => {
                        const arr = [];

                        sinkTables2[key].forEach((sinkColumn) => {
                          // console.log(1);
                          arr.push({
                            _checked: true,
                            _disabled: sinkColumn.pk || isMongoDB(this.taskInfo.sourceType) || this.createReviseFromCheck || isKafka(this.taskInfo.sourceType),
                            sinkColumn: sinkColumn.columnName,
                            sinkType: sinkColumn.dataType,
                            sinkIsNullable: sinkColumn.isNullable,
                            selected: true,
                            isPk: sinkColumn.pk,
                            isId: false,
                            index: i,
                            needAutoCreated: false,
                            sinkTable: key,
                            sinkDb: selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb
                          });
                        });
                        this.selectedColumns[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb][key] = arr;
                      });
                  }
                } else if (this.sinkDbTableMap[item.sinkDb].length > 0) {
                  let tables = [];

                  tables = res.data.data.tableMetaDataMap[selectedItem.sourceDb] || res.data.data.tableMetaDataMap.empty;
                  const noPkTableNameList = res.data.data.noPkTableNameList;
                  console.log(2);

                  this.tableList.forEach((table) => {
                    try {
                      const arr = [];
                      const suffixFields = [];

                      if (table.sourceDb === selectedItem.sourceDb) {
                        if (tables && tables[table.sourceTable]) {
                          tables[table.sourceTable].forEach((column, i) => {
                            if (column.pk) {
                              suffixFields.push(column.columnName);
                            }

                            let hasTheSameColumn = false;

                            if (sinkTables) {
                              if (sinkTables[table.sinkTable ? table.sinkTable : table.targetTable]) {
                                sinkTables[table.sinkTable ? table.sinkTable : table.targetTable].forEach((sinkColumn) => {
                                  if (sinkColumn.columnName === column.columnName) {
                                    hasTheSameColumn = true;
                                    arr.push({
                                      _checked: !column.columnDisabled && (this.hasIgnoreCaseColumn(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                        ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable
                                        ? table.sinkTable : table.targetTable]).hasIgnoreCase || (Boolean(this.typeMapping[column.dataType.toUpperCase()])
                                        && (table.needAutoCreated || DataSourceGroup.noStruct.includes(this.taskInfo.sinkType)
                                          || (!table.needAutoCreated && this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                            ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable]))))),
                                      _disabled: DataSourceGroup.mysql.includes(this.taskInfo.sourceType) && this.supportPkgDs.includes(this.taskInfo.sinkType)
                                        ? false : (table.cols && table.cols[column.columnName]
                                        || Boolean(!this.typeMapping[column.dataType.toUpperCase()])) || table.needAutoCreated || column.columnDisabled || this.createReviseFromCheck,
                                      sourceColumn: column.columnName,
                                      sourceType: column.dataType.toUpperCase(),
                                      sinkColumn: (table.needAutoCreated || DataSourceGroup.noStruct.includes(this.taskInfo.sinkType)
                                        || (!table.needAutoCreated && (this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                          ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable
                                          ? table.sinkTable : table.targetTable]) || this.hasIgnoreCaseColumn(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                          ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable
                                          ? table.sinkTable : table.targetTable]).hasIgnoreCase))) ? this.typeMapping[column.dataType.toUpperCase()]
                                          ? this.hasIgnoreCaseColumn(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                            ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable
                                            ? table.sinkTable : table.targetTable]).columnName : '' : '',
                                      commonRule: this.taskInfo ? this.taskInfo.commonRule : '',
                                      sourceIsNullable: column.isNullable,
                                      sinkType: this.typeMapping[column.dataType.toUpperCase()] || '',
                                      sinkIsNullable: sinkColumn.isNullable,
                                      selected: !column.columnDisabled && (this.hasIgnoreCaseColumn(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                        ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable
                                        ? table.sinkTable : table.targetTable]).hasIgnoreCase || (Boolean(this.typeMapping[column.dataType.toUpperCase()])
                                        && (table.needAutoCreated || DataSourceGroup.noStruct.includes(this.taskInfo.sinkType)
                                          || (!table.needAutoCreated && this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                            ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable]))))),
                                      isPk: table.cols && table.cols[column.columnName] || column.pk,
                                      isId: DataSourceGroup.es.indexOf(this.sinkType) > -1 ? (table.cols && table.cols[column.columnName] || column.pk) : false,
                                      index: i,
                                      sourceDb: table.sourceDb,
                                      sourceTable: table.sourceTable,
                                      sourceSchema: table.sourceSchema,
                                      targetSchema: table.targetSchema,
                                      analyzer: sinkColumn.esAnalyzerType,
                                      jsonValue: false,
                                      isIndex: true,
                                      timeFormat: sinkColumn.timeFormat,
                                      columnDisabled: column.columnDisabled,
                                      filterDesc: column.filterDesc,
                                      needAutoCreated: table.needAutoCreated || !this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                        ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable])
                                    });
                                  }
                                });
                              }
                            }

                            if (!hasTheSameColumn) {
                              console.log(22);
                              const arrColumn = {
                                _checked: !column.columnDisabled && (this.hasIgnoreCaseColumn(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                  ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable
                                  ? table.sinkTable : table.targetTable]).hasIgnoreCase || (Boolean(this.typeMapping[column.dataType.toUpperCase()])
                                  && (table.needAutoCreated || DataSourceGroup.noStruct.includes(this.taskInfo.sinkType)
                                    || (!table.needAutoCreated && this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                      ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable]))))),
                                _disabled: DataSourceGroup.mysql.includes(this.taskInfo.sourceType) && this.supportPkgDs.includes(this.taskInfo.sinkType)
                                  ? false : (table.cols && table.cols[column.columnName] || table.needAutoCreated
                                  || Boolean(!this.typeMapping[column.dataType.toUpperCase()])) || column.columnDisabled || this.createReviseFromCheck,
                                sourceColumn: column.columnName,
                                sourceType: column.dataType.toUpperCase(),
                                sinkColumn: (table.needAutoCreated || DataSourceGroup.noStruct.includes(this.taskInfo.sinkType)
                                  || (!table.needAutoCreated && (this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                    ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable
                                    ? table.sinkTable : table.targetTable]) || this.hasIgnoreCaseColumn(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                    ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable
                                    ? table.sinkTable : table.targetTable]).hasIgnoreCase))) ? this.typeMapping[column.dataType.toUpperCase()]
                                    ? this.hasIgnoreCaseColumn(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                      ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable
                                      ? table.sinkTable : table.targetTable]).columnName : '' : '',
                                commonRule: this.taskInfo ? this.taskInfo.commonRule : '',
                                sourceIsNullable: column.isNullable,
                                sinkType: this.typeMapping[column.dataType.toUpperCase()] || '',
                                sinkIsNullable: column.isNullable,
                                selected: !column.columnDisabled && (this.hasIgnoreCaseColumn(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                  ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable
                                  ? table.sinkTable : table.targetTable]).hasIgnoreCase || (Boolean(this.typeMapping[column.dataType.toUpperCase()])
                                  && (table.needAutoCreated || DataSourceGroup.noStruct.includes(this.taskInfo.sinkType)
                                    || (!table.needAutoCreated && this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                      ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable]))))),
                                isPk: table.cols && table.cols[column.columnName] || column.pk,
                                isId: DataSourceGroup.es.indexOf(this.sinkType) > -1 ? (table.cols && table.cols[column.columnName] || column.pk) : false,
                                index: i,
                                sourceDb: table.sourceDb,
                                sourceTable: table.sourceTable,
                                sourceSchema: table.sourceSchema,
                                targetSchema: table.targetSchema,
                                jsonValue: false,
                                isIndex: true,
                                columnDisabled: column.columnDisabled,
                                filterDesc: column.filterDesc,
                                needAutoCreated: table.needAutoCreated || !this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                  ? selectedItem.sinkDb : selectedItem.targetDb] && this.taskInfo.sinkColumns[selectedItem.sinkDb
                                  ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable
                                  ? table.sinkTable : table.targetTable])
                              };

                              if (DataSourceGroup.es.indexOf(this.sinkType) > -1) {
                                arrColumn.analyzer = column.analyzer
                                  ? column.analyzer : this.typeMapping[column.dataType.toUpperCase()] === 'TEXT' ? 'STANDARD' : null;
                              }

                              arr.push(arrColumn);
                            }
                            if (DataSourceGroup.mq.indexOf(this.sinkType) > -1 && column.pk) {
                              if (!table.partitionKeys) {
                                table.partitionKeys = [];
                              }
                              if (!table.partitions) {
                                table.partitions = 4;
                              }
                              table.partitionKeys.push(column.columnName);
                            }
                          });
                        }
                        this.selectedColumns[table.sourceDb][table.sourceTable] = arr;
                        if (DataSourceGroup.mq.indexOf(this.sinkType) > -1 && this.taskInfo.selectedTables[table.sourceDb]) {
                          this.taskInfo.selectedTables[table.sourceDb].forEach((selectTable) => {
                            if (selectTable.sourceTable === table.sourceTable) {
                              selectTable.partitionKeys = table.partitionKeys;
                            }
                          });
                        }
                        if (DataSourceGroup.redis.indexOf(this.sinkType) > -1 && this.taskInfo.selectedTables[table.sourceDb]) {
                          this.taskInfo.selectedTables[table.sourceDb].forEach((selectTable) => {
                            if (selectTable.sourceTable === table.sourceTable) {
                              if (selectTable.originalSuffixFields) {
                                selectTable.suffixFields = selectTable.originalSuffixFields;
                                table.suffixFields = selectTable.originalSuffixFields;
                              } else {
                                selectTable.suffixFields = suffixFields;
                                table.suffixFields = suffixFields;
                              }
                            }
                          });
                        }
                        if (noPkTableNameList && noPkTableNameList[table.sourceDb] && noPkTableNameList[table.sourceDb].includes(table.sourceTable)) {
                          table.isNoPk = true;
                          tables[table.sourceTable].isNoPk = true;
                          const key = {
                            dbName: table.sourceDb,
                            tableSchema: null,
                            tableName: table.sourceTable
                          };

                          if (this.uniqList[JSON.stringify(key)] && this.uniqList[JSON.stringify(key)][0]) {
                            const uqInfo = this.uniqList[JSON.stringify(key)][0];

                            table.cols = this.uniqList[JSON.stringify(key)][0].cols;
                            this.taskInfo.selectedTables[table.sourceDb].forEach((item1) => {
                              if (item1.sourceTable === table.sourceTable) {
                                item1.constraintName = _.cloneDeep(uqInfo.constraintName);
                                item1.cols = _.cloneDeep(uqInfo.cols);
                              }
                            });

                            if (this.selectedColumns[table.sourceDb][table.sourceTable]) {
                              this.selectedColumns[table.sourceDb][table.sourceTable].forEach((column) => {
                                if (uqInfo.cols.includes(column.sourceColumn)) {
                                  column.isPk = true;
                                  if (DataSourceGroup.es.indexOf(this.sinkType) > -1) {
                                    column.isId = true;
                                  }
                                } else {
                                  column.isPk = false;
                                  column.isId = false;
                                }
                              });
                            }
                          } else {
                            table.cols = [];
                          }
                        }
                        // setTimeout(() => {
                        //   that.handleGetSelectedColumns(table.sourceDb);
                        //   that.selectedColumns = { ...that.selectedColumns };
                        // });
                      }
                    } catch (e) {
                      console.log('e', e);
                    }
                  });
                  if (DataSourceGroup.mq.indexOf(this.sinkType) > -1) {
                    this.selectedColumns[this.selectedTable.sourceDb][this.selectedTable.sourceTable].forEach((column) => {
                      if (column.selected) {
                        this.mqSchema.sqlType[column.sourceColumn] = this.Mapping.sqlType[column.sourceType] ? this.Mapping.sqlType[column.sourceType] : 1111;
                        this.mqSchema.data[0][column.sourceColumn] = getExampleData(column.sourceType);
                      }
                    });
                  }
                } else {
                  let tables = [];

                  tables = res.data.data.tableMetaDataMap[selectedItem.sourceDb] || res.data.data.tableMetaDataMap.empty;
                  const noPkTableNameList = res.data.data.noPkTableNameList;
                  console.log(3);

                  this.tableList.forEach((table) => {
                    try {
                      const arr = [];

                      if (table.fieldMetaList) {
                        const columns = [];

                        table.fieldMetaList.forEach((field) => {
                          columns.push({
                            columnName: field.fieldName,
                            dataTypeWithLength: field.fieldTypeName,
                            needIndex: field.needIndex,
                            analyzer: field.esAnalyzerType ? field.esAnalyzerType : field.fieldTypeName === 'TEXT' ? 'STANDARD' : null,
                            timePrecision: field.timePrecision,
                            jsonValue: field.jsonValue
                          });
                        });
                        if (!this.taskInfo.sinkColumns[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb]) {
                          this.taskInfo.sinkColumns[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb] = {};
                        }
                        this.taskInfo.sinkColumns[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable] = columns;
                      } else {
                        if (!this.taskInfo.sinkColumns[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb]) {
                          this.taskInfo.sinkColumns[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb] = {};
                        }
                        this.taskInfo.sinkColumns[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable] = [];
                      }
                      if (table.sourceDb === selectedItem.sourceDb) {
                        if (tables[table.sourceTable]) {
                          tables[table.sourceTable].forEach((column, i) => {
                            arr.push({
                              _checked: !column.columnDisabled && (this.hasIgnoreCaseColumn(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable
                                ? table.sinkTable : table.targetTable]).hasIgnoreCase || (Boolean(this.typeMapping[column.dataType.toUpperCase()])
                                && (table.needAutoCreated || DataSourceGroup.mq.includes(this.taskInfo.sinkType)
                                  || (!table.needAutoCreated && this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                    ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable]))))),
                              _disabled: DataSourceGroup.mysql.includes(this.taskInfo.sourceType) && this.supportPkgDs.includes(this.taskInfo.sinkType)
                                ? false : (table.cols && table.cols[column.columnName]
                                || Boolean(!this.typeMapping[column.dataType.toUpperCase()])) || table.needAutoCreated || column.columnDisabled || this.createReviseFromCheck,
                              sourceColumn: column.columnName,
                              sourceType: column.dataType.toUpperCase(),
                              sinkColumn: this.typeMapping[column.dataType.toUpperCase()]
                                ? this.hasIgnoreCaseColumn(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                  ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable
                                  ? table.sinkTable : table.targetTable]).columnName : '',
                              commonRule: this.taskInfo ? this.taskInfo.commonRule : '',
                              sourceIsNullable: column.isNullable,
                              sinkType: this.typeMapping[column.dataType.toUpperCase()] || '',
                              sinkIsNullable: column.isNullable,
                              selected: !column.columnDisabled && (this.hasIgnoreCaseColumn(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable
                                ? table.sinkTable : table.targetTable]).hasIgnoreCase || (Boolean(this.typeMapping[column.dataType.toUpperCase()])
                                && (table.needAutoCreated || DataSourceGroup.mq.includes(this.taskInfo.sinkType)
                                  || (!table.needAutoCreated && this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                    ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable]))))),
                              isPk: table.cols && table.cols[column.columnName] || column.pk,
                              isId: DataSourceGroup.es.indexOf(this.sinkType) > -1 ? (table.cols && table.cols[column.columnName] || column.pk) : false,
                              index: i,
                              sourceDb: table.sourceDb,
                              sourceTable: table.sourceTable,
                              sourceSchema: table.sourceSchema,
                              targetSchema: table.targetSchema,
                              needAutoCreated: table.needAutoCreated || !this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable]),
                              isIndex: true,
                              analyzer: DataSourceGroup.es.indexOf(this.sinkType) > -1 ? column.analyzer
                                ? column.analyzer : this.typeMapping[column.dataType.toUpperCase()] === 'TEXT' ? 'STANDARD' : null : '',
                              timePrecision: column.timePrecision,
                              jsonValue: column.jsonValue,
                              columnDisabled: column.columnDisabled,
                              filterDesc: column.filterDesc
                            });

                            if (DataSourceGroup.mq.indexOf(this.sinkType) > -1 && column.pk) {
                              if (!table.partitionKeys) {
                                table.partitionKeys = [];
                                table.partitions = 4;
                              }

                              table.partitionKeys.push(column.columnName);
                            }
                          });
                        }
                        if (DataSourceGroup.mq.indexOf(this.sinkType) > -1 && this.taskInfo.selectedTables[table.sourceDb]) {
                          this.taskInfo.selectedTables[table.sourceDb].forEach((selectTable) => {
                            if (selectTable.sourceTable === table.sourceTable) {
                              selectTable.partitionKeys = table.partitionKeys;
                            }
                          });
                        }
                        this.selectedColumns[table.sourceDb][table.sourceTable] = arr;
                        if (noPkTableNameList && noPkTableNameList[table.sourceDb] && noPkTableNameList[table.sourceDb].includes(table.sourceTable)) {
                          table.isNoPk = true;
                          tables[table.sourceTable].isNoPk = true;
                          const key = {
                            dbName: table.sourceDb,
                            tableSchema: null,
                            tableName: table.sourceTable
                          };

                          if (this.uniqList[JSON.stringify(key)] && this.uniqList[JSON.stringify(key)][0]) {
                            const uqInfo = this.uniqList[JSON.stringify(key)][0];

                            table.cols = this.uniqList[JSON.stringify(key)][0].cols;
                            let list = this.taskInfo.selectedTables[table.sourceDb];

                            if (!list) {
                              list = this.tableList;
                            }
                            if (list) {
                              list.forEach((item1) => {
                                if (item1.sourceTable === table.sourceTable) {
                                  item1.constraintName = _.cloneDeep(uqInfo.constraintName);
                                  item1.cols = _.cloneDeep(uqInfo.cols);
                                }
                              });
                            }

                            if (this.selectedColumns[table.sourceDb][table.sourceTable]) {
                              this.selectedColumns[table.sourceDb][table.sourceTable].forEach((column) => {
                                if (uqInfo.cols.includes(column.sourceColumn)) {
                                  column.isPk = true;
                                  if (DataSourceGroup.es.indexOf(this.sinkType) > -1) {
                                    column.isId = true;
                                  }
                                } else {
                                  column.isPk = false;
                                  column.isId = false;
                                }
                              });
                            }
                          } else {
                            table.cols = [];
                          }
                        }
                        // setTimeout(() => {
                        //   that.handleGetSelectedColumns(table.sourceDb);
                        //   that.selectedColumns = { ...that.selectedColumns };
                        // });
                      }
                    } catch (e) {
                      console.log('e', e);
                    }
                  });
                  if (DataSourceGroup.mq.indexOf(this.sinkType) > -1) {
                    this.taskInfo.tableInfo = this.tableList;
                    this.tableList = this.taskInfo.tableInfo;
                    this.filteredTableList = _.cloneDeep(this.tableList);
                    this.total = this.filteredTableList.length;
                    this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
                    this.selectedTable = this.showTableList[0];
                    if (DataSourceGroup.mq.indexOf(this.sinkType) > -1) {
                      const pkColumns = [];

                      // eslint-disable-next-line no-unused-expressions
                      this.taskInfo.sinkColumns && this.taskInfo.sinkColumns[selectedItem.sinkDb.db
                        ? selectedItem.sinkDb.db : selectedItem.sinkDb][this.selectedTable.sinkTable
                        ? this.selectedTable.sinkTable : this.selectedTable.targetTable].forEach((item1) => {
                        if (item1.isPk) {
                          pkColumns.push(item1.columnName);
                        }
                      });
                      this.mqSchema = {
                        data: [
                          {}
                        ],
                        database: selectedItem.sourceDb,
                        entryType: null,
                        es: 1608782968300,
                        id: 0,
                        isDdl: false,
                        mysqlType: {},
                        old: [],
                        pkNames: pkColumns,
                        schema: null,
                        sql: null,
                        sqlType: {},
                        table: this.selectedTable.sourceTable,
                        ts: 1608782968300,
                        type: 'INSERT'
                      };
                      this.selectedColumns[this.selectedTable.sourceDb][this.selectedTable.sourceTable].forEach((column) => {
                        if (column.selected) {
                          this.mqSchema.sqlType[column.sourceColumn] = this.Mapping.sqlType[column.sourceType] ? this.Mapping.sqlType[column.sourceType] : 1111;
                          this.mqSchema.data[0][column.sourceColumn] = getExampleData(column.sourceType);
                        }
                      });
                    }
                  }
                }
              } catch (e) {
                console.log(e);
              }
            } else {
              ifUpdateNextDisabled = false;
            }
            if (store.state.jobData) {
              this.parseSchemaData(store.state.jobData.schemaData);
            }
          });
          if (ifUpdateNextDisabled) {
            this.updateNextDisabled(false);
          }
        })
        .finally(() => {
          this.updateLoading(false);
          this.loading = false;
          setTimeout(() => {
            this.handleGetColumnByTable((this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page))[0], 0);
            this.taskInfo.dbMap.forEach((db) => {
              if (DataSourceGroup.noDb.includes(this.taskInfo.sourceType) || isMongoDB(this.taskInfo.sourceType)) {
                this.handleGetSelectedColumns(db.sinkDb);
              } else {
                this.handleGetSelectedColumns(db.sourceDb);
              }
            });
            if (this.taskInfo.processType === 'edit') {
              if (isHasSchema(this.taskInfo.sourceType)) {
                Object.values(this.selectedColumns)
                  .forEach((db) => {
                    Object.values(db)
                      .forEach((table) => {
                        table.forEach((column) => {
                          if (this.taskInfo.preSelectedTablesObj[column.sourceDb] && this.taskInfo.preSelectedTablesObj[column.sourceDb][column.sourceSchema]
                            && this.taskInfo.preSelectedTablesObj[column.sourceDb][column.sourceSchema][column.sourceTable]) {
                            if (this.taskInfo.preSelectedTablesObj[column.sourceDb][column.sourceSchema][column.sourceTable].specifiedPks.includes(column.sourceColumn)) {
                              column.isPk = true;
                            } else {
                              column.isPk = false;
                            }
                            if (this.taskInfo.preSelectedTablesObj[column.sourceDb][column.sourceSchema][column.sourceTable].columnsObj[column.sourceColumn]) {
                              const preColumn = this.taskInfo.preSelectedTablesObj[column.sourceDb][column.sourceSchema][column.sourceTable].columnsObj[column.sourceColumn];
                              column.hasInJob = !preColumn.inBlackList;
                              column._checked = !preColumn.inBlackList;
                              column.selected = !preColumn.inBlackList;
                              column.originalIsPk = column.isPk;
                              if (this.taskInfo.dbMapObj[column.sourceDb] && this.taskInfo.dbMapObj[column.sourceDb][column.sourceSchema]
                                && this.taskInfo.dbMapObj[column.sourceDb][column.sourceSchema][column.sourceTable]
                                && this.taskInfo.dbMapObj[column.sourceDb][column.sourceSchema][column.sourceTable].originalSinkTable === this.taskInfo.dbMapObj[column.sourceDb][column.sourceSchema][column.sourceTable].sinkTable) {
                                column.sinkColumn = this.getTargetData(this.getMappingMethod('column'), preColumn.db, preColumn.table, preColumn);
                              }
                              column.originalSinkColumn = column.sinkColumn;
                            }
                          }
                        });
                      });
                  });
              } else {
                Object.values(this.taskInfo.selectedTables)
                  .forEach((db) => {
                    if (db instanceof Array) {
                      db.forEach((table) => {
                        if (table.selected) {
                          if (this.taskInfo.preSelectedTablesObj[table.sourceDb] && this.taskInfo.preSelectedTablesObj[table.sourceDb][table.sourceTable]) {
                            if (this.taskInfo.preSelectedTablesObj[table.sourceDb][table.sourceTable].specifiedPks && this.taskInfo.preSelectedTablesObj[table.sourceDb][table.sourceTable].specifiedPks.length > 0) {
                              table.cols = this.taskInfo.preSelectedTablesObj[table.sourceDb][table.sourceTable].specifiedPks;
                            }
                          }
                          let path = '';
                          if (isHasSchema(this.taskInfo.sourceType)) {
                            path = `/${table.sourceDb}/${table.sourceSchema}/${table.sourceTable}`;
                          } else {
                            path = `/${table.sourceDb}/${table.sourceTable}`;
                          }
                          if (this.taskInfo.virtualColumnData[path]) {
                            this.taskInfo.virtualColumnData[path].forEach((column1) => {
                              const columnName = column1.customName || column1.targetVirtualName;
                              if (this.taskInfo.preSelectedTablesObj[table.sourceDb][table.sourceTable].specifiedPks.includes(columnName)) {
                                column1.isPk = true;
                              } else {
                                column1.isPk = false;
                              }
                            });
                          }
                        }
                      });
                    }
                  });
                Object.values(this.selectedColumns)
                  .forEach((db) => {
                    Object.values(db)
                      .forEach((table) => {
                        table.forEach((column) => {
                          if (this.taskInfo.preSelectedTablesObj[column.sourceDb] && this.taskInfo.preSelectedTablesObj[column.sourceDb][column.sourceTable]) {
                            if (this.taskInfo.preSelectedTablesObj[column.sourceDb][column.sourceTable].specifiedPks && this.taskInfo.preSelectedTablesObj[column.sourceDb][column.sourceTable].specifiedPks.length > 0) {
                              if (this.taskInfo.preSelectedTablesObj[column.sourceDb][column.sourceTable].specifiedPks.includes(column.sourceColumn)) {
                                column.isPk = true;
                              } else {
                                column.isPk = false;
                              }
                            }
                            if (this.taskInfo.preSelectedTablesObj[column.sourceDb][column.sourceTable].columnsObj[column.sourceColumn]) {
                              const preColumn = this.taskInfo.preSelectedTablesObj[column.sourceDb][column.sourceTable].columnsObj[column.sourceColumn];
                              column.hasInJob = !preColumn.inBlackList;
                              column._checked = !preColumn.inBlackList;
                              column.selected = !preColumn.inBlackList;
                              column.originalIsPk = column.isPk;
                              if (this.taskInfo.dbMapObj[column.sourceDb] && this.taskInfo.dbMapObj[column.sourceDb][column.sourceTable]
                                && this.taskInfo.dbMapObj[column.sourceDb][column.sourceTable].originalSinkTable === this.taskInfo.dbMapObj[column.sourceDb][column.sourceTable].sinkTable) {
                                column.sinkColumn = this.getTargetData(this.getMappingMethod('column'), preColumn.db, preColumn.table, preColumn);
                              }
                              column.originalSinkColumn = column.sinkColumn;
                            }
                            if (isES(this.taskInfo.sinkType) && this.taskInfo.selectedTables && this.taskInfo.selectedTables[column.sourceDb]) {
                              for (let i = 0; i < this.taskInfo.selectedTables[column.sourceDb].length; i++) {
                                if (this.taskInfo.selectedTables[column.sourceDb][i].sourceTable === column.sourceTable && this.taskInfo.selectedTables[column.sourceDb][i].idFieldNames) {
                                  column.preIdFieldNames = this.taskInfo.selectedTables[column.sourceDb][i].idFieldNames;
                                  if (this.taskInfo.selectedTables[column.sourceDb][i].idFieldNames.includes(column.sinkColumn)) {
                                    column.isId = true;
                                    column.originalIsId = true;
                                  } else {
                                    column.isId = false;
                                    column.originalIsId = false;
                                  }
                                }
                              }
                            }
                          }
                        });
                      });
                  });
              }
            }

            this.selectedColumns = { ...this.selectedColumns };
            this.$forceUpdate();
          });
        });
      this.tableList = this.taskInfo.tableInfo;
      this.filteredTableList = this.tableList;
      this.total = this.filteredTableList.length;
      this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
    },
    hasIgnoreCaseColumn(column, sinkColumnList) {
      let hasIgnoreCase = false;
      let
        columnName = column;
      if (this.taskInfo.columnMappingRule[this.taskInfo.columnMappingIndex].compareIgnoreCase && sinkColumnList) {
        sinkColumnList.forEach((column1) => {
          if (column1.columnName.toLowerCase() === column.toLowerCase()) {
            hasIgnoreCase = true;
            columnName = column1.columnName;
          }
        });
      }
      return {
        hasIgnoreCase,
        columnName
      };
    },
    handleMqSchema() {
      if (DataSourceGroup.mq.includes(this.taskInfo.sinkType) || DataSourceGroup.redis.includes(this.taskInfo.sinkType)) {
        let type = 'tableMq';

        if (DataSourceGroup.redis.includes(this.taskInfo.sinkType)) {
          type = 'cache';
        }
        this.mqSchema = this.mqSchemaTemplate;
        this.mqSchema = handleMqSchemaTool(this.mqSchema, this.selectedColumns[this.selectedTable.sourceDb][this.selectedTable.sourceTable],
          this.selectedTable.sourceDb, DataSourceGroup.hasSchema.includes(this.taskInfo.sourceType) ? this.selectedTable.sourceSchema : '', this.selectedTable.sourceTable, type);
      } else if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        this.mqSchema = this.mqSchemaTemplate;
        this.mqSchema = handleMqSchemaTool(this.mqSchema, this.selectedColumns[this.selectedTable.sinkDb][this.selectedTable.sinkTable],
          this.selectedTable.sinkDb, DataSourceGroup.hasSchema.includes(this.taskInfo.sinkType) ? this.selectedTable.targetSchema : '', this.selectedTable.sinkTable, 'mqTable');
      }
    },
    hasTheSameItem(column, columns) {
      const ignoreCaseColumn = this.hasIgnoreCaseColumn(column, columns);
      column = ignoreCaseColumn.columnName;
      let isSame = false;

      if (columns) {
        columns.forEach((item) => {
          if (item.columnName === column) {
            isSame = true;
          }
          return null;
        });
      }
      return isSame;
    },
    hasTheSameColumn(columnName, columns) {
      const ignoreCaseColumn = this.hasIgnoreCaseColumn(columnName, columns);
      columnName = ignoreCaseColumn.columnName;
      let isSame = false;

      if (columns) {
        columns.forEach((item) => {
          if (item.columnName === columnName) {
            isSame = true;
          }
          return null;
        });
      }
      return isSame;
    },
    handleFilter(table) {
      this.showFilter = true;
      this.selectedTable = table;
      if (!this.selectedTable.dataFilterType) {
        this.selectedTable.dataFilterType = 'SQL_WHERE_ADV';
      }
      this.taskInfo.selectedTables[table.sourceDb].forEach((item) => {
        if (item.sourceTable === table.sourceTable) {
          this.whereCondition = _.cloneDeep(item.whereCondition);
        }
        return null;
      });
    },
    handleTargetFilter(table) {
      this.showTargetFilter = true;
      this.selectedTable = table;
      this.taskInfo.selectedTables[table.sourceDb].forEach((item) => {
        if (item.sourceTable === table.sourceTable) {
          this.targetWhereCondition = _.cloneDeep(item.targetWhereCondition);
        }
      });
    },
    handleBucketKeys(table) {
      this.currentColumnList = this.selectedColumns[table.sourceDb][table.sourceTable];
      const pks = [];
      this.currentColumnList.forEach((column) => {
        if (column.isPk) {
          const columnName = column.columnName || column.sourceColumn;
          pks.push(columnName);
        }
      });
      this.selectedTargetKeys = pks;
      // this.selectedTargetKeysList = pks;
      this.specifiedDisKeys = pks[0];
      this.selectedTable = table;
      this.showSetBucketKey = true;
    },
    handleChangeTargetKey() {
      this.selectedTargetKeysList = this.selectedTargetKeys.join(',').split(',');
      this.specifiedDisKeys = '';
      this.specifiedDisKeys = this.selectedTargetKeysList[0];
    },
    updateWhereCondition(data) {
      this.whereCondition = data;
    },
    updateTargetWhereCondition(data) {
      this.targetWhereCondition = data;
    },
    handleShowVirtualColumn(table) {
      this.showVirtualColumn = true;
      this.selectedTable = table;
    },
    handleGetSelectedColumns(dbName) {
      const commonSelectEdColumns = {};
      if (this.selectedColumns[dbName]) {
        Object.keys(this.selectedColumns[dbName])
          .forEach((item) => {
            if (this.selectedColumns[dbName][item] instanceof Array) {
              this.selectedColumns[dbName][item].forEach((column, i) => {
                if (DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) > -1 || isMongoDB(this.taskInfo.sourceType)) {
                  if (commonSelectEdColumns[this.selectedColumns[dbName][item][i].sinkDb]) {
                    commonSelectEdColumns[this.selectedColumns[dbName][item][i].sinkDb][this.selectedColumns[dbName][item][i].sinkTable] = this.selectedColumns[dbName][item];
                  } else {
                    commonSelectEdColumns[this.selectedColumns[dbName][item][i].sinkDb] = {};
                    commonSelectEdColumns[this.selectedColumns[dbName][item][i].sinkDb][this.selectedColumns[dbName][item][i].sinkTable] = this.selectedColumns[dbName][item];
                  }
                } else if (commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb]) {
                  commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb][this.selectedColumns[dbName][item][i].sourceTable] = this.selectedColumns[dbName][item];
                } else {
                  commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb] = {};
                  commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb][this.selectedColumns[dbName][item][i].sourceTable] = this.selectedColumns[dbName][item];
                }
                return null;
              });
            }
            return null;
          });
      }
      if (DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) > -1 || isMongoDB(this.taskInfo.sourceType)) {
        this.taskInfo.dbMap.forEach((item) => {
          if (commonSelectEdColumns[item.sinkDb]) {
            item.selectedColumns = commonSelectEdColumns[item.sinkDb];
          }
          return null;
        });
      } else {
        this.taskInfo.dbMap.forEach((item) => {
          if (commonSelectEdColumns[item.sourceDb]) {
            item.selectedColumns = commonSelectEdColumns[item.sourceDb];
          }
          return null;
        });
      }
      this.handleGetColumnByTable(this.selectedTable, this.selectedIndex);
    },
    handleSelected(table, index, selection) {
      selection.forEach((item, i) => {
        if (!DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
          if (!this.selectedColumns[table.sourceDb][table.sourceTable][item.index].sinkColumn) {
            let columnName = item.sourceColumn;

            if (this.taskInfo.targetCaseSensitive === 'false') {
              columnName = item.sourceColumn.toLowerCase();
            }
            if (!(table.needAutoCreated || DataSourceGroup.noStruct.includes(this.taskInfo.sinkType)
              || (!table.needAutoCreated && (this.hasTheSameItem(this.getTargetValue(item.sourceColumn), this.taskInfo.sinkColumns[table.sinkDb
                ? table.sinkDb : table.targetDb][table.sinkTable ? table.sinkTable : table.targetTable])
                || this.hasIgnoreCaseColumn(this.getTargetValue(columnName), this.taskInfo.sinkColumns[table.sinkDb
                  ? table.sinkDb : table.targetDb][table.sinkTable
                  ? table.sinkTable : table.targetTable]).hasIgnoreCase)))) {
              columnName = '';
            }
            this.selectedColumns[table.sourceDb][table.sourceTable][item.index].sinkColumn = this.hasIgnoreCaseColumn(this.getTargetValue(columnName), this.taskInfo.sinkColumns[table.sinkDb
              ? table.sinkDb : table.targetDb][table.sinkTable
              ? table.sinkTable : table.targetTable]).columnName;
            if (DataSourceGroup.es.indexOf(this.taskInfo.sinkType) > -1) {
              this.selectedColumns[table.sourceDb][table.sourceTable][item.index].sinkType = this.typeMapping[item.sourceType.split('(')[0].toUpperCase()];
            } else {
              this.selectedColumns[table.sourceDb][table.sourceTable][item.index].sinkType = item.sourceType;
            }
            this.selectedColumns[table.sourceDb][table.sourceTable][item.index].sinkIsNullable = item.sourceIsNullable;
            if (this.taskInfo.sinkColumns[table.sinkDb][table.sinkTable]) {
              this.taskInfo.sinkColumns[table.sinkDb][table.sinkTable].forEach((sinkColumn) => {
                if (sinkColumn.columnName === item.sourceColumn) {
                  this.selectedColumns[table.sourceDb][table.sourceTable][item.index].sinkType = sinkColumn.dataType;
                  this.selectedColumns[table.sourceDb][table.sourceTable][item.index].sinkIsNullable = sinkColumn.isNullable;
                }
                return null;
              });
            }
          }
          this.selectedColumns[table.sourceDb][table.sourceTable][item.index].selected = true;
          this.selectedColumns[table.sourceDb][table.sourceTable][item.index]._checked = true;
        } else {
          this.selectedColumns[table.sinkDb][table.sinkTable][i].selected = true;
          this.selectedColumns[table.sinkDb][table.sinkTable][i]._checked = true;
        }
        return null;
      });
      this.forceUpdateView();
      this.handleGetSelectedColumns(table.sourceDb);
    },
    handleSelectCancel(table, index, selection) {
      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        this.selectedColumns[table.sinkDb][table.sinkTable].forEach((item) => {
          let alreadyHave = false;

          selection.forEach((row) => {
            if (row.sinkColumn === item.sinkColumn) {
              alreadyHave = true;
            }
            return null;
          });
          if (!alreadyHave) {
            item.selected = false;
            this.forceUpdateView();
          }

          this.selectedColumns[table.sinkDb][table.sinkTable].forEach((column) => {
            let alreadyHaveColumn = false;

            selection.forEach((row) => {
              if (row.sinkColumn === column.sinkColumn) {
                alreadyHaveColumn = true;
              }
              return null;
            });
            if (!alreadyHaveColumn) {
              column._checked = false;
            }
            return null;
          });
          return null;
        });
      } else {
        this.selectedColumns[table.sourceDb][table.sourceTable].forEach((item) => {
          let alreadyHave = false;

          selection.forEach((row) => {
            if (row.sourceColumn === item.sourceColumn) {
              alreadyHave = true;
            }
            return null;
          });
          if (!alreadyHave) {
            item.sinkColumn = '';
            item.selected = false;
            this.forceUpdateView();
          }

          this.selectedColumns[table.sourceDb][table.sourceTable].forEach((column) => {
            let alreadyHaveColumn = false;

            selection.forEach((row) => {
              if (row.sourceColumn === column.sourceColumn) {
                alreadyHaveColumn = true;
              }
              return null;
            });
            if (!alreadyHaveColumn) {
              column._checked = false;
            }
            return null;
          });
          return null;
        });
      }
      this.handleGetSelectedColumns(table.sourceDb);
    },
    handleAllSelected(table) {
      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        this.selectedColumns[table.sinkDb][table.sinkTable].forEach((item) => {
          if (!item._disabled) {
            item.selected = Boolean(this.typeMapping[item.sourceType.toUpperCase()]);
            item._checked = Boolean(this.typeMapping[item.sourceType.toUpperCase()]);
          }
        });
      } else {
        this.selectedColumns[table.sourceDb][table.sourceTable].forEach((item) => {
          if (!item._disabled) {
            let sinkColumnName = item.sourceColumn;

            if (this.taskInfo.targetCaseSensitive === 'false') {
              sinkColumnName = item.sourceColumn.toLowerCase();
            }
            if (this.typeMapping[item.sourceType.toUpperCase()]
              && (table.needAutoCreated || DataSourceGroup.noStruct.includes(this.taskInfo.sinkType)
                || (!table.needAutoCreated && (this.hasTheSameItem(this.getTargetValue(item.sourceColumn), this.taskInfo.sinkColumns[table.sinkDb
                  ? table.sinkDb : table.targetDb][table.sinkTable
                  ? table.sinkTable : table.targetTable]) || this.hasIgnoreCaseColumn(this.getTargetValue(sinkColumnName), this.taskInfo.sinkColumns[table.sinkDb
                  ? table.sinkDb : table.targetDb][table.sinkTable
                  ? table.sinkTable : table.targetTable]).hasIgnoreCase)))) {
              item.sinkColumn = this.hasIgnoreCaseColumn(this.getTargetValue(sinkColumnName), this.taskInfo.sinkColumns[table.sinkDb
                ? table.sinkDb : table.targetDb][table.sinkTable
                ? table.sinkTable : table.targetTable]).columnName;
            } else {
              item.sinkColumn = '';
            }
            item.selected = Boolean(this.typeMapping[item.sourceType.toUpperCase()]);
            item._checked = Boolean(this.typeMapping[item.sourceType.toUpperCase()]);
          }
        });
      }
      this.forceUpdateView();
      this.handleGetSelectedColumns(table.sourceDb);
    },
    handleCancelSelectAll(table) {
      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        this.selectedColumns[table.sinkDb][table.sinkTable].forEach((item) => {
          item.selected = false;
          item._checked = false;
        });
      } else {
        this.selectedColumns[table.sourceDb][table.sourceTable].forEach((item) => {
          item.sinkColumn = '';
          item.selected = false;
          item._checked = false;
        });
      }
      this.forceUpdateView();
      this.handleGetSelectedColumns(table.sourceDb);
    },
    handleSinkColumnChange(table, row, column) {
      console.log(table, row, column);
      if (column) {
        if (isES(this.taskInfo.sinkType) && row.preIdFieldNames) {
          row.isId = row.preIdFieldNames.includes(column);
          this.selectedColumns[table.sourceDb][table.sourceTable][row.index].isId = row.preIdFieldNames.includes(column);
        }
        if (row.sourceColumn !== column) {
          this.selectedColumns[table.sourceDb][table.sourceTable][row.index].needAutoCreated = false;
          if (column === 'clougence_cloudcanal_filter_after_mapping_column') {
            row.isIndex = false;
            this.selectedColumns[table.sourceDb][table.sourceTable][row.index].isIndex = false;
          } else {
            row.sinkColumn = column;
            this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkColumn = column;
          }
        } else {
          let hasSame = false;

          this.taskInfo.sinkColumns[table.sinkDb][table.sinkTable].forEach((sinkColumn) => {
            if (sinkColumn.columnName === column) {
              hasSame = true;
            }
            return null;
          });
          console.log(hasSame, 'sink column change');
          if (!hasSame) {
            this.selectedColumns[table.sourceDb][table.sourceTable][row.index].needAutoCreated = true;
            if (column === 'clougence_cloudcanal_filter_after_mapping_column') {
              this.selectedColumns[table.sourceDb][table.sourceTable][row.index].needAutoCreated = false;
            }
          }
          if (hasSame && isDb2(this.taskInfo.sourceType)) {
            this.selectedColumns[table.sourceDb][table.sourceTable][row.index].needAutoCreated = false;
          }
        }
        this.taskInfo.sinkColumns[table.sinkDb][table.sinkTable].forEach((sinkColumn) => {
          if (sinkColumn.columnName === column) {
            this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkType = sinkColumn.dataType;
            this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkIsNullable = sinkColumn.isNullable;
            this.selectedColumns[table.sourceDb][table.sourceTable][row.index].analyzer = sinkColumn.analyzer;
            this.selectedColumns[table.sourceDb][table.sourceTable][row.index].isIndex = sinkColumn.needIndex;
            if (DataSourceGroup.es.indexOf(this.taskInfo.sinkType) > -1) {
              row.sinkType = sinkColumn.dataType.toUpperCase();
              row.analyzer = sinkColumn.analyzer;
              row.isIndex = sinkColumn.needIndex;
              // eslint-disable-next-line no-param-reassign
              row = { ...row };
            }
          }
          return null;
        });
        this.handleGetSelectedColumns(table.sourceDb);
        this.forceUpdateView();
      }
    },
    rowClassName(row) {
      let className = '';

      if (row._isChecked) {
        className += 'demo-table-info-row';
      }
      if (row.isVirtual) {
        className += ' virtual-column-cell';
      }
      return className;
    },
    forceUpdateView() {
      this.$forceUpdate();
      // this.$set(this.selectedColumns, this.index, this.index);
      // this.index++;
      // this.$set(this.selectedColumns, this.index, this.index);
    },
    generateTaskInfoData() {
      this.taskInfo.taskInfoNewData = [];
      this.taskInfo.taskInfoUpdateData = [];
      this.taskInfo.taskInfoReduceData = [];
      Object.keys(this.taskInfo.reduceData)
        .forEach((dbName) => {
          const db = this.taskInfo.reduceData[dbName];
          // console.log(dbName, db);
          let dbData = {};
          // console.log(this.taskInfo.reduceData[db.sourceDb], this.taskInfo.reduceData, db.sourceDb);
          if (this.taskInfo.reduceData[db.sourceDb]) {
            dbData = {
              ...db,
              selectedTables: []
            };
            Object.values(db.selectedTables)
              .forEach((table) => {
                dbData.selectedTables.push(table);
              });
            // dbData.selectedTables = db.selectedTables.filter((table) => this.taskInfo.reduceData[db.sourceDb][table.sourceTable]);
            this.taskInfo.taskInfoReduceData.push(dbData);
          }
        });
      this.taskInfo.dbMap.forEach((db) => {
        let dbData = {};
        let dbName = db.sourceDb;
        if (dbName === 'empty') {
          dbName = '';
        }
        if (this.taskInfo.newData[dbName]) {
          dbData = { ...db };
          dbData.selectedTables = db.selectedTables.filter((table) => this.taskInfo.newData[dbName].selectedTables[table.sourceTable]);
          this.taskInfo.taskInfoNewData.push(dbData);
        }
      });

      const dbMap = _.cloneDeep(this.taskInfo.dbMap);

      this.taskInfo.taskInfoUpdateData = dbMap.filter((db) => {
        if (db.new) {
          return false;
        }
        db.selectedTables = db.selectedTables.filter((table) => {
          if (!table.hasInJob && table._checked) {
            return false;
          }
          let edit = false;
          if (table.edit || (DataSourceGroup.kafka.includes(this.taskInfo.sinkType) && table.hasInJob && table.originalPartitions !== table.partitions)
            || (table.hasInJob && table.whereCondition !== table.originalWhereCondition) || (table.hasInJob && table.targetWhereCondition !== table.originalTargetWhereCondition)
            || (DataSourceGroup.redis.includes(this.taskInfo.sinkType) && !_.isEqual(table.suffixFields, table.originalSuffixFields))) {
            db.edit = true;
            edit = true;
          }

          const tableName = table.sourceTable;
          if (db.selectedColumns[tableName] && !db.new) {
            db.selectedColumns[tableName].forEach((column) => {
              if (this.isColumnEdited(column) && !isMongoDB(this.taskInfo.sourceType)) {
                edit = true;
                column.edit = true;
                db.edit = true;
              }
            });
          }
          return edit;
        });

        return db.edit;
      });

      return this.taskInfo.taskInfoNewData.length || this.taskInfo.taskInfoUpdateData.length || this.taskInfo.taskInfoReduceData.length;
    },
    isColumnEdited(column) {
      return (column.hasInJob && !column._checked)
        || (!column.hasInJob && column._checked)
        || (column._checked && ((column.sinkColumn !== column.originalSinkColumn) || (column.isPk !== column.originalIsPk)))
        || (column._checked && isES(this.taskInfo.sourceType) && (column.isId !== column.originalIsId));
    },
    updateNextStep(type = 'next') {
      const noColumnList = [];
      const noSinkColumnList = [];

      this.noPkList = [];
      this.noRedisKey = [];
      let noSinkColumn = false;

      if (!this.ignoreNoPk) {
        console.log('this.tableList', this.tableList);
        let dataFilterType = '';
        let differentDataFilter = false;
        this.tableList.forEach((table) => {
          let count = 0;
          let
            hasPk = false;
          if (!dataFilterType) {
            dataFilterType = table.dataFilterType;
          } else if (table.dataFilterType && dataFilterType !== table.dataFilterType) {
            console.log(123);
            differentDataFilter = true;
          }

          if (this.selectedColumns[table.sourceDb] && this.selectedColumns[table.sourceDb][table.sourceTable]) {
            this.selectedColumns[table.sourceDb][table.sourceTable].forEach((columns, index) => {
              if (columns.selected) {
                count++;
                if (columns.isPk) {
                  hasPk = true;
                }
                if (!columns.sinkColumn) {
                  columns._error = true;
                  noSinkColumnList.push(columns);
                  noSinkColumn = true;
                }
              }
              if (index === this.selectedColumns[table.sourceDb][table.sourceTable].length - 1 && count === 0) {
                noColumnList.push(table);
              }
            });
          }
          let path = '';
          if (isHasSchema(this.taskInfo.sourceType)) {
            path = `/${table.sourceDb}/${table.sourceSchema}/${table.sourceTable}`;
          } else {
            path = `/${table.sourceDb}/${table.sourceTable}`;
          }
          if (this.taskInfo.virtualColumnData[path]) {
            this.taskInfo.virtualColumnData[path].forEach((columns) => {
              if (columns.isPk) {
                hasPk = true;
              }
            });
          }
          if (table.action && ((table.action.includes('UPDATE') || table.action.includes('DELETE')) && !hasPk)) {
            this.noPkList.push(table);
          }

          if (!table.suffixFields || table.suffixFields.length === 0) {
            this.noRedisKey.push(table);
          }
        });
        this.taskInfo.selectedColumns = this.selectedColumns;

        if (type === 'next' && this.taskInfo.processType === 'edit') {
          if (!this.generateTaskInfoData()) {
            return {
              success: false,
              error: 'noEditData'
            };
          }
        }
        if (noColumnList.length > 0) {
          let noColumnTables = '';
          noColumnList.forEach((table, i) => {
            if (i === noColumnList.length - 1) {
              noColumnTables += `${table.sourceDb}.${table.sourceTable}`;
            } else {
              noColumnTables += `${table.sourceDb}.${table.sourceTable}, `;
            }
          });
          this.$Modal.warning({
            title: this.$t('ren-wu-chuang-jian-yi-chang'),
            content: this.$t('dang-qian-resallhascolumn0sourcetable-biao-mei-you-xuan-ze-zi-duan-qing-zhong-xin-xuan-ze-hou-zai-dian-ji-xia-yi-bu', [noColumnTables])
          });
          return null;
        }
        if (noSinkColumn) {
          this.showNoSinkColumnList = true;
          this.noSinkColumnList = noSinkColumnList;
          return {
            success: false,
            error: 'noSinkColumn'
          };
          // eslint-disable-next-line no-else-return
        } else {
          if (this.noPkList.length > 0 && (DataSourceGroup.mysql.includes(this.taskInfo.sourceType) || DataSourceGroup.polar.includes(this.taskInfo.sourceType))) {
            this.showNoPkList = true;
          }
          if (this.noRedisKey.length > 0 && DataSourceGroup.redis.includes(this.taskInfo.sinkType)) {
            this.showNoRedisKey = true;
          }
        }
      }
      return {
        success: true,
        allHasColumn: noColumnList,
        noPkList: this.noPkList,
        noRedisKeyList: this.noRedisKey
      };
    },
    handlePageChange(page) {
      this.page = page;
      this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
      this.selectedTable = this.showTableList[0];
      this.selectedIndex = 0;
    },
    handleShowHasColumnPageChange(page) {
      this.showHasColumnTableListPage = page;
      this.showHasColumnTableList = this.hasColumnTableList.slice(this.showHasColumnTableListSize * (this.showHasColumnTableListPage - 1),
        this.showHasColumnTableListPage * this.showHasColumnTableListSize);
    },
    checkColumns(columns, columnList) {
      const noSameColumns = [];
      columns.forEach((item) => {
        let hasSame = false;
        columnList.forEach((column) => {
          if (item === column.sourceColumn) {
            hasSame = true;
          }
        });
        if (!hasSame) {
          noSameColumns.push(item);
        }
      });
      if (noSameColumns.length > 0) {
        this.$Modal.error({
          title: this.$t('tian-jia-where-tiao-jian-yi-chang-ti-shi'),
          content: this.$t('dang-qian-biao-mei-you-xuan-ze-nosamecolumnsjoin-lie-qing-tian-xie-zhun-que-de-where-tiao-jian', [noSameColumns.join(',')])
        });
        return false;
      }
      return true;
    },
    handleAddWhereCondition(table) {
      if (this.whereCondition) {
        validExprFilter({
          dataFilterType: table.dataFilterType,
          expression: this.whereCondition
        })
          .then((res) => {
            if (res.data.code === '1') {
              const columns = res.data.data;
              const ifCorrect = this.checkColumns(columns, this.selectedColumns[table.sourceDb][table.sourceTable]);
              if (ifCorrect) {
                this.showFilter = false;
                this.tableList.forEach((item) => {
                  if (item.sourceTable === table.sourceTable && item.sourceDb === table.sourceDb) {
                    item.whereCondition = _.cloneDeep(this.whereCondition);
                    item.dataFilterType = table.dataFilterType;
                  }
                });
                this.taskInfo.selectedTables[table.sourceDb].forEach((item) => {
                  if (item.sourceTable === table.sourceTable) {
                    item.whereCondition = _.cloneDeep(this.whereCondition);
                    item.dataFilterType = table.dataFilterType;
                  }
                });
                this.forceUpdateView();
              }
            }
          });
      } else {
        this.showFilter = false;
        this.tableList.forEach((item) => {
          if (item.sourceTable === table.sourceTable && item.sourceDb === table.sourceDb) {
            item.whereCondition = _.cloneDeep(this.whereCondition);
          }
        });
        this.taskInfo.selectedTables[table.sourceDb].forEach((item) => {
          if (item.sourceTable === table.sourceTable) {
            item.whereCondition = _.cloneDeep(this.whereCondition);
          }
        });
        this.forceUpdateView();
      }
    },
    handleAddTargetWhereCondition(table) {
      if (this.targetWhereCondition) {
        validTargetWhereCondition({
          expression: this.targetWhereCondition
        })
          .then((res) => {
            if (res.data.code === '1') {
              const columns = res.data.data;
              const ifCorrect = this.checkColumns(columns, this.selectedColumns[table.sourceDb][table.sourceTable]);
              if (ifCorrect) {
                this.showTargetFilter = false;
                this.tableList.forEach((item) => {
                  if (item.sourceTable === table.sourceTable && item.sourceDb === table.sourceDb) {
                    item.targetWhereCondition = _.cloneDeep(this.targetWhereCondition);
                  }
                });
                this.taskInfo.selectedTables[table.sourceDb].forEach((item) => {
                  if (item.sourceTable === table.sourceTable) {
                    item.targetWhereCondition = _.cloneDeep(this.targetWhereCondition);
                  }
                });
                this.forceUpdateView();
              }
            }
          });
      } else {
        this.showTargetFilter = false;
        this.tableList.forEach((item) => {
          if (item.sourceTable === table.sourceTable && item.sourceDb === table.sourceDb) {
            item.targteWhereCondition = _.cloneDeep(this.targetWhereCondition);
          }
        });
        this.taskInfo.selectedTables[table.sourceDb].forEach((item) => {
          if (item.sourceTable === table.sourceTable) {
            item.targetWhereCondition = _.cloneDeep(this.targetWhereCondition);
          }
        });
        this.forceUpdateView();
      }
    },
    handleAddAllWhereCondition(data) {
      this.whereCondition = data;
      this.showFilterAll = false;
      if (data) {
        this.checkAllGroup.forEach((item) => {
          this.taskInfo.selectedTables[item.split('.')[0]].forEach((table) => {
            if (item.split('.')[1] === table.sourceTable) {
              table.whereCondition = _.cloneDeep(this.whereCondition);
              table.dataFilterType = this.dataFilterType;
            }
          });
          this.tableList.forEach((table) => {
            if (item.split('.')[1] === table.sourceTable && item.split('.')[0] === table.sourceDb) {
              table.whereCondition = _.cloneDeep(this.whereCondition);
              table.dataFilterType = this.dataFilterType;
            }
          });
        });
      } else {
        this.checkAllGroup = [];
      }
      this.hasColumnTableList = [];
      this.showHasColumnTableList = [];
      this.showHasColumnTableListPage = 1;
      this.forceUpdateView();
    },
    handleAddAllTargetWhereCondition(data) {
      this.targetWhereCondition = data;
      this.showTargetFilterAll = false;
      if (data) {
        this.checkAllGroup.forEach((item) => {
          this.taskInfo.selectedTables[item.split('.')[0]].forEach((table) => {
            if (item.split('.')[1] === table.sourceTable) {
              table.targetWhereCondition = _.cloneDeep(this.targetWhereCondition);
            }
          });
          this.tableList.forEach((table) => {
            if (item.split('.')[1] === table.sourceTable && item.split('.')[0] === table.sourceDb) {
              table.targetWhereCondition = _.cloneDeep(this.targetWhereCondition);
            }
          });
        });
      } else {
        this.checkAllGroup = [];
      }
      this.hasColumnTableList = [];
      this.showHasColumnTableList = [];
      this.showHasColumnTableListPage = 1;
      this.forceUpdateView();
    },
    handleOperateColumns(type, data) {
      if (type === 'cancel') {
        if (data) {
          this.checkAllGroup.forEach((item) => {
            const tableNameList = item.split('.');
            const db = tableNameList[0];
            const table = tableNameList[tableNameList.length - 1];

            this.selectedColumns[db][table].forEach((column) => {
              if (this.exprFilterColumnList.includes(column.sourceColumn) && !column._disabled) {
                column.selected = false;
                column._checked = false;
                column.sinkColumn = '';
              }
            });
            this.handleGetSelectedColumns(db);
            this.forceUpdateView();
          });
        }
      } else if (type === 'select') {
        if (data) {
          this.checkAllGroup.forEach((item) => {
            const tableNameList = item.split('.');
            const db = tableNameList[0];
            const table = tableNameList[tableNameList.length - 1];

            let targetDb = db;

            this.selectedColumns[db][table].forEach((column) => {
              if (this.exprFilterColumnList.includes(column.sourceColumn) && !column.selected && !column._disabled) {
                column.selected = true;
                column._checked = true;
                column.sinkColumn = this.getTargetValue(column.sourceColumn);
                this.taskInfo.dbMap.forEach((item1) => {
                  if (item1.sourceDb === db) {
                    targetDb = item1.sinkDb;
                  }
                  return null;
                });
                column.needAutoCreated = !this.hasTheSameItem(this.getTargetValue(column.sourceColumn), this.taskInfo.sinkColumns[targetDb][table]);
              }
              return null;
            });
            this.handleGetSelectedColumns(db);
            const columnsData = _.cloneDeep(this.selectedColumns);

            // this.selectedIndex = -1;
            this.forceUpdateView();

            setTimeout(() => {
              this.selectedColumns = columnsData;
            });
            return null;
          });
        }
      }

      this.showOperateColumns = false;
      this.searchColumnKey = '';
    },
    handleAddMqPartition(table) {
      this.showMqPartition = false;
      if (this.mqPartition.count > 32 || this.mqPartition.count < 1) {
        this.$Modal.warning({
          title: this.$t('fen-qu-shu-she-zhi-you-wu'),
          content: this.$t('fen-qu-shu-zhi-chi-fan-wei-wei-132-qing-she-zhi-zheng-que-de-fen-qu-shu')
        });
      } else {
        this.tableList.forEach((item) => {
          if (item.sourceDb === table.sourceDb && item.sourceTable === table.sourceTable) {
            item.partitionKeys = this.mqPartition.key;
            item.partitions = this.mqPartition.count;
          }
          return null;
        });
        this.taskInfo.selectedTables[table.sourceDb].forEach((item) => {
          if (item.sourceTable === table.sourceTable) {
            item.partitionKeys = this.mqPartition.key;
            item.partitions = this.mqPartition.count;
          }
          return null;
        });
        this.filteredTableList = this.tableList;
        this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
      }
    },
    handleAddPartitionAll() {
      this.showPartitionAll = false;
      this.taskInfo.partitionData = _.cloneDeep(this.partitionData);
      this.partitionData = {
        partition: [
          {
            key: this.HivePartitionFuncs[0].partitionKey,
            func: this.HivePartitionFuncs[0].function
          }
        ]
      };
      this.partitionCheckAllGroup.forEach((item) => {
        this.taskInfo.selectedTables[item.split('.')[0]].forEach((table) => {
          if (item.split('.')[1] === table.sourceTable) {
            table.partitionData = _.cloneDeep(this.taskInfo.partitionData);
          }
        });
      });
      this.taskInfo.dbMap.forEach((item) => {
        if (this.taskInfo.selectedTables[item.sourceDb]) {
          item.selectedTables = _.cloneDeep(this.taskInfo.selectedTables[item.sourceDb]);
        }
      });
    },
    handleCheckAll(data) {
      this.checkAll = !this.checkAll;
      this.indeterminate = false;
      if (!data) {
        data = this.hasColumnTableList;
      }
      if (this.checkAll) {
        this.checkAllGroup = [];
        data.forEach((table) => {
          this.checkAllGroup.push(`${table.db || table.dbName}.${table.sourceTable || table.tableName}`);
          return null;
        });
      } else {
        this.checkAllGroup = [];
      }
    },
    handleCheckAllBatch() {
      this.checkAll = !this.checkAll;
      this.indeterminate = false;

      if (this.checkAll) {
        this.checkAllGroup = [];
        this.hasColumnTableList.forEach((table) => {
          this.checkAllGroup.push(`${table.dbName}.${table.tableName}`);
          return null;
        });
      } else {
        this.checkAllGroup = [];
      }
    },
    checkAllGroupChange(data) {
      this.checkAllGroup = data;
      if (data.length === this.hasColumnTableList.length) {
        this.indeterminate = false;
        this.checkAll = true;
      } else if (data.length > 0) {
        this.indeterminate = true;
        this.checkAll = false;
      } else {
        this.indeterminate = false;
        this.checkAll = false;
      }
    },
    partitionCheckAllGroupChange(data) {
      if (data.length === this.tableList.length) {
        this.indeterminate = false;
        this.checkAll = true;
      } else if (data.length > 0) {
        this.indeterminate = true;
        this.checkAll = false;
      } else {
        this.indeterminate = false;
        this.checkAll = false;
      }
    },
    handleShowFilterAll() {
      this.showFilterAll = true;
      this.checkAll = false;
      this.hasColumnTableList = [];
      this.showHasColumnTableList = [];
      this.checkAllGroup = [];
      this.showHasColumnTableListPage = 1;
    },
    handleShowTargetFilterAll() {
      this.showTargetFilterAll = true;
      this.checkAll = false;
      this.hasColumnTableList = [];
      this.showHasColumnTableList = [];
      this.checkAllGroup = [];
      this.showHasColumnTableListPage = 1;
    },
    handleShowOperateColumns() {
      this.showOperateColumns = true;
      this.checkAll = false;
      this.hasColumnTableList = [];
      this.showHasColumnTableList = [];
      this.checkAllGroup = [];
      this.showHasColumnTableListPage = 1;
    },
    handleShowPartitionAll() {
      this.showPartitionAll = true;
      this.tableList.forEach((table) => {
        this.partitionCheckAllGroup.push(`${table.sourceDb}.${table.sourceTable}`);
        return null;
      });
      let func = '';
      if (!this.taskInfo.partitionData.partition.func) {
        func = this.partitionData.partition[0].func;
      }
      this.partitionData = _.cloneDeep(this.taskInfo.partitionData);
      if (func) {
        this.partitionData.partition[0].func = func;
        this.HivePartitionFuncs.forEach((item) => {
          if (item.function === func) {
            this.partitionData.partition[0].key = item.partitionKey;
          }
        });
      }
    },
    handleShowPartitionExprAll() {
      this.showPartitionExprAll = true;
    },
    handleFindExrCropTable() {
      validatePartitionExpr({
        dsType: 'StarRocks',
        partitionExpr: this.srData.partitionExpr
      }).then((res) => {
        if (res.data.success) {
          this.handleFindCropTable(res.data.data.join(','), 'target');
        }
      });
    },
    handleFindCropTable(data, type) {
      const columnList = data.split(',');
      this.exprFilterColumnList = columnList;
      this.hasColumnTableList = [];
      Object.keys(this.selectedColumns)
        .forEach((db) => {
          Object.keys(this.selectedColumns[db])
            .forEach((table) => {
              let hasSame = true;

              columnList.forEach((c) => {
                let sameColumn = false;

                this.selectedColumns[db][table].forEach((column) => {
                  let columnName = column.sourceColumn;
                  if (type === 'target') {
                    columnName = column.sinkColumn;
                  }
                  if (columnName === c) {
                    sameColumn = true;
                  }
                });
                if (!sameColumn) {
                  hasSame = false;
                }
              });
              if (hasSame) {
                this.hasColumnTableList.push({
                  db,
                  sourceTable: table
                });
              }
            });
        });
      this.showHasColumnTableListPage = 1;
      this.showHasColumnTableList = this.hasColumnTableList.slice(this.showHasColumnTableListSize * (this.showHasColumnTableListPage - 1),
        this.showHasColumnTableListPage * this.showHasColumnTableListSize);
    },
    handleFindTable(data) {
      if (data) {
        validExprFilter({
          dataFilterType: this.dataFilterType,
          expression: data
        })
          .then((res) => {
            if (res.data.code === '1') {
              const columnList = res.data.data;
              this.exprFilterColumnList = res.data.data;
              this.hasColumnTableList = [];
              Object.keys(this.selectedColumns)
                .forEach((db) => {
                  Object.keys(this.selectedColumns[db])
                    .forEach((table) => {
                      let hasSame = true;

                      columnList.forEach((c) => {
                        let sameColumn = false;

                        this.selectedColumns[db][table].forEach((column) => {
                          if (column.sourceColumn === c) {
                            sameColumn = true;
                          }
                        });
                        if (!sameColumn) {
                          hasSame = false;
                        }
                      });
                      if (hasSame) {
                        this.hasColumnTableList.push({
                          db,
                          sourceTable: table
                        });
                      }
                    });
                });
              this.showHasColumnTableListPage = 1;
              this.showHasColumnTableList = this.hasColumnTableList.slice(this.showHasColumnTableListSize * (this.showHasColumnTableListPage - 1),
                this.showHasColumnTableListPage * this.showHasColumnTableListSize);
            }
          });
      }
    },
    handleFindTargetTable(data) {
      if (data) {
        validTargetWhereCondition({
          expression: data
        })
          .then((res) => {
            if (res.data.code === '1') {
              const columnList = res.data.data;
              this.exprFilterColumnList = res.data.data;
              this.hasColumnTableList = [];
              Object.keys(this.selectedColumns)
                .forEach((db) => {
                  Object.keys(this.selectedColumns[db])
                    .forEach((table) => {
                      let hasSame = true;

                      columnList.forEach((c) => {
                        let sameColumn = false;

                        this.selectedColumns[db][table].forEach((column) => {
                          if (column.sinkColumn === c) {
                            sameColumn = true;
                          }
                        });
                        if (!sameColumn) {
                          hasSame = false;
                        }
                      });
                      if (hasSame) {
                        this.hasColumnTableList.push({
                          db,
                          sourceTable: table
                        });
                      }
                    });
                });
              this.showHasColumnTableListPage = 1;
              this.showHasColumnTableList = this.hasColumnTableList.slice(this.showHasColumnTableListSize * (this.showHasColumnTableListPage - 1),
                this.showHasColumnTableListPage * this.showHasColumnTableListSize);
            }
          });
      }
    },
    handleColumnChange() {
      this.hasColumnTableList = [];
      this.showHasColumnTableList = [];
    },
    getWhereCondition(tb) {
      let hasWhereCondition = false;

      if (this.taskInfo.selectedTables[tb.sourceDb]) {
        this.taskInfo.selectedTables[tb.sourceDb].forEach((table) => {
          if (tb.sourceTable === table.sourceTable) {
            if (table.whereConditionFilter && table.whereConditionFilter.column) {
              hasWhereCondition = true;
            }
          }
          return null;
        });
      }
      return hasWhereCondition;
    },
    clearWhereCondition(table) {
      this.taskInfo.selectedTables[table.sourceDb].forEach((item) => {
        if (item.sourceTable === table.sourceTable) {
          this.whereCondition = '';
          item.whereCondition = _.cloneDeep(this.whereCondition);
          item.dataFilterType = '';
        }
        return null;
      });
      this.tableList.forEach((item) => {
        if (item.sourceTable === table.sourceTable && item.sourceDb === table.sourceDb) {
          this.whereCondition = '';
          item.whereCondition = _.cloneDeep(this.whereCondition);
          item.dataFilterType = '';
        }
      });
    },
    clearTargetWhereCondition(table) {
      this.taskInfo.selectedTables[table.sourceDb].forEach((item) => {
        if (item.sourceTable === table.sourceTable) {
          this.targetWhereCondition = '';
          item.targetWhereCondition = '';
        }
        return null;
      });
    },
    handleTableFilter(filterType, type) {
      if (this.filterTable.tableFilterType && filterType === 'option') {
        if (this.filterList.indexOf(type) === -1) {
          this.filterList.push(type);
        }
      }
      let valueList = [];
      if (this.filterTable.filterTableName) {
        valueList = this.filterTable.filterTableName.split(';');
      }
      console.log('valueList', valueList, this.filterTable.filterTableName, this.filterTable);
      if (valueList.length > 1) {
        this.filterMode = 'exact';
      } else {
        this.filterMode = 'fuzzy';
      }
      this.filteredTableList = [];
      this.filteredTableList = this.tableList.filter((item) => {
        console.log('item', item);
        if (valueList.length < 1) {
          if ((this.filterList.indexOf('hasWhere') > -1 ? Boolean(item.whereCondition) : true)
            && (this.filterList.indexOf('noWhere') > -1 ? !item.whereCondition : true)) {
            return true;
          }
        }
        for (let i = 0; i < valueList.length; i++) {
          if ((this.filterList.indexOf('hasWhere') > -1 ? Boolean(item.whereCondition) : true)
            && (this.filterList.indexOf('noWhere') > -1 ? !item.whereCondition : true)
            && (valueList[i] ? this.filterMode === 'fuzzy' ? item.sourceTable.toLowerCase().indexOf(valueList[i].toLowerCase()) > -1 || (`${item.sourceDb}.${item.sourceTable}`).toLowerCase().indexOf(valueList[i].toLowerCase()) > -1 : item.sourceTable === valueList[i] || `${item.sourceDb}.${item.sourceTable}` === valueList[i] : true) && valueList[i]) {
            return true;
          }
        }
        return false;
      });
      console.log('filteredTableList', this.filteredTableList);
      this.total = this.filteredTableList.length;
      this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
      this.selectedTable = this.showTableList.length ? this.showTableList[0] : {};
      this.selectedIndex = 0;
    },
    handleRefresh() {
      // this.loading = true;

      if (DataSourceGroup.mysql.includes(this.taskInfo.sourceType) || DataSourceGroup.polar.includes(this.taskInfo.sourceType)) {
        this.getUniqList(this.init);
      } else {
        this.init();
      }
    },
    handleGetColumnByTable(table, index) {
      this.selectedTable = table;
      this.selectedIndex = index;
      if (DataSourceGroup.mq.indexOf(this.taskInfo.sinkType) > -1 || DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) > -1
        || DataSourceGroup.redis.indexOf(this.taskInfo.sinkType) > -1) {
        this.handleMqSchema();
      }

      if (this.taskInfo.virtualColumnData[`${table.sourceDb}/${table.sourceTable}`]) {
        let
          totalHeight = window.innerHeight;

        if (totalHeight < 720) {
          totalHeight = 720;
        }
        this.tableHeight = totalHeight - 570;
      }
    },
    handleDeleteFilter(i) {
      this.filterList.splice(i, 1);
      this.filterTable.tableFilterType = '';
      this.handleTableFilter('filter');
    },
    handleClearFilter() {
      this.filterList = [];
      this.filterTable.tableFilterType = '';
      this.handleTableFilter('filter');
    },
    handleShowMappingRule() {
      this.theColumnMappingIndex = this.taskInfo.columnMappingIndex;
      this.showMappingRule = true;
    },
    handleCancel() {
      this.showMappingRule = false;
      this.checkAll = false;
      this.showNoSinkColumnList = false;
      this.showFilter = false;
      this.showTargetFilter = false;
      this.showFilterAll = false;
      this.showTargetFilterAll = false;
      this.showPartition = false;
      this.showPartitionAll = false;
      this.showPartitionExprAll = false;
      this.showMqPartition = false;
      this.showDrdsPartition = false;
      this.showSrPartition = false;
      this.showVirtualColumn = false;
      this.showSetId = false;
      this.showChangePk = false;
      this.showChangePkBatch = false;
      this.selectedTargetKeys = [];
      this.specifiedDisKeys = [];
      this.currentCols = [];
      this.uqIndex++;
      this.showNoPkList = false;
      this.showNoUkList = false;
      this.showOperateColumns = false;
      this.showRedisKey = false;
      this.showBatchSetRedisKey = false;
      this.showNoRedisKey = false;
      this.showSetBucketKey = false;
      this.showSetBucketKeysBatch = false;
      this.showPartitionExprAll = false;
      this.showBatchVirtualColumn = false;
      if (DataSourceGroup.hive.includes(this.taskInfo.sinkType)) {
        this.partitionData = {
          partition: [
            {
              key: this.HivePartitionFuncs[0].partitionKey,
              func: this.HivePartitionFuncs[0].function
            }
          ]
        };
      }
    },
    handleNext() {
      this.ignoreNoPk = true;
      this.handleStep('next');
    },
    handleSetPartition(row) {
      this.selectedTable = row;
      this.mqPartition.count = row.partitions;
      this.mqPartition.key = row.partitionKeys;
      this.showMqPartition = true;
    },
    handleChangeIndexType(row, index, table, data) {
      if (data === 'KEYWORD' || data === 'NESTED') {
        row.analyzer = null;
        this.selectedColumns[table.sourceDb][table.sourceTable][row.index].analyzer = null;
      } else {
        row.analyzer = 'STANDARD';
        this.selectedColumns[table.sourceDb][table.sourceTable][row.index].analyzer = 'STANDARD';
      }
      this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkType = data;
      this.taskInfo.sinkColumns[table.sinkDb][table.sinkTable].forEach((sinkColumn) => {
        if (sinkColumn.columnName === row) {
          this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkType = sinkColumn.dataType;
          this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkIsNullable = sinkColumn.isNullable;
          this.selectedColumns[table.sourceDb][table.sourceTable][row.index].analyzer = sinkColumn.analyzer;
          this.selectedColumns[table.sourceDb][table.sourceTable][row.index].isIndex = sinkColumn.needIndex;
          if (this.taskInfo.sinkType === 'ElasticSearch') {
            row.sinkType = sinkColumn.dataType;
            row.analyzer = sinkColumn.analyzer;
            row.isIndex = sinkColumn.needIndex;
            // eslint-disable-next-line no-param-reassign
            row = { ...row };
          }
        }
        return null;
      });
    },
    handleChangeAnalyzer(row, index, table, data) {
      this.selectedColumns[table.sourceDb][table.sourceTable][row.index].analyzer = data;
      this.taskInfo.sinkColumns[table.sinkDb][table.sinkTable].forEach((sinkColumn) => {
        if (sinkColumn.columnName === row) {
          this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkType = sinkColumn.dataType;
          this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkIsNullable = sinkColumn.isNullable;
          this.selectedColumns[table.sourceDb][table.sourceTable][row.index].analyzer = sinkColumn.analyzer;
          this.selectedColumns[table.sourceDb][table.sourceTable][row.index].isIndex = sinkColumn.needIndex;
          if (DataSourceGroup.es.indexOf(this.taskInfo.sinkType) > -1) {
            row.sinkType = sinkColumn.dataType.toUpperCase();
            row.analyzer = sinkColumn.analyzer;
            row.isIndex = sinkColumn.needIndex;
            // eslint-disable-next-line no-param-reassign
            row = { ...row };
          }
        }
        return null;
      });
    },
    handleSetDrdsPartition(row) {
      this.selectedTable = row;
      this.showDrdsPartition = true;
      this.drdsPartition.dbpartition = row.dbpartition;
      this.drdsPartition.dbFuncs = row.dbFuncs ? row.dbFuncs : 'HASH';
      this.drdsPartition.tbpartition = row.tbpartition;
      this.drdsPartition.tbFuncs = row.tbFuncs;
      this.drdsPartition.tbpartitions = row.tbpartitions;
    },
    handleSetSrPartition(row) {
      this.selectedTable = row;
      this.showSrPartition = true;
      this.srData.partitionExpr = row.partitionExpr;
    },
    handleAddDrdsPartitions(table) {
      this.showDrdsPartition = false;
      this.tableList.forEach((item) => {
        if (item.sourceDb === table.sourceDb && item.sourceTable === table.sourceTable) {
          item.dbpartition = this.drdsPartition.dbpartition instanceof Array
            ? this.drdsPartition.dbpartition : this.drdsPartition.dbpartition
              ? [].concat(this.drdsPartition.dbpartition) : [];
          item.dbFuncs = this.drdsPartition.dbFuncs;
          item.tbpartition = this.drdsPartition.tbpartition instanceof Array
            ? this.drdsPartition.tbpartition : this.drdsPartition.tbpartition
              ? [].concat(this.drdsPartition.tbpartition) : [];
          item.tbFuncs = this.drdsPartition.tbFuncs;
          item.tbpartitions = this.drdsPartition.tbpartitions;
        }
        return null;
      });
      this.taskInfo.selectedTables[table.sourceDb].forEach((item) => {
        if (item.sourceTable === table.sourceTable) {
          item.dbpartition = this.drdsPartition.dbpartition instanceof Array
            ? this.drdsPartition.dbpartition : this.drdsPartition.dbpartition
              ? [].concat(this.drdsPartition.dbpartition) : [];
          item.dbFuncs = this.drdsPartition.dbFuncs;
          item.tbpartition = this.drdsPartition.tbpartition instanceof Array
            ? this.drdsPartition.tbpartition : this.drdsPartition.tbpartition
              ? [].concat(this.drdsPartition.tbpartition) : [];
          item.tbFuncs = this.drdsPartition.tbFuncs;
          item.tbpartitions = this.drdsPartition.tbpartitions;
        }
        return null;
      });
      this.filteredTableList = _.cloneDeep(this.tableList);
      this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
    },
    handleAddSrPartitions(table) {
      validatePartitionExpr({
        dsType: 'StarRocks',
        partitionExpr: this.srData.partitionExpr
      }).then((res) => {
        if (res.data.success) {
          console.log('res', res, table, this.selectedColumns[table.sourceDb][table.sourceTable]);
          let hasAllColumns = true;
          if (this.selectedColumns[table.sourceDb][table.sourceTable]) {
            res.data.data.forEach((data) => {
              let hasColumn = false;
              this.selectedColumns[table.sourceDb][table.sourceTable].forEach((column) => {
                if (data === column.sinkColumn) {
                  hasColumn = true;
                }
              });
              if (!hasColumn) {
                hasAllColumns = false;
              }
            });
          } else {
            hasAllColumns = false;
          }
          if (hasAllColumns) {
            this.showSrPartition = false;
            this.tableList.forEach((item) => {
              if (item.sinkDb === table.sinkDb && item.sinkTable === table.sinkTable) {
                item.partitionExpr = this.srData.partitionExpr;
              }
              return null;
            });
            this.taskInfo.selectedTables[table.sourceDb].forEach((item) => {
              if (item.sinkTable === table.sinkTable) {
                item.partitionExpr = this.srData.partitionExpr;
              }
              return null;
            });
            this.filteredTableList = _.cloneDeep(this.tableList);
            this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
          } else {
            this.$Modal.error({
              title: this.$t('cao-zuo-yi-chang'),
              content: this.$t('gai-biao-bu-cun-zai-biao-da-shi-zhong-de-lie-qing-tian-xie-zheng-que-de-biao-da-shi')
            });
          }
        }
      });
    },
    handleChangePartitionExpr(data) {
      console.log('data', data);
      this.partitionExpr = data;
    },
    handleVirtualColumn(table, virtualData) {
      let hasEmptyVirtual = false;
      const columns = _.cloneDeep(virtualData);
      console.log('columns', columns);
      columns.forEach((column) => {
        if ((!column.targetVirtualName && !column.customName) || !column.targetVirtualType || !column.genRule) {
          hasEmptyVirtual = true;
        }
      });

      if (hasEmptyVirtual) {
        this.$Modal.error({
          title: this.$t('tian-jia-shi-bai'),
          content: this.$t('qing-tian-jia-wan-zheng-de-xu-ni-lie-xin-xi')
        });
      } else {
        this.showVirtualColumn = false;
        let fieldPathName = '';
        if (isHasSchema(this.taskInfo.sourceType)) {
          fieldPathName = `/${table.sourceDb}/${table.sourceSchema}/${table.sourceTable}`;
        } else {
          fieldPathName = `/${table.sourceDb}/${table.sourceTable}`;
        }

        let hasSame = false;
        let currentItem = {};
        let hasCustom = false;
        let hasSameConsole = false;
        let currentConsoleItem = {};
        const fieldList = [];
        const customFieldList = [];
        let totalHeight = window.innerHeight;

        if (totalHeight < 720) {
          totalHeight = 720;
        }
        this.tableHeight = totalHeight - 570;

        this.taskInfo.virtualColumns.forEach((item) => {
          if (item.fieldPathName === fieldPathName) {
            hasSame = true;
            currentItem = item;
          }
          return null;
        });
        this.taskInfo.customVirtualColumns.forEach((item) => {
          if (item.fieldPathName === fieldPathName) {
            hasSameConsole = true;
            currentConsoleItem = item;
          }
          return null;
        });

        columns.forEach((item) => {
          if (item.isCustom) {
            hasCustom = true;
            customFieldList.push({
              fieldName: item.customName.trim(),
              typeName: item.targetVirtualType,
              fieldValue: item.columnValue,
              sqlType: item.sqlType,
              length: item.columnLength,
              genRule: item.genRule,
              needAutoCreate: hasCustom && !hasSameConsole
            });
          }
          fieldList.push({
            fieldName: item.isCustom ? item.customName.trim() : item.targetVirtualName.trim(),
            typeName: item.targetVirtualType,
            fieldValue: item.columnValue,
            sqlType: item.sqlType,
            length: item.columnLength,
            genRule: item.genRule,
            needAutoCreate: hasCustom && !hasSameConsole
          });
          return null;
        });
        if (!hasSame) {
          this.taskInfo.virtualColumns.push({
            fieldPathName,
            customFieldList: fieldList
          });
        } else {
          currentItem.customFieldList = fieldList;
        }
        if (hasCustom) {
          if (!hasSameConsole) {
            this.taskInfo.customVirtualColumns.push({
              fieldPathName,
              customFieldList,
              needAutoCreated: true
            });
          } else {
            currentConsoleItem.customFieldList = customFieldList;
          }
        }
        this.taskInfo.virtualColumnData[fieldPathName] = columns;
        this.taskInfo.virtualColumnData = { ...this.taskInfo.virtualColumnData };
      }
    },
    handleBatchSetVirtualColumn(virtualData, tables) {
      this.showBatchVirtualColumn = false;
      let hasEmptyVirtual = false;
      virtualData.forEach((column) => {
        if ((!column.targetVirtualName && !column.customName) || !column.targetVirtualType || !column.genRule) {
          hasEmptyVirtual = true;
        }
      });

      if (hasEmptyVirtual) {
        this.$Modal.error({
          title: this.$t('tian-jia-shi-bai'),
          content: this.$t('qing-tian-jia-wan-zheng-de-xu-ni-lie-xin-xi')
        });
      } else {
        let totalHeight = window.innerHeight;
        if (totalHeight < 720) {
          totalHeight = 720;
        }
        if (this.taskInfo.processType === 'edit') {
          // console.log('this.taskInfo.virtualColumns', this.taskInfo.virtualColumns, this.taskInfo.customVirtualColumns);
          this.taskInfo.virtualColumns.forEach((column, index) => {
            if (!column.hasInJob) {
              this.taskInfo.virtualColumns.splice(index, 1);
            }
          });
          this.taskInfo.customVirtualColumns.forEach((column, index) => {
            if (!column.hasInJob) {
              this.taskInfo.virtualColumns.splice(index, 1);
            }
          });
        } else {
          this.taskInfo.virtualColumns = [];
          this.taskInfo.customVirtualColumns = [];
        }
        this.tableHeight = totalHeight - 570;
        this.checkAllGroup.forEach((check) => {
          const db = check.split('.')[0];
          const table = check.split('.')[1];

          this.tableList.forEach((item, index) => {
            if (item.sourceTable === table && item.sourceDb === db) {
              let fieldPathName = '';
              if (isHasSchema(this.taskInfo.sourceType)) {
                fieldPathName = `/${item.sourceDb}/${item.sourceSchema}/${item.sourceTable}`;
              } else {
                fieldPathName = `/${item.sourceDb}/${item.sourceTable}`;
              }
              let hasCustom = false;
              const fieldList = [];
              const customFieldList = [];
              const theVirtualData = _.cloneDeep(virtualData);
              theVirtualData.forEach((item1) => {
                if (item1.isCustom) {
                  hasCustom = true;
                  customFieldList.push({
                    fieldName: item1.customName.trim(),
                    typeName: item1.targetVirtualType,
                    fieldValue: item1.columnValue,
                    sqlType: item1.sqlType,
                    length: item1.columnLength,
                    genRule: item1.genRule,
                    needAutoCreate: hasCustom
                  });
                }
                fieldList.push({
                  fieldName: item1.isCustom ? item1.customName.trim() : item1.targetVirtualName.trim(),
                  typeName: item1.targetVirtualType,
                  fieldValue: item1.columnValue,
                  sqlType: item1.sqlType,
                  length: item1.columnLength,
                  genRule: item1.genRule,
                  needAutoCreate: hasCustom
                });
                return null;
              });
              this.taskInfo.virtualColumns.push({
                fieldPathName,
                customFieldList: fieldList
              });
              this.taskInfo.customVirtualColumns.push({
                fieldPathName,
                customFieldList,
                needAutoCreated: true
              });
              this.taskInfo.virtualColumnData[fieldPathName] = theVirtualData;
              if (index === this.tableList.length - 1) {
                this.taskInfo.virtualColumnData = { ...this.taskInfo.virtualColumnData };
              }
            }
          });
        });
      }
    },
    handleDeleteColumn(row, index) {
      this.selectedColumns[row.sourceDb][row.sourceTable].splice(index, 1);
    },
    handleDeleteVirtual(index, table) {
      let fieldPath = '';
      if (isHasSchema(this.taskInfo.sourceType)) {
        fieldPath = `/${table.sourceDb}/${table.sourceSchema}/${table.sourceTable}`;
      } else {
        fieldPath = `/${table.sourceDb}/${table.sourceTable}`;
      }

      this.taskInfo.virtualColumnData[fieldPath].splice(index, 1);
      if (this.taskInfo.virtualColumnData[fieldPath].length < 1) {
        let totalHeight = window.innerHeight;

        if (totalHeight < 720) {
          totalHeight = 720;
        }
        this.tableHeight = totalHeight - 370;
      }
      this.taskInfo.virtualColumns.forEach((item) => {
        if (item.fieldPathName === fieldPath) {
          item.customFieldList.splice(index, 1);
        }
        return null;
      });
    },
    handleCopyMqSchema(data) {
      handleCopy(data);
      this.$Message.success(this.$t('fu-zhi-cheng-gong'));
    },
    handleGenerateOptionData(table, isId = false) {
      const uniqueArr = [];
      const currentColumnList = [];
      const pks = [];
      const ids = [];
      if (isKafka(this.taskInfo.sourceType) && isES(this.taskInfo.sinkType)) {
        this.selectedColumns[table.sinkDb][table.sinkTable].forEach((column) => {
          const columnName = column.sinkColumn;
          column.columnName = columnName;

          if (!uniqueArr.includes(columnName)) {
            currentColumnList.push(column);
            if (column.isId) {
              ids.push(column.columnName);
              uniqueArr.push(columnName);
            }
            if (column.isPk) {
              pks.push(column.columnName);
              uniqueArr.push(columnName);
            }
          }
        });
      } else {
        this.selectedColumns[table.sourceDb][table.sourceTable].forEach((column) => {
          const columnName = isId ? column.needAutoCreated ? column.sourceColumn : column.sinkColumn
            : column.sourceColumn;
          column.columnName = columnName;

          if (!uniqueArr.includes(columnName)) {
            currentColumnList.push(column);
            if (column.isId) {
              ids.push(column.columnName);
              uniqueArr.push(columnName);
            }
            if (column.isPk) {
              pks.push(column.columnName);
              uniqueArr.push(columnName);
            }
          }
        });
      }
      this.currentColumnList = currentColumnList;

      return isId && ids.length ? ids : pks;
    },
    handleChangePk(table) {
      if (isKafka(this.sourceType)) {
        this.currentColumnList = this.selectedColumns[table.sinkDb][table.sinkTable];
      } else {
        this.currentColumnList = this.selectedColumns[table.sourceDb][table.sourceTable];
      }
      const pks = [];
      this.currentColumnList.forEach((column) => {
        if (column.isPk) {
          const columnName = column.columnName || column.sourceColumn;
          pks.push(columnName);
        }
      });
      let path = '';
      if (isHasSchema(this.taskInfo.sourceType)) {
        path = `/${table.sourceDb}/${table.sourceSchema}/${table.sourceTable}`;
      } else {
        path = `/${table.sourceDb}/${table.sourceTable}`;
      }
      if (this.taskInfo.virtualColumnData[path]) {
        this.taskInfo.virtualColumnData[path].forEach((column) => {
          if (column.isPk) {
            const columnName = column.customName || column.targetVirtualName;
            pks.push(columnName);
          }
        });
      }
      this.selectedTargetKeys = pks;
      this.selectedTargetKeysList = pks;
      this.showChangePk = true;
      this.selectedTable = table;
    },
    handleSetId(table) {
      const pks = this.handleGenerateOptionData(table, true);
      this.selectedIds = pks;
      this.showSetId = true;
      this.selectedTable = table;
    },
    handleConfirmSetIds(table, ids) {
      this.showFilter = false;
      let cols = ids;
      if (cols.length) {
        cols = ids.join(',').split(',');
      }

      if (isKafka(this.taskInfo.sourceType) && isES(this.taskInfo.sinkType)) {
        if (this.selectedColumns[table.sinkDb][table.sinkTable]) {
          this.selectedColumns[table.sinkDb][table.sinkTable].forEach((column) => {
            if (cols.includes(column.sinkColumn)) {
              column.isId = true;
            } else {
              column.isId = false;
            }
          });
        }
      } else {
        if (this.selectedColumns[table.sourceDb][table.sourceTable]) {
          this.selectedColumns[table.sourceDb][table.sourceTable].forEach((column) => {
            if (cols.includes(column.sinkColumn)) {
              column.isId = true;
            } else {
              column.isId = false;
            }
          });
        }
      }

      const columnsData = _.cloneDeep(this.selectedColumns);
      const index = this.selectedIndex;

      this.selectedIndex = -1;

      this.updatePkAndIdFlag(table, 'id');
      this.forceUpdateView();

      setTimeout(() => {
        this.selectedColumns = columnsData;
        this.selectedIndex = index;
      }, 200);

      this.uqIndex++;
    },
    updatePkAndIdFlag(table, type) {
      const key = `${table.sourceDb}.${table.sourceTable}`;
      const pkAndIdFlag = this.setPkAndIdFlags[key] || {};

      pkAndIdFlag[type] = true;
      this.setPkAndIdFlags[key] = pkAndIdFlag;

      // this.$set(this.setPkAndIdFlags, key, pkAndIdFlag);
      this.taskInfo.setPkAndIdFlags = this.setPkAndIdFlags;
    },
    handleConfirmChangePk(table, selectedTargetKeys) {
      this.showFilter = false;
      let cols = selectedTargetKeys;
      if (cols.length) {
        cols = selectedTargetKeys.join(',')
          .split(',');
      }

      this.tableList.forEach((item) => {
        if (isKafka(this.sourceType)) {
          if (item.sinkTable === table.sinkTable && item.sinkDb === table.sinkDb) {
            item.cols = _.cloneDeep(cols);
            // item.specifiedDisKeys = _.cloneDeep(this.specifiedDisKeys);
          }
        } else {
          if (item.sourceTable === table.sourceTable && item.sourceDb === table.sourceDb) {
            item.cols = _.cloneDeep(cols);
            // item.specifiedDisKeys = _.cloneDeep(this.specifiedDisKeys);
          }
        }
      });
      if (isKafka(this.sourceType)) {
        this.taskInfo.selectedTables[table.sinkDb === 'empty' ? '' : table.sinkDb].forEach((item) => {
          if (item.sinkTable === table.sinkTable) {
            item.cols = _.cloneDeep(cols);
            // item.specifiedDisKeys = _.cloneDeep(this.specifiedDisKeys);
          }
        });
        if (this.selectedColumns[table.sinkDb][table.sinkTable]) {
          this.selectedColumns[table.sinkDb][table.sinkTable].forEach((column) => {
            if (cols.includes(column.sinkColumn)) {
              column.isPk = true;
            } else {
              column.isPk = false;
            }
          });
        }
      } else {
        this.taskInfo.selectedTables[table.sourceDb].forEach((item) => {
          if (item.sourceTable === table.sourceTable) {
            item.cols = _.cloneDeep(cols);
            // item.specifiedDisKeys = _.cloneDeep(this.specifiedDisKeys);
          }
        });
        if (this.selectedColumns[table.sourceDb][table.sourceTable]) {
          this.selectedColumns[table.sourceDb][table.sourceTable].forEach((column) => {
            if (cols.includes(column.sourceColumn)) {
              column.isPk = true;
            } else {
              column.isPk = false;
            }
          });
        }
      }
      let path = '';
      if (isHasSchema(this.taskInfo.sourceType)) {
        path = `/${table.sourceDb}/${table.sourceSchema}/${table.sourceTable}`;
      } else {
        path = `/${table.sourceDb}/${table.sourceTable}`;
      }
      if (this.taskInfo.virtualColumnData[path]) {
        this.taskInfo.virtualColumnData[path].forEach((column) => {
          const columnName = column.customName || column.targetVirtualName;
          if (cols
            .includes(columnName)) {
            column.isPk = true;
          } else {
            column.isPk = false;
          }
        });
      }

      this.forceUpdateView();
    },
    handleConfirmChangePkBatch() {
      if (this.currentCols) {
        let cols = this.currentCols;
        if (cols.length) {
          cols = this.currentCols.join(',').split(',');
        }

        this.checkAllGroup.forEach((check) => {
          const dotIndex = check.indexOf('.');
          const db = check.substring(0, dotIndex);
          const
            table = check.substring(dotIndex + 1);
          const schema = this.selectedColumns[db][table].length > 0 ? this.selectedColumns[db][table][0].sourceSchema : '';

          if (isKafka(this.sourceType)) {
            try {
              this.tableList.forEach((item) => {
                if (item.sinkTable === table && item.sinkDb === db) {
                  item.cols = cols;
                  // item.specifiedDisKeys = this.specifiedDisKeys;
                }
                return null;
              });
              this.taskInfo.selectedTables[db === 'empty' ? '' : db].forEach((item) => {
                if (item.sinkTable === table) {
                  item.cols = cols;
                  // item.specifiedDisKeys = this.specifiedDisKeys;
                }
                return null;
              });

              if (this.selectedColumns[db][table]) {
                this.selectedColumns[db][table].forEach((column) => {
                  if (cols
                    .includes(column.sinkColumn)) {
                    column.isPk = true;
                  } else {
                    column.isPk = false;
                  }
                  return null;
                });
              }
            } catch (e) {
              console.log('err', e);
            }
          } else {
            this.tableList.forEach((item) => {
              if (item.sourceTable === table && item.sourceDb === db) {
                item.cols = cols;
                // item.specifiedDisKeys = this.specifiedDisKeys;
              }
              return null;
            });
            this.taskInfo.selectedTables[db].forEach((item) => {
              if (item.sourceTable === table) {
                item.cols = cols;
                // item.specifiedDisKeys = this.specifiedDisKeys;
              }
              return null;
            });

            if (this.selectedColumns[db][table]) {
              this.selectedColumns[db][table].forEach((column) => {
                if (cols
                  .includes(column.sinkColumn)) {
                  column.isPk = true;
                } else {
                  column.isPk = false;
                }
                return null;
              });
            }
            let path = '';
            if (isHasSchema(this.taskInfo.sourceType)) {
              path = `/${db}/${schema}/${table}`;
            } else {
              path = `/${db}/${table}`;
            }
            if (this.taskInfo.virtualColumnData[path]) {
              this.taskInfo.virtualColumnData[path].forEach((column) => {
                const columnName = column.customName || column.targetVirtualName;
                if (cols
                  .includes(columnName)) {
                  column.isPk = true;
                } else {
                  column.isPk = false;
                }
              });
            }
          }
          return null;
        });
        const columnsData = _.cloneDeep(this.selectedColumns);
        const
          index = this.selectedIndex;

        this.selectedIndex = -1;
        this.forceUpdateView();

        setTimeout(() => {
          this.selectedColumns = columnsData;
          this.selectedIndex = index;
        }, 200);
      }
    },
    handleSelectUniq(data) {
      this.selectedTargetKeys = data;
      // this.selectedTargetKeysList = data.join(',').split(',');
    },
    handleSetIds(data) {
      this.selectedIds = data;
    },
    handleConfirmPk(type) {
      this.showChangePk = false;
      this.showChangePkBatch = false;
      if (type === 'batch') {
        this.handleConfirmChangePkBatch(this.selectedTable);
      } else {
        this.handleConfirmChangePk(this.selectedTable, this.selectedTargetKeys);
      }
      this.selectedTargetKeys = [];
      this.currentCols = [];
      this.checkAll = false;
      if (this.$refs['virtual-column-container'].$refs['virtual-column-content']) {
        this.$refs['virtual-column-container'].$refs['virtual-column-content'].index++;
      }
    },
    handleConfirmBuckets(type) {
      this.showSetBucketKey = false;
      this.showSetBucketKeysBatch = false;
      if (type === 'batch') {
        this.handleConfirmChangePkBatch(this.selectedTable);
      } else {
        this.handleConfirmChangePk(this.selectedTable, this.selectedTargetKeys);
      }
    },
    handleConfirmIds() {
      this.showSetId = false;
      this.handleConfirmSetIds(this.selectedTable, this.selectedIds);
      this.selectedIds = [];
    },
    handleSetTargetKey() {
      this.hasColumnTableList = [];

      this.showChangePkBatch = true;
      this.showHasColumnTableList = [];
      this.showHasColumnTableListPage = 1;
    },
    handleSetBucketKey() {
      this.hasColumnTableList = [];

      this.showSetBucketKeysBatch = true;
      this.showHasColumnTableList = [];
      this.showHasColumnTableListPage = 1;
    },
    getTableByDefault() {
      this.hasColumnTableList = [];
      console.log('this.selectedColumns', this.selectedColumns);
      Object.keys(this.selectedColumns)
        .forEach((db) => {
          Object.keys(this.selectedColumns[db])
            .forEach((table) => {
              console.log('table[0]', this.selectedColumns[db][table][0], table);
              if (!this.selectedColumns[db][table][0].hasInJob) {
                this.hasColumnTableList.push({
                  dbName: db,
                  tableName: table,
                  sourceSchema: this.selectedColumns[db][table].length > 0 ? this.selectedColumns[db][table][0].sourceSchema : ''
                });
              }
            });
        });
      this.showHasColumnTableListPage = 1;
      this.showHasColumnTableList = this.hasColumnTableList.slice(this.showHasColumnTableListSize * (this.showHasColumnTableListPage - 1),
        this.showHasColumnTableListPage * this.showHasColumnTableListSize);
    },
    handleFindTableByUq() {
      this.hasColumnTableList = [];

      if (this.currentCols) {
        const columnList = this.currentCols.join(',')
          .split(',');

        Object.keys(this.selectedColumns)
          .forEach((db) => {
            Object.keys(this.selectedColumns[db])
              .forEach((table) => {
                let hasSame = true;

                columnList.forEach((c) => {
                  let sameColumn = false;

                  this.selectedColumns[db][table].forEach((column) => {
                    if (isKafka(this.sourceType)) {
                      if (column.sinkColumn === c) {
                        sameColumn = true;
                      }
                    } else {
                      if (column.sourceColumn === c) {
                        sameColumn = true;
                      }
                    }
                  });
                  if (!sameColumn) {
                    hasSame = false;
                  }
                });
                if (hasSame) {
                  this.hasColumnTableList.push({
                    dbName: db,
                    tableName: table
                  });
                }
              });
          });
        Object.keys(this.taskInfo.virtualColumnData)
          .forEach((path) => {
            let hasSame = false;
            this.taskInfo.virtualColumnData[path].forEach((virtualColumn) => {
              columnList.forEach((c) => {
                if ((virtualColumn.customName || virtualColumn.targetVirtualName) === c) {
                  hasSame = true;
                }
              });
            });
            if (hasSame) {
              if (isHasSchema(this.taskInfo.sourceType)) {
                this.hasColumnTableList.push({
                  dbName: path.split('/')[1],
                  sourceSchema: path.split('/')[2],
                  tableName: path.split('/')[3]
                });
              } else {
                this.hasColumnTableList.push({
                  dbName: path.split('/')[1],
                  tableName: path.split('/')[2]
                });
              }
            }
          });
      }
      this.showHasColumnTableListPage = 1;
      this.showHasColumnTableList = this.hasColumnTableList.slice(this.showHasColumnTableListSize * (this.showHasColumnTableListPage - 1),
        this.showHasColumnTableListPage * this.showHasColumnTableListSize);
    },
    handleSetPk() {
      this.noUkList = [];

      this.noPkList.forEach((table) => {
        const key = {};

        key.dbName = table.sourceDb;
        key.tableSchema = null;
        key.tableName = table.sourceTable;
        const uqInfos = this.uniqList[JSON.stringify(key)];

        if (uqInfos && uqInfos.length > 0) {
          const uqInfo = uqInfos[0];
          this.handleConfirmChangePk(table, uqInfo.cols);
        } else {
          this.noUkList.push(table);
        }
      });
      const columnsData = _.cloneDeep(this.selectedColumns);
      const
        index = this.selectedIndex;

      this.selectedIndex = -1;
      this.forceUpdateView();

      setTimeout(() => {
        this.selectedColumns = columnsData;
        this.selectedIndex = index;
      }, 200);
      if (this.noUkList.length > 0) {
        this.showNoPkList = false;
        this.showNoUkList = true;
      } else {
        this.handleStep('next');
      }
    },
    handleSetRedisKey(table) {
      this.selectedTable = table;
      this.showRedisKey = true;
    },
    handleConfirmSetRedisKey(table, suffixFields) {
      this.showFilter = false;

      this.tableList.forEach((item) => {
        if (item.sourceTable === table.sourceTable && item.sourceDb === table.sourceDb) {
          item.suffixFields = _.cloneDeep(suffixFields);
        }
      });
      this.taskInfo.selectedTables[table.sourceDb].forEach((item) => {
        if (item.sourceTable === table.sourceTable) {
          item.suffixFields = _.cloneDeep(suffixFields);
        }
      });

      const index = this.selectedIndex;

      this.handleTableFilter('filter');

      this.selectedTable = this.showTableList[index];
      this.selectedIndex = index;

      this.uqIndex++;

      this.showRedisKey = false;
    },
    handleConfirmBatchSetRedisKey(suffixFields) {
      const list = [];

      list.push(suffixFields);

      this.checkAllGroup.forEach((check) => {
        const db = check.split('.')[0];
        const table = check.split('.')[1];

        this.tableList.forEach((item) => {
          if (item.sourceTable === table && item.sourceDb === db) {
            item.suffixFields = list;
          }
        });
        this.taskInfo.selectedTables[db].forEach((item) => {
          if (item.sourceTable === table) {
            item.suffixFields = list;
          }
        });
      });

      this.filteredTableList = this.tableList;
      this.total = this.filteredTableList.length;
      this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);

      this.forceUpdateView();

      this.uqIndex++;

      this.showBatchSetRedisKey = false;
    },
    handleConfirmBatchSetPartitionExpr() {
      this.checkAllGroup.forEach((check) => {
        const db = check.split('.')[0];
        const table = check.split('.')[1];

        this.tableList.forEach((item) => {
          if (item.sourceTable === table && item.sourceDb === db) {
            item.partitionExpr = this.srData.partitionExpr;
          }
        });
        this.taskInfo.selectedTables[db].forEach((item) => {
          if (item.sourceTable === table) {
            item.partitionExpr = this.srData.partitionExpr;
          }
        });
      });

      this.filteredTableList = this.tableList;
      this.total = this.filteredTableList.length;
      this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);

      this.forceUpdateView();

      this.uqIndex++;

      this.showPartitionExprAll = false;
    },
    handleBatchSetRedisKey() {
      this.showBatchSetRedisKey = true;
    },
    handleShowBatchVirtualColumn() {
      this.showBatchVirtualColumn = true;
      this.getTableByDefault();
    },
    getEsAnalyzerTypes() {
      getEsAnalyzerTypes()
        .then((res) => {
          if (res.data.code === '1') {
            this.esTypeList = res.data.data;
          }
        });
    },
    parseSchemaData(data) {
      console.log('parse schema data');
      if (DataSourceGroup.noDb.includes(this.taskInfo.sourceType)) {
        console.log(1234);
      } else {
        const sourceSchemaJson = JSON.parse(data.sourceSchema);
        const
          mappingJson = JSON.parse(data.mappingConfig);
        const targetSchemaJson = data.targetSchema ? JSON.parse(data.targetSchema) : {};
        let columnMapping = {};
        let tableMqMapping = {};
        mappingJson.forEach((mapping) => {
          if (mapping.method === 'COLUMN_COLUMN') {
            columnMapping = mapping.serializeMapping;
          } else if (mapping.method === 'TABLE_TOPIC') {
            tableMqMapping = mapping.serializeMapping;
          }
        });
        if (DataSourceGroup.hive.includes(this.taskInfo.sinkType) && targetSchemaJson.length > 0) {
          const tableInfo = targetSchemaJson[0].tables[0];
          this.taskInfo.partitionData = {
            partition: [
              {
                key: tableInfo.partitionKeys[0] ? tableInfo.partitionKeys[0].keyName : '',
                func: tableInfo.partitionKeys[0] ? tableInfo.partitionKeys[0].partitionFunction : ''
              }
            ],
            fileFormat: tableInfo.fileFormat,
            compressType: tableInfo.compressType
          };
          this.partitionData = {
            partition: [
              {
                key: tableInfo.partitionKeys[0] ? tableInfo.partitionKeys[0].keyName : '',
                func: tableInfo.partitionKeys[0] ? tableInfo.partitionKeys[0].partitionFunction : ''
              }
            ],
            fileFormat: tableInfo.fileFormat,
            compressType: tableInfo.compressType
          };
        }
        sourceSchemaJson.forEach((db) => {
          let tables = [];
          if (db.schemas) {
            tables = db.schemas[0].tables;
          } else if (db.tableSpaces) {
            tables = db.tableSpaces[0].tables;
          } else if (db.collections) {
            tables = db.collections;
          } else {
            tables = db.tables;
          }
          tables.forEach((table) => {
            if (table.collection) {
              table.table = table.collection;
            }
            if (table.dataFilter) {
              this.tableList.forEach((item) => {
                if (item.sourceTable === table.table && item.sourceDb === db.db) {
                  item.whereCondition = table.dataFilter.expression;
                  item.dataFilterType = table.dataFilter.type;
                }
              });
              if (this.taskInfo.selectedTables[db.db]) {
                this.taskInfo.selectedTables[db.db].forEach((item) => {
                  if (item.sourceTable === table.table) {
                    item.whereCondition = table.dataFilter.expression;
                    item.dataFilterType = table.dataFilter.type;
                  }
                });
              }
            }
            if (DataSourceGroup.hive.includes(this.taskInfo.sinkType)) {
              this.tableList.forEach((item) => {
                if (item.sourceTable === table.table && item.sourceDb === db.db) {
                  item.partitionData = _.cloneDeep(this.taskInfo.partitionData);
                }
              });
              if (this.taskInfo.selectedTables[db.db]) {
                this.taskInfo.selectedTables[db.db].forEach((item) => {
                  if (item.sourceTable === table.table) {
                    item.partitionData = _.cloneDeep(this.taskInfo.partitionData);
                  }
                });
              }
            }
            if (table.specifiedPks) {
              this.tableList.forEach((item) => {
                if (item.sourceTable === table.table && item.sourceDb === db.db) {
                  item.cols = _.cloneDeep(table.specifiedPks);
                }
                return null;
              });
              if (this.taskInfo.selectedTables[db.db]) {
                this.taskInfo.selectedTables[db.db].forEach((item) => {
                  if (item.sourceTable === table.table) {
                    item.cols = _.cloneDeep(table.specifiedPks);
                  }
                  return null;
                });
              }
              let path = '';
              if (isHasSchema(this.taskInfo.sourceType)) {
                if (db.schemas) {
                  path = `/${db.db}/${db.schemas[0].schema}/${table.table}`;
                } else if (db.tableSpaces) {
                  path = `/${db.db}/${db.tableSpaces[0].schema}/${table.table}`;
                }
              } else {
                path = `/${db.db}/${table.table}`;
              }
              console.log('path1111', path);
              if (this.taskInfo.virtualColumnData[path]) {
                this.taskInfo.virtualColumnData[path].forEach((column) => {
                  const columnName = column.customName || column.targetVirtualName;
                  if (table.specifiedPks.includes(columnName)) {
                    column.isPk = true;
                  } else {
                    column.isPk = false;
                  }
                });
              }
            }
            if (DataSourceGroup.redis.includes(this.taskInfo.sinkType)) {
              targetSchemaJson.forEach((target) => {
                if (target.prefix === table.table) {
                  this.tableList.forEach((item) => {
                    if (item.sourceTable === table.table && item.sourceDb === db.db) {
                      item.suffixFields = target.suffixFields;
                    }
                  });
                  if (this.taskInfo.selectedTables[db.db]) {
                    this.taskInfo.selectedTables[db.db].forEach((item) => {
                      if (item.sourceTable === table.table) {
                        item.suffixFields = target.suffixFields;
                      }
                    });
                  }
                }
              });
              this.filteredTableList = this.tableList;
              this.total = this.filteredTableList.length;
              this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
            }
            if (DataSourceGroup.mq.includes(this.taskInfo.sinkType) && targetSchemaJson.length > 0) {
              targetSchemaJson.forEach((target) => {
                if (target.topic === this.getTargetTable(table, tableMqMapping, db)) {
                  this.tableList.forEach((item) => {
                    if (item.sourceTable === table.table && item.sourceDb === db.db) {
                      item.partitions = target.partitions;
                      item.partitionKeys = target.partitionKeys;
                    }
                  });
                  if (this.taskInfo.selectedTables[db.db]) {
                    this.taskInfo.selectedTables[db.db].forEach((item) => {
                      if (item.sourceTable === table.table) {
                        item.partitions = target.partitions;
                        item.partitionKeys = target.partitionKeys;
                      }
                    });
                  }
                }
              });
              this.filteredTableList = this.tableList;
              this.total = this.filteredTableList.length;
              this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
            }
            if (this.selectedColumns[db.db] && this.selectedColumns[db.db][table.table]) {
              this.selectedColumns[db.db][table.table].forEach(((column) => {
                if (table.columns) {
                  table.columns.forEach((theColumn) => {
                    if (theColumn.column === column.sourceColumn) {
                      if (theColumn.inBlackList) {
                        column.selected = false;
                        column._checked = false;
                        column.sinkColumn = '';
                        if (table.specifiedPks.length > 0) {
                          if (table.specifiedPks.includes(theColumn.column)) {
                            column.isPk = true;
                          } else {
                            column.isPk = false;
                          }
                        }
                      } else {
                        column.selected = true;
                        column._checked = true;
                        column.sinkColumn = this.getTargetColumn(column, columnMapping, db);
                        // column.needAutoCreated = theColumn.targetAutoCreate;
                        if (table.specifiedPks && table.specifiedPks.length > 0) {
                          if (table.specifiedPks.includes(theColumn.column)) {
                            column.isPk = true;
                          } else {
                            column.isPk = false;
                          }
                        }
                      }
                    }
                  });
                }
              }));
            }
          });
        });
        this.taskInfo.dbMap.forEach((item) => {
          if (this.taskInfo.selectedTables[item.sourceDb]) {
            item.selectedTables = this.taskInfo.selectedTables[item.sourceDb];
          }
        });
      }
    },
    getTargetColumn(column, mapping, db) {
      let targetColumn = column.sourceColumn;
      Object.keys(mapping)
        .forEach((theMapping) => {
          try {
            let theKey = {
              parent: {
                value: column.sourceTable,
                parent: {
                  value: column.sourceDb
                }
              },
              value: column.sourceColumn
            };
            if (db.schemas || db.tableSpaces) {
              theKey = {
                parent: {
                  value: column.sourceTable,
                  parent: {
                    value: column.sourceSchema,
                    parent: {
                      value: column.sourceDb
                    }
                  }
                },
                value: column.sourceColumn
              };
            }
            if (theMapping === JSON.stringify(theKey)) {
              const theValue = JSON.parse(mapping[theMapping]);
              targetColumn = theValue.value;
            }
          } catch (e) {
            console.log('e', e);
          }
        });
      return targetColumn;
    },
    getTargetTable(table, mapping, db) {
      let targetTable = table.table;
      Object.keys(mapping)
        .forEach((theMapping) => {
          try {
            const theKey = {
              parent: {
                value: db.db
              },
              value: table.table
            };
            if (theMapping === JSON.stringify(theKey)) {
              const theValue = JSON.parse(mapping[theMapping]);
              targetTable = theValue.value;
            }
          } catch (e) {
            console.log('e', e);
          }
        });
      return targetTable;
    }
  },
  watch: {
    HivePartitionFuncs(val) {
      if (val && val.length > 0) {
        this.taskInfo.partitionData = {
          partition: [
            {
              key: this.HivePartitionFuncs[0].partitionKey,
              func: this.HivePartitionFuncs[0].function
            }
          ]
        };
      }
    }
  }
};
</script>
<style lang="less" scoped>
.clean-data-body {
  overflow: auto;
}

.clean-data-table-info {
  font-size: 16px;
  color: #0BB9F8;
  margin-right: 10px;
}

.clean-data-table-arrow {
  margin: 0 3px;
}

.ivu-modal-confirm-body > div {
  word-break: break-word;
}

.has-where-table {
  color: #FF6E0D;
}

.clean-data-page {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  line-height: 58px;
  vertical-align: middle;
  background-color: #FAFAFA;
  border-top: 1px solid #DADADA;
  border-right: 1px solid #DADADA;;
  text-align: center;
}

.table-filter-nav-column {
  width: 400px;
  border-right: 1px solid #DADADA;
  border-top: 1px solid #DADADA;
}

.column-filter-container {
  margin-left: 400px;
  position: relative;
  border-top: 1px solid #DADADA;
  border-bottom: 1px solid #DADADA;
  background: #FAFAFA;

  .ivu-table-wrapper-with-border {
    border-right: none;
    border-left: none;
    border-bottom: none;
  }

  .mq-schema-container {
    width: 470px;
    position: absolute;
    right: 0;
    top: 0;
    //float: right;
    border-left: 1px solid #DADADA;
    margin-left: 10px;
    background: #ffffff;
  }
}

.mq-schema-left-container {
  width: 470px;
  float: left;
  border-right: 1px solid #DADADA;
  margin-right: 10px;
  background: #ffffff;
}

.table-filter-filter-span {
  background-color: #FFE1E1;
  padding: 4px 6px;
  border-radius: 4px;
  margin-right: 8px;

  .ivu-icon {
    margin-left: 6px;
    cursor: pointer;
  }
}

.table-filter-filter-clear {
  margin-left: 6px;
  cursor: pointer;
}
</style>
