export const UPDATE_USERINFO = 'UPDATE_USERINFO';
export const UPDATE_DEPLOY_ENV_LIST_MAP = 'UPDATE_DEPLOY_ENV_LIST_MAP';
export const UPDATE_REGION_LIST_MAP = 'UPDATE_REGION_LIST_MAP';
export const UPDATE_CLUSTER_LIST = 'UPDATE_CLUSTER_LIST';
export const UPDATE_DS_TYPE_LIST = 'UPDATE_DS_TYPE_LIST';
export const UPDATE_DS_SETTING = 'UPDATE_DS_SETTING';
export const UPDATE_PRODUCT_CLUSTER = 'UPDATE_PRODUCT_CLUSTER';
export const UPDATE_SELECT_PRODUCT_CLUSTER = 'UPDATE_SELECT_PRODUCT_CLUSTER';
export const UPDATE_GLOBAL_SETTING = 'UPDATE_GLOBAL_SETTING';
export const UPDATE_CC_GLOBAL_SETTING = 'UPDATE_CC_GLOBAL_SETTING';
export const UPDATE_DM_GLOBAL_SETTING = 'UPDATE_DM_GLOBAL_SETTING';
export const UPDATE_EDITOR_SET = 'UPDATE_EDITOR_SET';
export const UPDATE_MY_CATALOG = 'UPDATE_MY_CATALOG';
export const UPDATE_MY_AUTH = 'UPDATE_MY_AUTH';
export const UPDATE_DEPLOY_SITE = 'UPDATE_DEPLOY_SITE';
export const UPDATE_RESOURCE_INFO = 'UPDATE_RESOURCE_INFO';
export const UPDATE_SELECT_DM_PRODUCT_CLUSTER = 'UPDATE_SELECT_DM_PRODUCT_CLUSTER';
