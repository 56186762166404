export const fakerApi = {
  fakerDef: '/faker/fakerDef',
  fakerInit: '/faker/initFaker',
  fakerPreview: '/faker/preview',
  fakerExecute: '/faker/execute',
  fakerPause: '/faker/pause',
  fakerResume: '/faker/resume',
  fakerTailLog: '/faker/tailLog',
  fakerClose: '/faker/close',
  fakerFetchUiConfig: '/faker/fetchUiConfig'
};
