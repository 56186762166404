import { instance } from '../instance';

export const queryAllLog = (param) => instance.post('audit/queryall', param);

export const getLogByUid = (param) => instance.post('audit/querybyuid', param);

export const getLogByUserName = (param) => instance.post('audit/querybyusername', param);

export const grepOperationLog = (param) => instance.post('logview/grep/operationlog', param);
export const ccQueryOperationListCondition = (param) => instance.post('audit/querylistcondition', param);
