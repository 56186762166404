import { instance } from '../instance';

export const getDbList = (param) => instance.post('datasource/db/list', param);

export const getTableList = (param) => instance.post('datasource/table/list', param);

export const testConnection = (param) => instance.post('datasource/connection', param);

export const batchListColumns = (param) => instance.post('datasource/column/batchlist', param);

export const queryIndex = (param) => instance.post('datasource/es/index/query', param);

export const getDsTypeMapping = (param) => instance.post('datasource/gettypemapping', param);

export const listDbTablesUniqs = (param) => instance.post('datasource/listdbtablesuniqs', param);

export const testConnectionBeforeAdd = (param) => instance.post('datasource/testconnectionbeforeadd', param);

export const schemasTableCount = (param) => instance.post('datasource/schemastablecount', param);

export const queryDsAttrs = (param) => instance.post('datasource/querydsattrs', param);
export const listByCc = (param) => instance.post('datasource/listforcc', param);
export const validatePartitionExpr = (param) => instance.post('datasource/validatePartitionExpr', param);
