import i18n from '../i18n';

export default {
  jobType: {
    MIGRATION: i18n.t('quan-liang-qian-yi'),
    SYNC: i18n.t('zeng-liang-tong-bu'),
    SUBSCRIBE: i18n.t('shu-ju-ding-yue'),
    CHECK: i18n.t('xiao-yan-yu-ding-zheng'),
    STRUCT_MIGRATION: i18n.t('jie-gou-qian-yi'),
    REVISE: i18n.t('shu-ju-ding-zheng')
  },
  jobTypeIcon: {
    MIGRATION: 'iconshujuqianyi',
    SYNC: 'iconshujutongbu',
    SUBSCRIBE: 'md-checkmark-circle-outline',
    CHECK: 'iconshujujiaoyan',
    STRUCT_MIGRATION: 'iconjiegouqianyi'
  },
  taskState: {
    CREATING: i18n.t('chuang-jian'),
    CREATED: i18n.t('yi-chuang-jian'),
    INIT: i18n.t('ren-wu-chu-shi-hua'),
    FULL: i18n.t('quan-liang-qian-yi'),
    INCREMENT: i18n.t('zeng-liang-tong-bu'),
    INCRE: i18n.t('zeng-liang-tong-bu'),
    CATCH_UP: i18n.t('zeng-liang-zhui-ping'),
    FULL_CHECK: i18n.t('quan-liang-xiao-yan'),
    FULL_REVERSE_CHECK: i18n.t('quan-liang-ding-zheng-xiao-yan'),
    FULL_REVISE: i18n.t('quan-liang-ding-zheng'),
    REVIEW_DIFF: i18n.t('ding-zheng-yi-chang'),
    SYNCING: i18n.t('shu-ju-tong-bu'),
    PAUSE: i18n.t('zan-ting'),
    EXPIRED: i18n.t('yi-guo-qi'),
    ERROR: i18n.t('cuo-wu'),
    COMPLETE: i18n.t('yi-wan-cheng'),
    CHECK: i18n.t('shu-ju-xiao-yan'),
    R_CHECK: i18n.t('zai-ci-shu-ju-xiao-yan'),
    REVISE: i18n.t('shu-ju-ding-zheng'),
    R_REVISE: i18n.t('zai-ci-shu-ju-ding-zheng'),
    ANY: i18n.t('ren-he-jie-duan'),
    DELETE: i18n.t('yi-shan-chu'),
    TIMING_SCHEDULE: i18n.t('tiao-du-zhong'),
    TIMING_SCHEDULE_PAUSE: i18n.t('yi-zan-ting-tiao-du')
  },
  taskStatus: {
    INIT: i18n.t('chu-shi-hua'),
    WAIT_START: i18n.t('deng-dai-kai-shi'),
    RUNNING: i18n.t('yun-hang-zhong'),
    DONE: i18n.t('yi-wan-cheng'),
    WAIT_STOP: i18n.t('deng-dai-ting-zhi'),
    STOP: i18n.t('yi-ting-zhi'),
    ABNORMAL: i18n.t('yi-chang'),
    WAIT_COMPLETE: i18n.t('deng-dai-wan-cheng'),
    COMPLETE: i18n.t('yi-wan-cheng'),
    DELETE: i18n.t('yi-shan-chu'),
    WAIT_RESTART: i18n.t('deng-dai-zhong-qi')
  },
  taskSateToTaskType: {
    FULL: 'FULL',
    INCRE: 'INCREMENT',
    CATCH_UP: 'INCREMENT',
    CHECK: 'CHECK',
    INIT: 'BUILD_STRUCT',
    REVISE: 'REVISE'
  },
  tagInfo: {
    FILTER: i18n.t('shu-ju-guo-lv-xiang-guan-de-can-shu'),
    MAPPING: i18n.t('yuan-he-mu-biao-ying-she-xiang-guan-de-pei-zhi'),
    PERFORMANCE: i18n.t('xing-neng-xiang-guan-de-pei-zhi'),
    NORMAL: i18n.t('pu-tong-can-shu'),
    GENERAL: i18n.t('pu-tong-can-shu'),
    USER_PREFER: i18n.t('yong-hu-pian-hao'),
    COMMON: i18n.t('pu-tong-can-shu'),
    TIME: i18n.t('shi-jian-xiang-guan-de-can-shu-bi-ru-xian-cheng-tiao-du-de-zhou-qi-yi-ji-yi-xie-shi-jian-yu-zhi'),
    RESERVERD: i18n.t('canal-ji-cheng-guo-lai-de-bao-liu-can-shu-wei-lai-ban-ben-jiang-bei-yi-chu'),
    POSITION: i18n.t('tong-bu-ren-wu-wei-dian-xiang-guan-de-can-shu'),
    DATA_EXPORT: i18n.t('shu-ju-dao-chu'),
    GH_OST: 'GH_OST'
  },
  consoleJob: {
    DATA_JOB_CREATE: i18n.t('chuang-jian-ren-wu'),
    DATA_JOB_DELETE: i18n.t('shan-chu-ren-wu'),
    RDS_ADD_PUBLIC_NET: i18n.t('rds-kai-fang-gong-wang-quan-xian'),
    RDS_AUTO_ADD_ACCOUNT: i18n.t('a-li-yun-shu-ju-yuan-zi-dong-chuang-jian-zhang-hao'),
    MANUAL_SCHEDULE_TASK: i18n.t('shou-dong-tiao-du-ren-wu'),
    DATA_TASK_COMPLETE_ACTION: i18n.t('wan-cheng-ren-wu'),
    INSTALL_ECS: i18n.t('zhu-ce-ecs-ji-qi'),
    UNINSTALL_ECS: i18n.t('xie-zai-ecs-ji-qi'),
    INSTALL_LOCAL_MAC: i18n.t('zhu-ce-ben-di-ji-qi'),
    UNINSTALL_LOCAL_MAC: i18n.t('xie-zai-ben-di-ji-qi'),
    RDS_ADD_IP_WHITE_LIST: i18n.t('rds-tian-jia-bai-ming-dan'),
    RDS_ADD_SECURITY_INFO: i18n.t('rds-tian-jia-an-quan-xin-xi'),
    START_ECS_CLIENT: i18n.t('qi-dong-ecs-shang-de-ke-hu-duan'),
    EDIT_DATA_JOB: i18n.t('xiu-gai-ding-yue'),
    ALIYUN_ADD_WHITELIST_INFO: i18n.t('a-li-yun-shu-ju-ku-tian-jia-bai-ming-dan'),
    UPGRADE_ECS: i18n.t('geng-xin-ecs-shang-de-ke-hu-duan'),
    DATA_JOB_REPLAY: i18n.t('zhong-pao-ren-wu'),
    TAKE_OVER_USER_RESOURCE: i18n.t('yi-jiao-yong-hu-zi-yuan'),
    DELETE_USER: i18n.t('shan-chu-yong-hu'),
    INCRE_TASK_DETACH: i18n.t('zhai-chu-zeng-liang-ren-wu'),
    INCRE_TASK_ATTACH: i18n.t('gua-zai-zeng-liang-ren-wu'),
    CLEAN_DATA_JOB_RESOURCE: i18n.t('qing-li-zi-ding-yi-dai-ma-bao'),
    WORKER_IPS_ADD_TO_ALIYUN_LIST: i18n.t('tian-jia-ji-qi-ip-dao-ji-qun-ren-wu-suo-guan-lian-yun-shu-ju-ku-bai-ming-dan')
  },
  consoleJobStatus: {
    WAIT_START: i18n.t('deng-dai-kai-shi'),
    EXECUTE: i18n.t('zhi-hang-zhong'),
    SUCCESS: i18n.t('cheng-gong'),
    FAILED: i18n.t('shi-bai'),
    CANCELED: i18n.t('yi-qu-xiao'),
    SKIP: i18n.t('yi-hu-lve')
  },
  workerStatus: {
    WAIT_TO_ONLINE: i18n.t('deng-dai-shang-xian'),
    ONLINE: i18n.t('zai-xian'),
    WAIT_TO_OFFLINE: i18n.t('deng-dai-xia-xian'),
    OFFLINE: i18n.t('li-xian'),
    ABNORMAL: i18n.t('yi-chang')
  },
  workerType: {
    BARE_METAL: i18n.t('wu-li-ji'),
    VM: i18n.t('xu-ni-ji'),
    ECS: i18n.t('a-li-yun-fu-wu-qi')
  },
  cloudOrIdcName: {
    SELF_MAINTENANCE: i18n.t('zi-jian-ji-fang'),
    ALIBABA_CLOUD: i18n.t('a-li-yun'),
    ALIBABA_CLOUD_HOSTED: i18n.t('a-li-yun')
  },
  alarmIndicator: {
    DELAY_MS: i18n.t('ren-wu-yan-chi-shi-jian'),
    DELAY_MIN: i18n.t('ren-wu-yan-chi-shi-jian')
  },
  aliyunType: {
    MySQL: 'RDS for MySQL',
    PostgreSQL: 'RDS for PostgreSQL',
    Greenplum: 'ADB for PG',
    ElasticSearch: 'ElasticSearch',
    RocketMQ: 'RocketMQ',
    Kafka: 'Kafka',
    RabbitMQ: 'RabbitMQ',
    DRDS: 'DRDS',
    PolarDbX: 'PolarDB-X',
    AdbForMySQL: 'ADB for MySQL',
    PolarDbMySQL: 'PolarDbMySQL',
    ClickHouse: 'ClickHouse',
    MongoDB: 'MongoDB',
    Redis: 'Redis',
    SQLServer: 'SQLServer'
  },
  selfType: {
    PolarDbX: 'PolarDB-X'
  },
  dataTaskType: {
    FULL: i18n.t('quan-liang-qian-yi'),
    INCREMENT: i18n.t('zeng-liang-tong-bu'),
    CHECK: i18n.t('shu-ju-xiao-yan'),
    BUILD_STRUCT: i18n.t('jie-gou-qian-yi'),
    REVISE: i18n.t('shu-ju-ding-zheng')
  },
  deployType: {
    SELF_MAINTENANCE: i18n.t('zi-jian-shu-ju-ku'),
    ALIBABA_CLOUD_HOSTED: i18n.t('a-li-yun'),
    AWS_CLOUD_HOSTED: 'AWS',
    MICROSOFT_AZURE_CLOUD_HOSTED: 'AZURE'
  },
  hostType: {
    PRIVATE: i18n.t('si-you-wang-luo'),
    PUBLIC: i18n.t('gong-you-wang-luo')
  },
  role: {
    SYSTEM: i18n.t('xi-tong-guan-li-yuan'),
    DEFAULT: i18n.t('pu-tong-yong-hu'),
    ADMIN: i18n.t('guan-li-yuan'),
    ADMIN_READONLY: i18n.t('zhi-du-guan-li-yuan'),
    ORG_ADMIN: i18n.t('pu-tong-yong-hu')
  },
  region: {
    hangzhou: i18n.t('hang-zhou'),
    shanghai: i18n.t('shang-hai'),
    beijing: i18n.t('bei-jing'),
    shenzhen: i18n.t('shen-zhen'),
    guangzhou: i18n.t('guang-zhou'),
    customer: i18n.t('bu-xian'),
    zhangjiakou: i18n.t('zhang-jia-kou'),
    huhehaote: i18n.t('hu-he-hao-te'),
    hongkong: i18n.t('xiang-gang'),
    singapore: i18n.t('xin-jia-po'),
    silicon_valley: i18n.t('gui-gu'),
    london: i18n.t('lun-dun'),
    qingdao: i18n.t('qing-dao'),
    mq_internet_access: i18n.t('gong-wang'),
    hangzhou_finance: i18n.t('hang-zhou-jin-rong-yun'),
    virginia: i18n.t('fu-ji-ni-ya'),
    japan: i18n.t('ri-ben'),
    australia: i18n.t('ao-da-li-ya'),
    malaysia: i18n.t('ma-lai-xi-ya'),
    indonesia: i18n.t('yin-du-ni-xi-ya'),
    philippine: i18n.t('fei-lv-bin'),
    german: i18n.t('de-guo'),
    india: i18n.t('yin-du'),
    dubai: i18n.t('di-bai'),
    shanghai_finance: i18n.t('shang-hai-jin-rong-yun'),
    shenzhen_finance: i18n.t('shen-zhen-jin-rong-yun'),
    beijing_finance: i18n.t('bei-jing-jin-rong-yun'),
    wulanchabu: i18n.t('wu-lan-cha-bu')
  },
  statusIcon: {
    'icon-zanting': i18n.t('yi-zan-ting'),
    'icon-zhengchang': i18n.t('zheng-chang'),
    'icon-yichang': i18n.t('yi-chang'),
    'icon-wancheng': i18n.t('yi-wan-cheng')
  },
  filterType: {
    autoCreated: i18n.t('dai-chuang-jian-biao'),
    noCreate: i18n.t('yi-cun-zai-biao'),
    hasPk: i18n.t('you-zhu-jian-biao'),
    noPk: i18n.t('wu-zhu-jian-biao'),
    column: i18n.t('lie-ming-shai-xuan'),
    differPk: i18n.t('zhu-jian-wei-dui-qi'),
    hasWhere: i18n.t('yi-tian-jia-where-tiao-jian'),
    noWhere: i18n.t('wei-tian-jia-where-tiao-jian'),
    hasInJob: i18n.t('yi-zai-ren-wu-zhong-de-biao'),
    notInJob: i18n.t('wei-zai-ren-wu-zhong-de-biao'),
    hasSelected: i18n.t('yi-xuan-ze-de-biao'),
    notSelected: i18n.t('wei-xuan-ze-de-biao')
  },
  resourceType: {
    WORKER: i18n.t('tong-bu-ji-qi'),
    DATASOURCE: i18n.t('shu-ju-yuan'),
    DATA_JOB: i18n.t('tong-bu-ren-wu')
  },
  createStatus: {
    CREATED: i18n.t('chuang-jian-wan-cheng'),
    CREATING: i18n.t('chuang-jian-zhong-0')
  },
  licenseType: {
    fullTransferCount: i18n.t('quan-liang-qian-yi'),
    incrementDuration: i18n.t('zeng-liang-tong-bu'),
    checkCount: i18n.t('quan-liang-xiao-yan'),
    structCount: i18n.t('jie-gou-qian-yi')
  },
  licenseTypeDefault: {
    FULL_TRANSFER_COUNT: i18n.t('quan-liang-qian-yi'),
    INCREMENT_SYNC_DURATION: i18n.t('zeng-liang-tong-bu'),
    FULL_CHECK_COUNT: i18n.t('quan-liang-xiao-yan'),
    STRUCT_TRANSFER_COUNT: i18n.t('jie-gou-qian-yi')
  },
  licenseTypeDefaultMeta: {
    '1_TIME_FULL_TRANSFER_COUNT': i18n.t('dan-ci-quan-liang-shu-ju-qian-yi'),
    '5_TIME_FULL_TRANSFER_COUNT': i18n.t('5-ci-quan-liang-shu-ju-qian-yi'),
    '10_TIME_FULL_TRANSFER_COUNT': i18n.t('10-ci-quan-liang-shu-ju-qian-yi'),
    '30_TIME_FULL_TRANSFER_COUNT': i18n.t('30-ci-quan-liang-shu-ju-qian-yi'),
    '100_TIME_FULL_TRANSFER_COUNT': i18n.t('100-ci-quan-liang-shu-ju-qian-yi'),
    '24_HOUR_INCREMENT_SYNC_DURATION': i18n.t('24-xiao-shi-zeng-liang-tong-bu'),
    '168_HOUR_INCREMENT_SYNC_DURATION': i18n.t('7-tian-zeng-liang-tong-bu'),
    '720_HOUR_INCREMENT_SYNC_DURATION': i18n.t('30-tian-zeng-liang-tong-bu'),
    '4320_HOUR_INCREMENT_SYNC_DURATION': i18n.t('180-tian-zeng-liang-tong-bu'),
    '8640_HOUR_INCREMENT_SYNC_DURATION': i18n.t('360-tian-zeng-liang-tong-bu'),
    '1_TIME_FULL_CHECK_COUNT': i18n.t('dan-ci-quan-liang-shu-ju-xiao-yan'),
    '5_TIME_FULL_CHECK_COUNT': i18n.t('5-ci-quan-liang-shu-ju-xiao-yan'),
    '10_TIME_FULL_CHECK_COUNT': i18n.t('10-ci-quan-liang-shu-ju-xiao-yan'),
    '30_TIME_FULL_CHECK_COUNT': i18n.t('30-ci-quan-liang-shu-ju-xiao-yan'),
    '100_TIME_FULL_CHECK_COUNT': i18n.t('100-ci-quan-liang-shu-ju-xiao-yan'),
    '1_TIME_STRUCT_TRANSFER_COUNT': i18n.t('dan-ci-jie-gou-qian-yi'),
    '5_TIME_STRUCT_TRANSFER_COUNT': i18n.t('5-ci-jie-gou-qian-yi'),
    '10_TIME_STRUCT_TRANSFER_COUNT': i18n.t('10-ci-jie-gou-qian-yi'),
    '30_TIME_STRUCT_TRANSFER_COUNT': i18n.t('30-ci-jie-gou-qian-yi'),
    '100_TIME_STRUCT_TRANSFER_COUNT': i18n.t('100-ci-jie-gou-qian-yi')
  },
  securityType: {
    KERBEROS: 'KERBEROS',
    USER_PASSWD_WITH_TLS: i18n.t('zhang-hao-mi-ma-jia-tls'),
    USER_PASSWD: i18n.t('zhang-hao-mi-ma'),
    NONE: i18n.t('wu-zhang-hao-mi-ma'),
    ONLY_USER: i18n.t('you-zhang-hao-wu-mi-ma'),
    ONLY_PASSWD: i18n.t('wu-zhang-hao-you-mi-ma')
  },
  validation: {
    account: ['USER_PASSWD', 'ONLY_USER', 'KERBEROS', 'USER_PASSWD_WITH_TLS'],
    password: ['USER_PASSWD', 'ONLY_PASSWD', 'USER_PASSWD_WITH_TLS']
  },
  testSecurityType: [
    'USER_PASSWD',
    'NONE',
    'ONLY_USER',
    'ONLY_PASSWD'
  ],
  posType: {
    MYSQL_LOG_FILE_POS: i18n.t('wen-jian-wei-dian'),
    MYSQL_GTID_POS: i18n.t('gtid-wei-dian'),
    MYSQL_TIMESTAMP_POS: i18n.t('shi-jian-chuo-wei-dian'),
    ORACLE_TIMESTAMP_POS: i18n.t('shi-jian-chuo-wei-dian'),
    ORACLE_SCN_POS: i18n.t('scn-wei-dian')
  },
  columnMapping: {
    clougence_cloudcanal_filter_after_mapping_column: i18n.t('jin-ding-yue-bu-xie-ru-dui-duan')
  },
  deployDsMap: {
    SELF_MAINTENANCE: {
      PolarDbX: 'PolarDB-X'
    },
    ALIBABA_CLOUD_HOSTED: {
      MySQL: 'RDS for MySQL',
      PostgreSQL: 'RDS for PostgreSQL',
      Greenplum: 'ADB for PG',
      ElasticSearch: 'ElasticSearch',
      RocketMQ: 'RocketMQ',
      Kafka: 'Kafka',
      RabbitMQ: 'RabbitMQ',
      DRDS: 'DRDS',
      PolarDbX: 'PolarDB-X',
      AdbForMySQL: 'ADB for MySQL',
      PolarDbMySQL: 'PolarDbMySQL',
      ClickHouse: 'ClickHouse',
      MongoDB: 'MongoDB',
      Redis: 'Redis',
      SQLServer: 'SQLServer'
    },
    AWS_CLOUD_HOSTED: {
      AuroraMySQL: 'Aurora (MySQL)',
      AuroraPostgreSQL: 'Aurora (PostgreSQL)',
      MySQL: 'MySQL',
      PostgreSQL: 'PostgreSQL',
      MariaDB: 'MariaDB',
      Oracle: 'Oracle',
      SQLServer: 'Microsoft SQLServer'
    },
    MICROSOFT_AZURE_CLOUD_HOSTED: {
      MySQL: 'Azure for MySQL',
      PostgreSQL: 'Azure for PostgreSQL',
      MariaDB: 'Azure for MariaDB',
      SQLServer: 'Azure SQL'
    },
    HUAWEI_CLOUD_HOSTED: {
    }
  }
};
